import { DatePicker, Input, message, Modal, Select, Table } from 'antd';
import 'antd/dist/antd.min.css';
import 'antd/lib/date-picker/style/css';
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import Button1 from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import AuthService from '../login/AuthService';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import Brand from './Brand.js';
import Cat from './Cat.jsx';
import ItemDescr from './itemdescr.jsx';
import MarketFilter from './marketFilter';
import OpcoId from './opcoid.jsx';
import VendorName from './VendorName.jsx';

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
'use strict';

const Option = Select.Option;
const Search = Input.Search;
const Auth = new AuthService();

// declare var jQuery: jQuery;
const itemURL = 'getItems';
const addURL = 'addItem';
const supcURL = 'getSUPC';
const majorURL = 'getMajor';
const marketURL = 'getMarket';
const multiSearchUrl = 'multipleSearch';
// const adminBrokerList="getBrokerNumbersOfCompany"
const corpBrokerList = "getBrokerNumbersOfCompany";
const corpUserList = "getUsersForBrokerNumber";
const children = [];
const products = [];
var adminList = [];
var adminUserList = [];

function disable() {
	document.getElementById("inputMarket").disabled = true;

	$('.opcoId').children().val("ALL")
	$('.market').children().val("ALL")
}
function enable() {
	$('.opcoId').children().val("")
	document.getElementById("inputMarket").disabled = false
}

for (let i = 10; i < 36; i++) {
	children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}
// var $j = jQuery.noConflict();
var itemId;
let arr = [];
var rowPushed = [];
var prod1 = [];
const vnameFil = [];
const marketFil = [];
const brandFil = [];
const opcoFil = [];
var vnames1 = [];
var market1 = [];
var cat1 = [];
var catFill = [];
var maj1 = [];
var market = [];
var cat = [];
var maj = [];
var majFil = [];
var vendorSearch = "VENDOR_NUMBER";
var marSearch = "MARKET";
var opcoSearch = "it.OPCO_ID";
var catSearch = "CATEGORY";
var majSearch = "MAJOR";
var brandSearch = "BRAND";
var itemSearch = "ITEM_ID";
var today = new Date();
var weekOldDate = new Date(today.getTime() - (60 * 60 * 24 * 7 * 1000));
var dataSetNum = 1;
var visitedPages = [];
var selectAllFlag = false;
var selectedList = [];
var majorList = "";

var comp;
var mailId;
var brokerType;

function handleCatChange(value) {
	var string = `${value}`;



	$(".category").children().val(string)
}

function handleMakChange(value) {
	var string = `${value}`;



	$(".market").children().val(string)
}

function handleMajChange(value) {
	var string = `${value}`;

	comp.setState({ majorList: string });

	$(".major").children().val(string)
}
function onChange(date, dateString) {
}

//var comp;
var newItem = [];
$(".ant-calendar-month-panel-tbody td[title|='Jan']").addClass("ant-calendar-month-panel-cell-disabled")
function disabledDate(current) {
	// Can not select days before today and today

	//var month=today.getMonth();

	return true;
}
const today1 = moment();


class DateRange extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startValue: null,
			endValue: moment('2099-12', 'YYYY-MM'),
			endOpen: false,
		};
	}

	handleStartChange = (value, mode) => {
		this.setState({ mode });
		var index = this.props.rec;
		for (var el in index) {
			comp.state.products[index[el]].startDate = '';
		}

		if (this.state.startValue != null) {
			console.log("this.state.endValue 1 is", this.state.endValue)
			var startDate = this.state.startValue.get("year") + '-' + ("0" + (Number(this.state.startValue.get("month")) + 1)).slice(-2);
			var endDate = this.state.endValue.get("year") + '-' + ("0" + (Number(this.state.endValue.get("month")) + 1)).slice(-2);

			for (var e2 in index) {
				comp.state.products[index[e2]].startDate = startDate;
				comp.state.products[index[e2]].endDate = endDate;
			}
			comp.setState({date : {startDate: startDate, endDate: endDate}});
			comp.setState({ products: comp.state.products })
		}

	}

	handleEndChange = (value, mode) => {
		this.setState({ mode });
		var index = this.props.rec;
		for (var el in index) {
			comp.state.products[index[el]].endDate = '';
		}

		if (this.state.endValue != null) {
			var endDate = this.state.endValue.get("year") + '-' + ("0" + (Number(this.state.endValue.get("month")) + 1)).slice(-2);
			for (var e2 in index) {
				comp.state.products[index[e2]].endDate = endDate;
			}
			comp.setState({date : {startDate: "", endDate: endDate}});
			comp.setState({ products: comp.state.products })
		}
	}

	disabledStartDate = (startValue) => {
		const endValue = this.state.endValue;
		if (startValue < moment() ) {
			return true;
		} else if (!startValue || !endValue) {
			return false;
		}

		return startValue.add(-1, "M") >= endValue;
	}


	disabledEndDate = (endValue) => {
		const startValue = this.state.startValue;
		if (endValue.add(1, "M") < today1.endOf('day')) {
			return true;
		} else if (!endValue || !startValue) {
			return false;
		}
		return endValue.add(-1, "M") < startValue;
	}

	onStartChange = (value) => {
		this.state.startValue = value;
		this.setState({
			startValue: value,
		}, () => { console.log("this.state.startValue in onStartChange is", this.state.startValue) });

		this.handleStartChange();
	}

	onEndChange = (value) => {
		console.log("value in onEndChange is", value)
		this.state.endValue = value;
		this.setState({
			endValue: this.state.endValue,
		}, () => { console.log("this.state.endValue in onEndChange is", this.state.endValue); });
		this.handleEndChange();

	}

	handleStartOpenChange = (open) => {
		if (!open) {
			this.setState({ endOpen: false });
		}
		else {
			this.setState({ mode: 'month' });
		}
	}

	handleEndOpenChange = (open) => {
		this.setState({ endOpen: open });
		if (open) {
			this.setState({ mode: 'month' });
		}
	}

	render() {
		const { startValue, endValue, endOpen } = this.state;

		return (
			<div>
				<DatePicker
					disabledDate={this.disabledStartDate}
					mode={this.state.mode}
					picker={"month"}
					format="YYYY-MM"
					value={startValue}
					placeholder="Start Date"
					onChange={this.onStartChange}
					onOpenChange={this.handleStartOpenChange}
					onPanelChange={this.handleStartChange}

				/>
				<DatePicker
					disabledDate={this.disabledEndDate}
					mode={this.state.mode}
					picker={"month"}
					format="YYYY-MM"
					defaultValue={moment('2099-12', 'YYYY-MM')}
					value={endValue}
					placeholder="End Date"
					onChange={this.onEndChange}
					open={endOpen}
					onOpenChange={this.handleEndOpenChange}
					onPanelChange={this.handleEndChange}
				/>
			</div>
		);
	}
}


class AllItems extends React.Component {

	constructor(props) {
		super(props);
		console.log(this.props.history.location)
		this.state = {
			userNameB: "",
			itemSelectedList: [],rowPushed: [], selectedRowKeys: [], date: { startDate: "", endDate: "" }, products: [], emptyText: ' ',
			data: { brokerNumber: this.props.history.location.state.brokerNumber, brokerCode: this.props.history.location.state.brokerCode, brokerName: this.props.history.location.state.brokerName, mailId: this.props.history.location.state.mailId, brokerType: this.props.history.location.state.brokerType },
			filteredProducts: [], visible: false, visibleBrand: false, visibleId: false, visibleCat: false, visibleMak: false, visibleMaj: false, visibleVname: false, visibleVno: false, visibleoId: false, loadingflag: false,
			vendorNo: this.props.history.location.state.vendorNo, market: this.props.history.location.state.market, opcoId: this.props.history.location.state.opcoId, category: this.props.history.location.state.category, major: this.props.history.location.state.major, brand: this.props.history.location.state.brand,
			adminList: [], adminUserList: [], maj1: [], majorList: ''
		},
		mailId = this.props.history.location.state.mailId;
		brokerType = this.props.history.location.state.brokerType;
		//var brokerName=this.props.history.location.state.brokerName;
		comp = this;
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.addItem = this.addItem.bind(this);
		this.search = this.search.bind(this);
		this.currentUser = this.props.history.location.state.currentUser;
		this.getBrokerNumbersOfCompany = this.getBrokerNumbersOfCompany.bind(this);
		this.getMajor = this.getMajor.bind(this);
		this.getItems = this.getItems.bind(this);
		this.getUsersForBrokerNumber = this.getUsersForBrokerNumber.bind(this);
		this.multipleSearch = this.multipleSearch.bind(this);
		this.getNextItemSet = this.getNextItemSet.bind(this);
		this.setProperty = this.setProperty.bind(this);
	}

	onSelectChange = (selectedRowKeys) => {
		this.state.selectedRowKeys = selectedRowKeys
		this.setState({ selectedRowKeys: this.state.selectedRowKeys });
	}

	onSelectDeselect = (record, selected, selectedRows, nativeEvent) => {
		var selection = selectedList;
		if(!selection.includes(record.key)){
			selection.push(record.key);
		} else {
			const index = selection.indexOf(record.key);
			selection.splice(index, 1);
		}
		selectedList = selection;
	}

	showModal = () => {
		this.setState({
			visible: true,
		});

	}
	handleOk = (e) => {
		$("#add").prop("disabled", true);
		for (var el in comp.state.rowPushed) {
			console.log("comp.state.rowPushed[el].startDate,comp.state.rowPushed[el].endDate", comp.state.rowPushed[el].startDate, comp.state.rowPushed[el].endDate)

			if (comp.state.rowPushed[el].startDate == null || comp.state.rowPushed[el].endDate == null) {
				message.error(<span className="errorMsg">"Please fill start and end dates"</span>);
				return;
			}
		}
		this.setState({
			visible: false,
		});
		var param = this.getAddParam();
		comp.addItem(param, false);

	}
	handleCancel = (e) => {
		console.log("entered cancel")
		for (var el in comp.state.rowPushed) {
			comp.state.rowPushed[el].startDate = null
			comp.state.rowPushed[el].endDate = null
		}
		$("#add").prop("disabled", false);
		this.setState({
			visible: false,
		});
	}


	add() {
		if (comp.state.data.brokerType == "Normal user" || (comp.state.data.brokerNumber != "" && comp.state.data.brokerNumber != "All")) {
			if (comp.state.data.brokerType == "Normal user" || (comp.state.userNameB != "" && comp.state.userNameB != "All")) {
				comp.state.rowPushed = []

				comp.setState({ rowPushed: comp.state.rowPushed }, () => { console.log("rowPushed", comp.state.rowPushed) })
				for (var el in selectedList) {
					comp.state.itemSelectedList.push(comp.state.products[selectedList[el]]);
					comp.state.rowPushed.push(comp.state.products[comp.state.selectedRowKeys[el]]);
					comp.state.rowPushed.map((row) => { row.brokerNumber = comp.state.data.brokerNumber; row.mailId = comp.state.data.mailId; row.userName = comp.group == "admin" || comp.state.data.brokerType == "Broker Corporate" ? comp.state.userNameB : comp.currentUser });
				}
				comp.setState({ rowPushed: comp.state.rowPushed })
				var length = selectedList.length;
				if (length > 0 || selectAllFlag) {
					var z = 0;
					comp.state.rowPushed.map(row => { if (row.startDate != null && row.startDate != '') { z += 1 } })
					if (z > 0 && z < length) {
						window.alert("Fill all the Dates");
					} else if (z === 0) {
						comp.showModal();

					} else if (z == length) {
						var param = comp.getAddParam();
						comp.addItem(param, false)
					}
				} else {
					window.alert("Select one or more row");
				}
			}
			else {
				window.alert("Please select a user");
			}
		}
		else {
			window.alert("Please select a broker number");
		}
	}

	getAddParam(){
		brandSearch = "";
		catSearch = "";
		itemSearch = "";
		majSearch = "";
		marSearch = "";
		opcoSearch = "";
		vendorSearch = "";

		if (comp.state.brand != "") {
			brandSearch = comp.state.brand;
		}
		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}
		if (comp.state.major != "") {
			majSearch = comp.state.major;
		}
		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}
		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}
		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}

		var param = {
			"addItemsList" : comp.state.itemSelectedList,
			"brand": brandSearch,
			"brokerCode": comp.state.data.brokerCode,
			"brokerNumber": comp.state.data.brokerNumber,
			"category": catSearch,
			"endDate": comp.state.date.endDate,
			"itemId": itemSearch,
			"major": majSearch,
			"market": comp.state.market,
			"opcoId": opcoSearch,
			"selectAllFlag": selectAllFlag,
			"startDate": comp.state.date.startDate,
			"userName": (comp.props.history.location.state.group == "admin" || comp.props.history.location.state.brokerType == "Broker Corporate") ? comp.state.userNameB : comp.props.history.location.state.currentUser,
			"vendorNumber": vendorSearch
		}
		return param;
	}

	componentWillMount() {
		comp = this;
		adminList = []
		adminUserList = []

		if (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") {
			let param1 = {
				"brokerCode": this.props.history.location.state.brokerCode,
				"branch": this.props.history.location.state.group == "admin" ? this.props.history.location.state.branch : "corporate",
				"brokerNumber": this.props.history.location.state.brokerNumber
			}
			comp.getBrokerNumbersOfCompany(param1, false);
		}
		comp.getMajor(false);
	}

	getBrokerNumbersOfCompany(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},

			beforeSend: function () {
				$("#loader").show();
			},
			crossDomain: true,
			'type': 'POST',
			'contentType': 'application/json',
			'dataType': 'json',
			'url': URL.url + corpBrokerList,
			'data': JSON.stringify(param),
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {

				if (data1.rows == null) {
					adminList.push(<Option key={comp.props.history.location.state.brokerNumber}>{comp.props.history.location.state.brokerNumber + " - " + comp.props.history.location.state.brokerName}</Option>)
				}
				for (var e in data1.rows) {
					adminList.push(<Option key={data1.rows[e].brokerNumber}>{data1.rows[e].brokerNumber + '-' + data1.rows[e].brokerName}</Option>)
				}
				comp.state.data.brokerNumber = "All"
				comp.setState({ adminList: adminList })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getBrokerNumbersOfCompany(param, true);
				}
			});
	}

	getMajor(didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + majorURL,
			'contentType': 'application/json',
			'dataType': 'json',
		})
			.done(function (data) {
				for (var el in data.rows) {
					maj1.push(<Option key={data.rows[el].major}>{data.rows[el].major}</Option>)
				}
				comp.setState({ maj1 })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getMajor(true);
				}
			});
	}

	fetchItems() {
		newItem = [];
		comp.state.products = [];
		comp.setState({ products: comp.state.products })
		comp.state.selectedRowKeys = [];
		comp.setState({ selectedRowKeys: comp.state.selectedRowKeys })
		if (this.props.history.location.state.route != "new") {
			comp.setState({
				loadingFlag: true
			})
			var params = {
				"brokerNumber": this.state.data.brokerNumber
			}
			comp.getItems(params, false);
		}

	}

	getItems(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + itemURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container

			}
		})
			.done(function (data) {
				var i = 0;
				for (var k in data.rows) {
					var cDate = new Date(data.rows[k].createdDate)

					if (cDate <= today && cDate > weekOldDate) {
						newItem.push(i)
					}
					comp.state.products.push({
						key: i,
						itemSize: data.rows[k].itemSize,
						vendorNumber: data.rows[k].vendorNumber,
						vendorName: data.rows[k].vendorName,
						itemId: data.rows[k].itemId,
						itemDescr: data.rows[k].itemDescr,
						itemDescr1: data.rows[k].itemDescr.slice(0, 15) + '...',
						opcoId: data.rows[k].opcoId,
						market: data.rows[k].market,
						brand: data.rows[k].brand,
						category: data.rows[k].category,
						major: data.rows[k].major,
						intermediate: data.rows[k].intermediate,
						minor: data.rows[k].minor,
						itemPack: data.rows[k].itemPack,
						startDate: '',
						endDate: ''
					})
					i += 1;
					if (market.indexOf(data.rows[k].market) < 0) {
						market.push(data.rows[k].market)
					}

					if (cat.indexOf(data.rows[k].category) < 0) {
						cat.push(data.rows[k].category)
					}
					if (maj.indexOf(data.rows[k].major) < 0) {
						maj.push(data.rows[k].major)
					}
				}
				comp.setState({ products: comp.state.products });
				for (var j = 0; j < market.length; j++) {
					market1.push(<Option key={market[j]}>{market[j]}</Option>);
				}

				for (var k = 0; k < maj.length; k++) {
					maj1.push(<Option key={maj[k]}>{maj[k]}</Option>);
				}
				for (var l = 0; l < cat.length; l++) {
					cat1.push(<Option key={cat[l]}>{cat[l]}</Option>);
				}
				comp.setState({ maj1 });

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getItems(param, true);
				}
			})
			.always(function (data) {
				comp.setState({
					loadingFlag: false
				})
			});
	}

	componentDidMount() {
		initGA();
		PageView();

		if (this.props.history.location.state.route == "new") {
			this.setState({})
			$('.vno').children().val(this.state.vendorNo);
			// $('.market').children().val(this.state.market);
			// $('.opcoId').children().val(this.state.opcoId);
			$('.category').children().val(this.state.category);
			$('.major').children().val(this.state.major);
			$('.brand').children().val(this.state.brand);

		}

		$("div .ant-table-selection").find($("input")).on("click", function(){
			if ($(this).is(':checked')) {
				selectAllFlag = true;
				selectedList = [];
			}else {
				selectAllFlag = false;
			}
		});

		$("div .ant-table-selection").find($("input")).change(
			function () {
				if ($(this).is(':checked')) {
					comp.state.selectedRowKeys = [...Array(comp.state.products.length).keys()]
					comp.setState({ selectedRowKeys: comp.state.selectedRowKeys })
					comp.onSelectChange(comp.state.selectedRowKeys)
				} else {
					comp.state.selectedRowKeys = []
					comp.setState({ selectedRowKeys: comp.state.selectedRowKeys })
					comp.onSelectChange(comp.state.selectedRowKeys)
				}
			});
	}

	handleChange = (pagination, filters, sorter) => {
		if(pagination.current % 100 == 0 && !visitedPages.includes(pagination.current)){
			visitedPages.push(pagination.current);
			comp.getNextDataSet();
		}
	}


	addItem(param, didFail) {
		var comp = this;
		const hide = message.loading('Adding Ties. Please do not reload or exit the page', 0);
		// var param = {
		// 	"data": comp.state.rowPushed
		// }
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + addURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader").hide();
			}
		})
			.done(function (data) {
				setTimeout(hide, 10);
				$("#add").prop("disabled", false);
				message.info(<span className="errorMsg">{data.message}</span>);
				window.location.reload(true);
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.addItem(param, true);
				}
				else {
					$("#add").prop("disabled", true);
					setTimeout(hide, 10);
				}
			});

	}

	showBrandModal = () => {
		this.setState({
			visibleBrand: true,
		});
	}
	handleBrandOk = (e) => {
		this.setState({
			visibleBrand: false,
		});
	}
	handleBrandCancel = (e) => {
		this.setState({
			visibleBrand: false,
		});
	}

	showVnoModal = () => {
		this.setState({
			visibleVno: true,
		});
	}
	handleVnoOk = (e) => {
		this.setState({
			visibleVno: false,
		});
	}
	handleVnoCancel = (e) => {
		this.setState({
			visibleVno: false,
		});
	}
	handleuserNameChange = (e) => {
		this.setState({ userNameB: e })
	}

	handleBrokerNameChange = (e) => {
		this.setState({ userNameB: "Select" })
		var dataCopy = this.state.data;
		dataCopy.brokerNumber = e;
		this.setState({ data: dataCopy });
		let param1 = { "brokerCode": this.props.history.location.state.brokerCode, "brokerNumber": e }
		comp.getUsersForBrokerNumber(param1, false);

	}

	getUsersForBrokerNumber(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + corpUserList,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {
				adminUserList.splice(0,);
				// adminUserList.push(<Option key={"All"}>All</Option>);
				for (var e in data1.rows) {
					adminUserList.push(<Option key={data1.rows[e].userName}>{data1.rows[e].userName + ' - ' + data1.rows[e].contactPersonName}</Option>)
				}
				comp.setState({ adminUserList });
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken()
					comp.getUsersForBrokerNumber(param, true);
				}
			});
	}

	showMakModal = () => {
		this.setState({
			visibleMak: true,
		});
	}
	handleMakOk = (market) => {
		this.setState({
			market: market,
			visibleMak: false,
		});
	}
	handleMakCancel = (e) => {
		this.setState({
			visibleMak: false,
		});
	}

	showMajModal = () => {
		this.setState({
			visibleMaj: true,
		});
	}
	handleMajOk = (e) => {
		this.setState({
			visibleMaj: false,
		});
	}
	handleMajCancel = (e) => {
		this.setState({
			visibleMaj: false,
		});
	}

	showCatModal = () => {
		this.setState({
			visibleCat: true,
		});
	}
	handleCatOk = (e) => {
		this.setState({
			visibleCat: false,
		});
	}
	handleCatCancel = (e) => {
		this.setState({
			visibleCat: false,
		});
	}

	showIidModal1 = () => {
		this.setState({
			visibleId: true,
		});
	}
	handleIdOk1 = (e) => {
		this.setState({
			visibleId: false,
		});
	}
	handleIdCancel1 = (e) => {
		this.setState({
			visibleId: false,
		});
	}

	showoIdModal1 = () => {
		this.setState({
			visibleoId: true,
		});
	}
	handleoIdOk1 = (e) => {
		this.setState({
			visibleoId: false,
		});
	}
	handleoIdCancel1 = (e) => {
		this.setState({
			visibleoId: false,
		});
	}

	onChangeMarketSearch = (event) => {
		this.setState({ market: event.target.value });
	}

	search(e) {
		visitedPages = [];
		dataSetNum = 1;
		e.preventDefault();
		comp.state.products = [];
		comp.setState({
			emptyText: ' ',
			loadingFlag: true
		})
		opcoSearch = "";
		catSearch = "";
		majSearch = "";
		brandSearch = "";
		vendorSearch = "";
		marSearch = "";
		itemSearch = "";
		comp.setState({ products: comp.state.products });
		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}
		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}
		if (comp.state.majorList != "") {
			majSearch = comp.state.majorList;
		}

		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}

		if (comp.state.brand != "") {
			brandSearch = comp.state.brand;
		}
		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}
		var param = {
			"brand": brandSearch,
			"category": catSearch,
			"itemId": itemSearch,
			"major": majSearch,
			"market": comp.state.market,
			"opcoId": opcoSearch,
			"vendorNumber": vendorSearch,
			"brokerNumber": comp.state.data.brokerNumber,
			"userName": (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.userNameB : this.props.history.location.state.currentUser,
			"brokerType": (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") && (this.state.userNameB == "All" || this.state.userNameB == "") ? "Broker Corporate" : "",
			"brokerCode": this.state.data.brokerCode,
			"dataSetNum": dataSetNum
		}
		comp.multipleSearch(param, false);
	}

	multipleSearch(param, didFail) {
		var p1 = [];
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + multiSearchUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader").hide();
			}
		})
			.done(function (data) {
				var i = 0

				for (var el in data.rows) {

					p1.push({
						key: i,
						vendorNumber: data.rows[el].vendorNumber,
						vendorName: data.rows[el].vendorName,
						itemId: data.rows[el].itemId,
						itemDescr: data.rows[el].itemDescr,
						itemDescr1: data.rows[el].itemDescr.slice(0, 15) + '...',
						opcoId: data.rows[el].opcoId,
						market: data.rows[el].market,
						brand: data.rows[el].brand,
						category: data.rows[el].category,
						major: data.rows[el].major,
						intermediate: data.rows[el].intermediate,
						minor: data.rows[el].minor,
						itemPack: data.rows[el].itemPack,
						itemSize: data.rows[el].itemSize,
						startDate: data.rows[el].startDate
					})
					i = i + 1
				}
				comp.setState({ products: p1 });

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken()
					comp.multipleSearch(param, true);
				}
			}).always(function (data) {
				comp.setState({
					emptyText: "No Data",
					loadingFlag: false
				})
			});
	}

	getNextDataSet(){
		dataSetNum = dataSetNum + 1;
		opcoSearch = "";
		catSearch = "";
		majSearch = "";
		brandSearch = "";
		vendorSearch = "";
		marSearch = "";
		itemSearch = "";

		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}
		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}
		if (comp.state.majorList != "") {
			majSearch = comp.state.majorList;
		}

		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}

		if (comp.state.brand != "") {
			brandSearch = comp.state.brand;
		}
		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}
		var param = {
			"brand": brandSearch,
			"category": catSearch,
			"itemId": itemSearch,
			"major": majSearch,
			"market": comp.state.market,
			"opcoId": opcoSearch,
			"vendorNumber": vendorSearch,
			"brokerNumber": comp.state.data.brokerNumber,
			"userName": (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.userNameB : this.props.history.location.state.currentUser,
			"brokerType": (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") && (this.state.userNameB == "All" || this.state.userNameB == "") ? "Broker Corporate" : "",
			"brokerCode": this.state.data.brokerCode,
			"dataSetNum": dataSetNum
		}
		this.getNextItemSet(param, false);
	}

	getNextItemSet(param, didFail) {
		var p1 = comp.state.products;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + multiSearchUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader").hide();
			}
		})
			.done(function (data) {
				var i = comp.state.products.length;

				for (var el in data.rows) {

					p1.push({
						key: i,
						vendorNumber: data.rows[el].vendorNumber,
						vendorName: data.rows[el].vendorName,
						itemId: data.rows[el].itemId,
						itemDescr: data.rows[el].itemDescr,
						itemDescr1: data.rows[el].itemDescr.slice(0, 15) + '...',
						opcoId: data.rows[el].opcoId,
						market: data.rows[el].market,
						brand: data.rows[el].brand,
						category: data.rows[el].category,
						major: data.rows[el].major,
						intermediate: data.rows[el].intermediate,
						minor: data.rows[el].minor,
						itemPack: data.rows[el].itemPack,
						itemSize: data.rows[el].itemSize,
						startDate: data.rows[el].startDate
					})
					i = i + 1
				}
				comp.setState({ products: p1 });

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken()
					comp.getNextItemSet(param, true);
				}
			}).always(function (data) {
				comp.setState({
					emptyText: "No Data",
					loadingFlag: false
				})
			});
	}

	clear() {
		comp.setState({ opcoId: "" });
		comp.setState({ vno: "" });
		comp.setState({ market: "" });
		comp.setState({ category: "" });
		comp.setState({ majorList: "" });
		comp.setState({ brand: "" });
		comp.setState({ itemId: "" });
	}

	setProperty(key, value) {
		comp.setState({
			[key]: value
		})
	}

	render() {
		const { selectedRowKeys } = this.state;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
			hideDefaultSelections: true,
			onSelection: this.onSelection,
			onSelect: this.onSelectDeselect,
		};

		const columns = [{
			title: 'Vendor No',
			dataIndex: 'vendorNumber',
			key: 'vendorNumber',

			width: 90,
			sorter: (a, b) => parseInt(a.vendorNumber) - parseInt(b.vendorNumber),
			render: (text, record) => { if (newItem.indexOf(record.key) >= 0) { return <p >&#128681;{text}</p> } else { return <p>{text}</p> } }

		}, {
			title: 'Vendor Name',
			dataIndex: 'vendorName',
			key: 'vendorName',

			width: 240,

			sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),

		}, {
			title: 'Market',
			dataIndex: 'market',
			key: 'market',
			width: 100,

			sorter: (a, b) => a.market.localeCompare(b.market),

		},
		{
			title: 'OPCO Id',
			dataIndex: 'opcoId',
			key: 'opcoId',
			width: 90,

			sorter: (a, b) => parseInt(a.opcoId) - parseInt(b.opcoId),

		},
		{
			title: 'Cat',
			dataIndex: 'category',
			key: 'category',
			width: 50,
			sorter: (a, b) => parseInt(a.category) - parseInt(b.category),

		}, {
			title: 'Maj',
			dataIndex: 'major',
			key: 'major',
			width: 55,

			sorter: (a, b) => parseInt(a.major) - parseInt(b.major),

		}, {
			title: 'Int',
			dataIndex: 'intermediate',
			key: 'intermediate',
			width: 50,

			sorter: (a, b) => parseInt(a.intermediate) - parseInt(b.intermediate),

		}, {
			title: 'Min',
			dataIndex: 'minor',
			key: 'minor',
			width: 55,

			sorter: (a, b) => parseInt(a.minor) - parseInt(b.minor),

		},
		{
			title: 'Brand',
			dataIndex: 'brand',
			key: 'brand',
			width: 100,


			sorter: (a, b) => a.brand.localeCompare(b.brand),

		},
		{
			title: 'Item Id',
			dataIndex: 'itemId',
			key: 'itemId',
			width: 100,

			sorter: (a, b) => parseInt(a.itemId) - parseInt(b.itemId),

		}, {
			title: 'Item Description',
			dataIndex: 'itemDescr1',
			key: 'itemDescr1',
			width: 150,

			sorter: (a, b) => a.itemDescr1.localeCompare(b.itemDescr1),

		},
		{
			title: 'Start date and End Date',
			key: 'startDate',
			dataIndex: 'startDate',
			editable: true,
			width: 350,

			render: (text, record) => {
				var rec = [];
				rec.push(record.key)
				return <DateRange rec={rec} />
			}
		}];

		//const { size } = this.state;
		return (
			<div className="container-fluid">
				<NavBar1 currentUser={this.currentUser} name={this.name} group={this.group} brokerNumber={this.state.data.brokerNumber} brokerCode={this.state.data.brokerCode} brokerName={this.state.data.brokerName} mailId={this.state.data.mailId} brokerType={this.state.data.brokerType} /><br />

				<div className="row tab"><h1 className="headings" style={{ margin: "auto" }}>Item Tie Add</h1></div>
				<div className="row tab"><span className="col-md-offset-8 col-md-4 col-sm-12 col-xs-12"><button className="btn btn-primary" id="add" style={{ float: "right" }} onClick={this.add}>Add Records</button></span></div>
				<br />

				<form onSubmit={this.search} className="form-horizontal row tab">
					<div className="col-md-5">
						{this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate" ? (
							<FormGroup >
								<div className="col-md-3 col-sm-5"><label>Broker:</label></div>
								<div className="col-md-9 col-sm-12 col-xs-12">
									<Select
										placeholder={"Select"}
										style={{width: 'inherit'}}
										onChange={(event) => this.handleBrokerNameChange(event)}
									>
										{this.state.adminList}
									</Select></div>
							</FormGroup >) : ""}

						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Vendor No:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12"><Search
								placeholder="input search text" className="vno"
								onSearch={value => this.showVnoModal()}
								onChange={e => this.setProperty('vno', e.target.value)}
								value={this.state.vno}
								enterButton
							/>

								<Modal
									title="Search vendors"
									visible={this.state.visibleVno}
									onOk={this.handleVnoOk}
									onCancel={this.handleVnoCancel}
								>
									<VendorName setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Market:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12">
								<Search
									placeholder="input search text" className="market"
									onSearch={value => this.showMakModal()}
									enterButton
									value={this.state.market}
									onChange={(event) => this.onChangeMarketSearch(event)}
								/>
								<MarketFilter
									visible={this.state.visibleMak}
									setMarket={this.handleMakOk}
									onCancel={this.handleMakCancel} />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>OpCo Id:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12"><Search
								placeholder="input search text" className="opcoId"
								onSearch={value => this.showoIdModal1()}
								onChange={e => this.setProperty('opcoId', e.target.value)}
								value={this.state.opcoId}
								enterButton
							/>

								<Modal
									title="Search OPCO"
									visible={this.state.visibleoId}
									onOk={this.handleoIdOk1}
									onCancel={this.handleoIdCancel1}
								>
									<OpcoId setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup style={{}}>
							<div className="col-md-9 col-sm-12 col-xs-12" style={{ textAlign: "right" }}><Button1 className="btn btn-primary btn-lg" style={{ backgroundColor: "white", borderColor: "rgb(86, 177, 70)", color: "rgb(86, 177, 70)", opacity: "1.0" }} onClick={comp.clear}>Clear Search</Button1></div>
							<div className="col-md-3 col-sm-12 col-xs-12"><Button1 type="submit" className="btn btn-primary btn-lg search" style={{ backgroundColor: "#008cd2", opacity: "1.0", width: "103px" }}> Search</Button1></div>
						</FormGroup>
					</div>

					<div className="col-md-1"></div>

					<div className="col-md-5">

						{this.props.history.location.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ? (
							<FormGroup >
								<div className="col-md-3 col-sm-5"><label>
									User: </label></div>
								<div className="col-md-9 col-sm-12 col-xs-12">
									<Select
										className="userName"
										placeholder="Select"
										style={{width: 'inherit'}}
										value={this.state.userNameB}
										onChange={(event) => this.handleuserNameChange(event)}
									>
										{this.state.adminUserList}
									</Select></div>
							</FormGroup >) : ""}

						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Category:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12">

								<Search
									placeholder="input search text" className="category"
									onSearch={value => this.showCatModal()}
									onChange={e => this.setProperty('category', e.target.value)}
									value={this.state.category}
									enterButton
								/>
								<Modal
									title="Search Category"
									visible={this.state.visibleCat}
									onOk={this.handleCatOk}
									onCancel={this.handleCatCancel}
								>

									<Cat setProperty={this.setProperty} />

								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Major:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12">

								<Search
									placeholder="input search text" className="major"
									onSearch={value => this.showMajModal()}
									onChange={e => this.setProperty('majorList', e.target.value)}
									value={this.state.majorList}
									enterButton
								/>
								<Modal
									title="Search Major"
									open={this.state.visibleMaj}
									onOk={this.handleMajOk}
									onCancel={this.handleMajCancel}
								>
									<Select
										mode="multiple"
										style={{ width: '100%' }}
										placeholder="Please select"
										onChange={handleMajChange}
									>
										{this.state.maj1}
									</Select>
								</Modal>
							</div>
						</FormGroup>



						<FormGroup>

							<div className="col-md-3 col-sm-5"><label>Brand:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12">

								<Search
									placeholder="input search text" className="brand"
									onSearch={value => this.showBrandModal()}
									onChange={e => this.setProperty('brand', e.target.value)}
									value={this.state.brand}
									enterButton
								/>
								<Modal
									title="Search Brands"
									open={this.state.visibleBrand}
									onOk={this.handleBrandOk}
									onCancel={this.handleBrandCancel}
								>
									<Brand setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Item Id:</label></div>
							<div className="col-md-9 col-sm-12 col-xs-12"><Search
								placeholder="input search text" className="itemId"
								onSearch={value => this.showIidModal1()}
								onChange={e => this.setProperty('itemId', e.target.value)}
								value={this.state.itemId}
								enterButton
							/>

								<Modal

									title="Search Items"
									visible={this.state.visibleId}
									onOk={this.handleIdOk1}
									onCancel={this.handleIdCancel1}

								>
									<ItemDescr setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>

					</div>
				</form><br /><br />
				<Modal
					title="Enter Start Date and End Date"
					visible={comp.state.visible}
					onOk={comp.handleOk}
					onCancel={comp.handleCancel}

				>
					<DateRange rec={comp.state.selectedRowKeys} />
				</Modal>
				<div className="row tab" style={{ width: "95vw", overflowX: "auto" }}>
					&#128681; indicates new items which are not assigned to this broker
				<Table rowSelection={rowSelection} locale={{ emptyText: this.state.emptyText }} loading={this.state.loadingFlag} columns={columns} expandedRowRender={record => <p style={{ margin: 0 }}>Item Pack= {record.itemPack}, Item Size= {record.itemSize}, Item Description= {record.itemDescr}</p>} dataSource={this.state.products} onChange={this.handleChange} pagination={{ pageSize: 10 }} scroll={{ x: 800, y: 250 }} size="middle" />
				</div>
			</div>
		);
	}
}
export default withAuth(AllItems);