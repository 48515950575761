import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import NavBar1 from '../admin welcome/navbar.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import VendorOpcoFilters from './vendoropcofilters.jsx';

var comp;
class VendorOpco extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				opcoId: '',
				contactPersonName: '',
				mailId: '',
				phoneNumber: ''
			}, edit: "none",
		};
		comp = this;
		this.name = this.props.history.location.state.contactPersonName;
		this.group = this.props.history.location.state.group;
		this.setEmpState = this.setEmpState.bind(this)
		this.row = this.props.history.location.state.row;
		this.edit = this.edit.bind(this);
	}

	componentWillMount() {
		if (this.group === "admin" || this.group === "cm") {
			this.state.data = {
				opcoId: this.row.opcoId,
				contactPersonName: this.row.contactPersonName,
				mailId: this.row.mailId,
				phoneNumber: this.row.phoneNumber,
				userName: this.row.userName,
				addedBy: this.row.phoneNumber,
				userGroup: this.row.userGroup,
			};

			this.setState({
				data: this.state.data
			})

		}
		else if (this.group === "opcoviewer") {

			this.state.data = {
				opcoId: this.props.history.location.state.opcoId,
				contactPersonName: this.props.history.location.state.contactPersonName,
				mailId: this.props.history.location.state.mailId,
				phoneNumber: this.props.history.location.state.phoneNumber

			};

			this.setState({
				data: this.state.data
			})
		}

		if (this.group === "admin") {
			this.setState({ edit: "inline-block" });
		}

	}


	setEmpState(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data, value: e.target.value });
		this.state = { value: 'coconut' };
	}

	edit(e) {
		this.props.history.push({
			pathname: "/suite/vbt/editOPCO",
			state: {
				data: this.name,
				row: this.row,
				group: this.group
			}
		})
	}

	render() {
		return <div className="container-fluid">
			<NavBar1 name={this.name} group={this.group} opcoId={this.state.data.opcoId} /><br />


			<h1 className="headings" style={{ fontWeight: "bold", textAlign: "left" }}><span>{this.state.data.opcoId}  </span><span>{this.state.data.contactPersonName}</span></h1>
			<span style={{ float: "right" }}> <Button className="btn btn-primary btn-lg" onClick={this.edit} style={{ backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif', display: this.state.edit }}>Edit OPCO user details</Button></span>
			<br />
			<br />
			<div><VendorOpcoFilters opcoId={this.state.data.opcoId} /></div>



		</div>

	}

}
export default withAuth(VendorOpco);
