import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthService from '../login/AuthService';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import snav from './sysco-nav.png';

const Auth = new AuthService();

class NavBar1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { data: this.props.name, head: "USER", audit: 'none', noti: "none", ur: "none", dr: "none", ad: "none", ad1: "none", ad2: "none", ad3: "none", ad4: "none", brokerData: { brokerName: '', brokerCode: this.props.brokerCode, brokerNumber: '', mailId: '', brokerType: this.props.brokerType, currentUser: this.props.currentUser }, vendor: { vendorNumber: '' }, opco: { opcoId: '' }, currentUser: this.props.currentUser };
		this.group = this.props.group;
		this.myFunction = this.myFunction.bind(this);
		this.logout = this.logout.bind(this);
		this.duplicate = this.duplicate.bind(this);
		this.broker_vendor = this.broker_vendor.bind(this);
		this.audit = this.audit.bind(this);
		this.unassigned = this.unassigned.bind(this);
		this.routeToHome = this.routeToHome.bind(this);
		this.alltiesaudit = this.alltiesaudit.bind(this);
		this.brokertieaudit = this.brokertieaudit.bind(this);
		this.brokerPOCaudit = this.brokerPOCaudit.bind(this);
	}

	componentWillMount() {
		if (this.group == "admin" || this.group == "cm") {
			this.setState({ head: "Admin", noti: "block", dr: "block", bv: "block", ad: "block", ad1: "block", ad4: "block", audit: "block" });
		} else {
			this.setState({ noti: "none", bv: "block" });
		}
		if (this.group == "user") {
			this.setState({ ad2: "block", ad3: "block" }, () => console.log("this.state.ad3", this.state.ad3)
			);
		}
		if (this.group == "user" && this.props.history.location.state.brokerType == "Broker Corporate") {

			this.setState({ head: "Broker" })
		}
		if (this.group == "user" || this.group == "admin" || this.group == "cm") {
			this.setState({ ur: "block", al: "block", audit: "block", dr: "block" });
		} else {
			this.setState({ ur: "none", al: "none", li: "none", dr: "none" });
		}

		if (this.group == "user") {
			this.state.brokerData.brokerNumber = this.props.brokerNumber;
			this.state.brokerData.brokerCode = this.props.history.location.state.brokerCode;
			this.state.brokerData.brokerName = this.props.brokerName;
			this.state.brokerData.mailId = this.props.mailId;
			this.state.brokerData.brokerType = this.props.history.location.state.brokerType;
			this.setState({ brokerData: this.state.brokerData })
		} else if (this.group == "viewer") {
			this.state.vendor.vendorNumber = this.props.vendorNumber;
			this.setState({ vendor: this.state.vendor })
		} else if (this.group == "opcoviewer") {
			this.state.opco.opcoId = this.props.opcoId;
			this.setState({ opco: this.state.opco })
		}
	}

	myFunction(e) {
		var x = document.getElementById("myTopnav");
		if (x.className === "topnav") {
			x.className += " responsive";
		} else {
			x.className = "topnav";
		}
	}

	routeToHome() {
		if (this.group == "admin" || this.group == "cm") {
			this.props.history.push({
				pathname: '/suite/vbt/admin',
				state: {
					data: this.state.data,
					group: this.group,
					currentUser: this.state.currentUser
				}
			})
		} else if (this.group == "user") {
			this.props.history.push({
				pathname: '/suite/vbt/broker',
				state: {
					data: this.state.data,
					brokerNumber: this.state.brokerData.brokerNumber,
					mailId: this.state.brokerData.mailId,
					brokerCode: this.state.brokerData.brokerCode,
					brokerName: this.state.brokerData.brokerName,
					brokerType: this.state.brokerData.brokerType,
					group: "user",
					currentUser: this.state.currentUser
				}
			})
		} else if (this.group == "viewer") {
			this.props.history.push({
				pathname: '/suite/vbt/vendor',
				state: {
					data: this.state.data,
					vendorNumber: this.state.vendor.vendorNumber,
					group: "viewer"
				}
			})
		} else if (this.group == "opcoviewer") {
			this.props.history.push({
				pathname: '/suite/vbt/opcoviewer',
				state: {
					data: this.state.data,
					opcoId: this.state.opco.opcoId,
					group: "opcoviewer"
				}
			})
		}
	}

	alltiesaudit(e) {
		this.props.history.push({ pathname: '/suite/vbt/all_ties', state: { data: this.state.data, group: this.group, mailId: this.state.brokerData.mailId, brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser } })

	}
	brokertieaudit(e) {
		this.props.history.push({ pathname: '/suite/vbt/broker_ties', state: { data: this.state.data, group: this.group, mailId: this.state.brokerData.mailId, brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser } })

	}
	brokerPOCaudit(e) {
		this.props.history.push({ pathname: '/suite/vbt/broker_poc_audit', state: { data: this.state.data, group: this.group, name: this.name, mailId: this.state.brokerData.mailId, brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser } })

	}

	logout() {
		Auth.logout()
		this.props.history.replace('/suite/vbt');
	}
	duplicate(e) {
		this.props.history.push({
			pathname: '/suite/vbt/duplicate_report', state: {
				data: this.state.data, group: this.group, mailId: this.state.brokerData.mailId, brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName
				, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser
			}
		})
	}
	broker_vendor(e) {
		this.props.history.push({
			pathname: '/suite/vbt/broker_vendor_report', state: {
				data: this.state.data, group: this.group, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser,
				brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName, mailId: this.state.brokerData.mailId, vendorNumber: this.state.vendor.vendorNumber, opcoId: this.state.opco.opcoId
			}
		})

	}
	unassigned(e) {
		this.props.history.push({ pathname: '/suite/vbt/unassigned_report', state: { data: this.state.data, group: this.group, mailId: this.state.brokerData.mailId, brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser } })
	}

	audit(e) {
		this.props.history.push({ pathname: '/suite/vbt/user_audit', state: { data: this.state.data, group: this.group, mailId: this.state.brokerData.mailId, brokerNumber: this.state.brokerData.brokerNumber, brokerCode: this.state.brokerData.brokerCode, brokerName: this.state.brokerData.brokerName, brokerType: this.state.brokerData.brokerType, currentUser: this.state.brokerData.currentUser } })
	}

	render() {
		return (<div className="row"><div className="topnav" style={{ position: "relative", zIndex: "1", width: "100%" }} id="myTopnav">

			<i className="fa fa-home" style={{ float: "left", color: "white", margin: "15px", fontSize: "19px" }} onClick={this.routeToHome}></i>
			<span className="brand nav-logo" style={{ color: "white", fontSize: "20px", position: "absolute" }} >Vendor-Broker Tie Portal</span>

			<a href="javascript:void(0);" style={{ fontSize: "15px" }} className="icon brand" onClick={this.myFunction}>&#9776;</a>
			<span style={{ float: "right" }} className="resp-nav-menu">


				<span className="dropdown nav-items" >
					<button className="dropbtn " style={{ fontFamily: "Work Sans,sans-serif" }}> Reports </button>
					<span className="dropdown-content" style={{ cursor: "pointer", fontFamily: "Work Sans,sans-serif" }}>
						<a onClick={this.unassigned} style={{ display: this.state.ur }}>Unassigned and New Item</a>
						<a onClick={this.duplicate} style={{ display: this.state.dr }}>Duplicate broker-vendor tie reports</a>
						<a onClick={this.broker_vendor} style={{ display: this.state.bv }}>Broker-Vendor tie reports</a>
					</span><span className="line">|</span>
				</span>

				<span className="dropdown nav-items" style={{ display: this.state.audit }}>
					<button className="dropbtn "> Audit Logs

                               </button>
					<span className="dropdown-content" style={{ cursor: "pointer" }}>
						<a onClick={this.audit} style={{ display: this.state.ad }}>User Audits</a>
						<a onClick={this.alltiesaudit} style={{ display: this.state.ad1 }}>Tie Audits</a>
						<a onClick={this.brokerPOCaudit} style={{ display: this.state.ad4 }}>POC Audits</a>
						<a onClick={this.brokertieaudit} style={{ display: this.state.ad2 }}>Tie Audits</a>
						<a onClick={this.brokerPOCaudit} style={{ display: this.state.ad3 }}>POC Audits</a>
					</span>
				</span>

				<span className="dropdown">
					<span className="dropdown-content" style={{ cursor: "pointer" }}>
						<a></a>
					</span>
				</span>
			</span>

		</div>
		{/*
			<footer style={{ position: "fixed", left: "0", bottom: "0", width: "100%", fontSize: "17px", height: "35px !important", textAlign: "left", paddingTop: "6px", backgroundColor: "rgb(196, 197, 199)", color: "white", paddingLeft: "8px", zIndex: "1" }} class="greyFooter"><p>Sysco @2020 All rights Reserved. Sysco Corporation.</p><div style={{ textAlign: "right", marginTop: "-40px", paddingRight: "10px" }}><a href="https://www.sysco.com/Privacy-Policy.html" target="_blank">Privacy Policy</a></div></footer>
		*/}
		</div>
		)
	}
}

export default withRouter(NavBar1);
