import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';


const audit = "getAllTiesAudit";
class AuditLogs extends React.Component {

	constructor(props) {
		super(props)
		this.state = { brokerData: { brokerName: '', brokerCode: '', brokerNumber: '', mailId: '' }, vendor: { vendorNumber: '' }, opco: { opcoId: '' }, adminAudit: "none", brokerAudit: "none", data: [] };
		this.group = this.props.history.location.state.group;

		this.name = this.props.history.location.state.data;
		this.getAllTiesAudit = this.getAllTiesAudit.bind(this);
	}

	componentWillMount() {
		var comp = this;
		$("#loaderAllTies").show();
		comp.getAllTiesAudit(false);
	}

	getAllTiesAudit(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + audit,
			'contentType': 'application/json',
			'dataType': 'json',

		})
			.done(function (data) {
				$("#loaderAllTies").hide();
				for (var el in data.rows) {
					comp.state.data.push({
						"opcoId": data.rows[el].opcoId,
						"itemId": data.rows[el].itemId,
						"vendorNumber": data.rows[el].vendorNumber,
						"brokerNumber": data.rows[el].brokerNumber,
						"startDate": data.rows[el].startDate,
						"endDate": data.rows[el].endDate,
						"auditdate": data.rows[el].auditDate,
						"auditType": data.rows[el].auditType,
						"deletionType": data.rows[el].deletionType
					})
				}
				comp.setState({ data: comp.state.data });

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getAllTiesAudit(true);
				}
				else
					$("#loaderAllTies").hide();
			});
	}

	componentDidMount() {
		initGA();
		PageView();

		$("#loader").hide();
	}

	render() {
		const options1 = {
			sizePerPage: 14,
			noDataText: ' '
		}

		return <div>
			<NavBar1 name={this.name} group={this.group} brokerNumber={this.state.brokerData.brokerNumber} brokerCode={this.state.brokerData.brokerCode} brokerName={this.state.brokerData.brokerName} mailId={this.state.brokerData.mailId} vendorNumber={this.state.vendor.vendorNumber} opcoId={this.state.opco.opcoId} /> <br />
			<br />
			<div className="row tab">
				<h1 className="headings" style={{ textAlign: "left" }}>Tie Audits</h1>

				<div style={{ width: "95vw", overflowX: "auto", overflowY: "hidden" }}>
					<BootstrapTable ref='table' data={this.state.data} options={options1} pagination search={true} striped hover condensed>
						<TableHeaderColumn width="150" ref='brokerUserId' dataField='brokerNumber' dataSort={true} >Broker Number</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='itemId' dataField='itemId' isKey={true} dataSort={true} >Item Id</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='opcoId' dataField='opcoId' dataSort={true} >OPCO Id</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='vendorNumber' dataField='vendorNumber' dataSort={true} >Vendor Number</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='startDate' dataField='startDate' dataSort={true} >Start Date</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='endDate' dataField='endDate' dataSort={true} >End Date</TableHeaderColumn>
						<TableHeaderColumn width="150" dataField='auditType' dataSort={true} >Audit Type</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='auditdate' dataField='auditdate' dataSort={true}>Audit Date</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='deletionType' dataField='deletionType' dataSort={true}>Deleted By</TableHeaderColumn>
					</BootstrapTable>
					<div id="loaderAllTies" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		</div>
	}
}
export default withAuth(AuditLogs);
