import { message, Select } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import sysco from './handshake.png';
import NavBar1 from './navbar.jsx';

var userId, group;
const Option = Select.Option;
const addBrokerURL = 'addUser';
const addOpcoURL = 'addOpco';
const addVendorURL = 'addVendor';
const addCorporateURL = 'addCorporate';
const addAdminURL = 'addAdmin';
const corpBrokerList = "getBrokerNumbersOfCompany";
var brokerNumberList = []
var allBrokerList = []

function checkProperties(obj) {
	for (var key in obj) {
		if (obj[key].length == 0) {
			message.error(<span className="errorMsg">"Please fill all the details"</span>);
			$("#add").prop('disabled', false);
			return false;
		}
	}
	$("#add").prop('disabled', true);
	return true;
}
class AddRowBroker extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			data: { code: '', name: '', bname: '', userName: '', email: '', syscoId: '', contactnumber: '', brokerNumber: '', vno: '', oid: '', oname: '', brokerCode: "", brokerName: "", brokerType: this.props.history.location.state.brokerType !== "Broker Corporate" ? "Normal user" : "Broker Corporate" },
			upload: "none", vendor1: "none", opco1: "none", admin1: "none", cm1: "none", brokerNumber: '', brokerNumberList: []
		}
		this.setEmpState = this.setEmpState.bind(this)
		this.brokerName = this.props.history.location.state.brokerName;
		this.brokerCode = this.props.history.location.state.brokerCode;
		this.brokerNumber = this.props.history.location.state.brokerNumber;
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.groupName = this.props.history.location.state.groupName;
		this.addRows = this.addRows.bind(this);
		this.currentUser = this.props.history.location.state.currentUser;
		this.loginBrokerNumber = this.props.history.location.state.brokerNumber;
		this.loginBrokerName = this.props.history.location.state.brokerName;
		this.getBrokerNumbers = this.getBrokerNumbers.bind(this);
		this.addUser = this.addUser.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {
		brokerNumberList = []
		allBrokerList = []
		var comp = this;
		if (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") {
			let param1 = { "brokerCode": this.props.history.location.state.brokerCode, "brokerNumber": this.props.history.location.state.brokerNumber, "branch": this.props.history.location.state.group == "admin" ? this.props.history.location.state.branch : "corporate" }
			comp.getBrokerNumbers(param1, false);
		}
		//   if(this.props.history.location.state.groupName=="broker"){
		// 	  this.setState({upload:"block",notUpload:"none"});
		//   }
		//   if(this.props.history.location.state.groupName=="viewer"){
		// 	  this.setState({vendor1:"block"});
		//   }
		//   if(this.props.history.location.state.groupName=="opco"){
		// 	  this.setState({opco1:"block"});
		//   }
		//   if(this.props.history.location.state.groupName=="admin"){
		// 	  this.setState({admin1:"block"});
		//   }
		//   if(this.props.history.location.state.groupName=="cm"){
		// 	  this.setState({cm1:"block"});
		//   }

	}

	getBrokerNumbers(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + corpBrokerList,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {
				allBrokerList = data1.rows
				if (data1.rows == null) {
					brokerNumberList.push(<Option key={comp.props.history.location.state.brokerNumber}>{comp.props.history.location.state.brokerNumber}</Option>)
				}
				for (var e in data1.rows) {
					brokerNumberList.push(<Option key={data1.rows[e].brokerNumber}>{data1.rows[e].brokerNumber}</Option>)
				}
				comp.setState({ brokerNumberList });
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getBrokerNumbers(param, true);
				}
			});
	}

	setEmpState(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data });
	}

	handlebrokerNameChange = (event) => {
		if (allBrokerList != null) {
			allBrokerList.map((item) => {
				if (item.brokerNumber == event) {
					this.brokerName = item.brokerName;
					this.state.data["bname"] = item.brokerName;
					this.setState({ data: this.state.data });
				}
			})
		}
		this.setState({ brokerNumber: event })
	}

	addRows(e) {
		e.preventDefault();

		var comp = this;


		var param = {
			"brokerCode": comp.brokerCode.toString(),
			"brokerType": comp.state.data.brokerType.toString(),
			//    "brokerName": comp.brokerName.toString(),
			"brokerNumber": comp.state.brokerNumber.toString(),
			"contactPersonName": comp.state.data.name.toString(),
			"mailId": comp.state.data.email.toString(),
			"userName": comp.state.data.userName.toString(),
			"phoneNumber": comp.state.data.contactnumber.toString(),
			"userGroup": "broker",
			"addedBy": comp.name
		}

		if (checkProperties(param)) {
			comp.addUser(param, false);
		}
	}

	addUser(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {

				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + addBrokerURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (dataq) {

				$("#loader").hide();

			}
		}).done(function (dataq) {
			$("#add").prop('disabled', false);
			if (dataq.result) {
				message.success(<span className="errorMsg">'Added Successfully'</span>);
				if (comp.group == "user") {
					comp.props.history.push({
						pathname: '/suite/vbt/broker',
						state: {
							data: comp.name,
							group: "user",
							brokerType: comp.props.history.location.state.brokerType,
							brokerCode: comp.brokerCode,
							brokerName: comp.loginBrokerName,
							brokerNumber: comp.loginBrokerNumber,
							currentUser: comp.currentUser
						}
					})
				}
				else {
					comp.props.history.push({
						pathname: '/suite/vbt/admin',
						state: {
							data: comp.name,
							group: "admin"
						}
					})
				}

			} else {
				console.log("duplicates")
				message.error(<span className="errorMsg">"Duplicate records"</span>)
			}
		}).fail(function (jqXhr) {
			if (!didFail && jqXhr.status == 401) {
				GetToken();
				comp.addUser(param, true);
			}
			else
				$("#add").prop('disabled', false);
		})
	}

	render() {
		return <div className="container-fluid">

			<NavBar1 brokerType={this.state.data.brokerType} brokerCode={this.brokerCode} currentUser={this.currentUser} name={this.name} group={this.group} brokerName={this.brokerName} brokerNumber={this.brokerNumber} />
			<br />
			<div className="class1">
				<div className="row"><h1 className="headings tab">Add Broker details</h1></div>
				<br />
				{/* <div className="row">
					<h1 className="headings tab" style={{ display: this.state.upload }}>Add Broker Details</h1>
					<h1 className="headings tab" style={{ display: this.state.vendor1 }}>Add Vendor Details</h1>
					<h1 className="headings tab" style={{ display: this.state.opco1 }}>Add OpCo User Details</h1>
					<h1 className="headings tab" style={{ display: this.state.admin1 }}>Add Admin Details</h1>
					<h1 className="headings tab" style={{ display: this.state.cm1 }}>Add Corporate Merchandiser Details</h1>
				</div><br /> */}
				<div className="row" style={{ fontSize: "15px" }}>

					<form className="form-horizontal col-md-7 col-sm-11 addForm" onSubmit={this.addRows}>
						<div className="col-md-offset-3 col-md-7"><h5 style={{ color: "red" }} >Note: All fields are mandatory</h5></div>

						<FormGroup >
							<div className="col-md-3 aname"><label>
								Broker Type: </label></div>
							<select
								className="form-control col-md-6"
								value={this.state.data.brokerType}
								name="brokerType"
								id="brokerType"
								onChange={this.setEmpState}>
								<option value="Normal user">Individual User</option>
								{this.props.history.location.state.branch == "corporate" || this.props.history.location.state.brokerType == "Broker Corporate" ?
									<option value="Broker Corporate">Corporate Broker</option> : ""}
							</select>
						</FormGroup >
						{/* <FormGroup>
							<div className="col-md-3 aname"><label>Broker Type: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="brokerType" value={comp.code=="IB"?"Normal user":"Broker Corporate"} disabled={true} pattern="[A-Z]{2}" title="Please enter the code in caps" maxLength="2" onChange={this.setEmpState} />
							</div>
						</FormGroup> */}

						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Code: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="code" value={this.brokerCode} disabled={true} pattern="[A-Z]{2}" title="Please enter the code in caps" maxLength="2" onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="bname" value={this.brokerName} onChange={this.setEmpState} disabled />
							</div>
						</FormGroup>

						<FormGroup >
							<div className="col-md-3 aname"><label>
								Broker Number: </label></div>
							<div className="col-md-6">
								<Select
									name={"brokerNumber"}
									className={"brokerNumber"}
									style={{ width: 'inherit' }}
									onChange={(event) => this.handlebrokerNameChange(event)}
								>
									{this.state.brokerNumberList}
								</Select></div>
						</FormGroup>

						<FormGroup>
							<div className="col-md-3 aname"><label>Email: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="email" name="email" value={this.state.data.email} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label><span className="addalign">User Name</span>: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="userName" value={this.state.data.userName} onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup>
							<div className="col-md-3 aname"><label>Contact Person Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="name" value={this.state.data.name} onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup >
							<div className="col-md-3 aname"><label>Contact no: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="contactnumber" value={this.state.data.contactnumber} pattern="[0-9]{10}" title="Please enter a 10 digit phone number" maxLength="10" onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<div className="col-md-offset-3 col-md-4">
							<Button type="submit" className="btn btn-primary btn-lg" id="add"> Submit </Button>
						</div>
					</form>
					<div className="col-md-3">
						<img src="/static/media/handshake.c4db6082.png" style={{ paddingTop: "15%" }} className="handshake" alt="image" />
					</div>

				</div>

			</div>
		</div>
	}
}
export default withAuth(AddRowBroker);