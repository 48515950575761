import { message, Table } from 'antd';
import axios from 'axios';
import saveAs from 'file-saver';
import $ from 'jquery';
import React from 'react';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import URL from '../url.js';
import img1 from './loading.gif';
import {checkLinkAvailable, isLinkDisabled, selectCursor} from './reportlogic'

const FileDownload = require('js-file-download');

const fetchStatusURL = 'fetchBrokerTiesReportStatus';
var href1;

class DownloadReport extends React.Component {

	constructor(props) {
		super(props);
		console.log(props);
		this.state = {
			data: ''
		}
	}

	render() {
		return (
			<a>{this.props.row.download}</a>
		)
	}
}

const downloadReport = (onUpdate, props) => (<DownloadReport onUpdate={onUpdate} {...props} />);

class BVRDownloadStatus extends React.Component {
	constructor(props) {

		super(props);

		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.state = { data: [], broker: { brokerCode: '', brokerNumber: '', brokerName: '', mailId: '' } }
		this.currentUser = this.props.history.location.state.currentUser;
		this.fetchBrokerTiesReportStatus = this.fetchBrokerTiesReportStatus.bind(this);
		this.downloadData = this.downloadData.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {
		$("#loaderDownload1").show();
		var comp = this;
		var param = {
			"userName": localStorage.getItem("userId")
		}
		comp.fetchBrokerTiesReportStatus(param, false);

		if (this.group == "user") {
			this.setState({
				broker:
				{
					brokerCode: this.props.history.location.state.brokerCode,
					brokerName: this.props.history.location.state.brokerName,
					brokerNumber: this.props.history.location.state.brokerNumber,
					mailId: this.props.history.location.state.mailId
				}
			}, () => console.log("status", this.state.broker))
		}
	}

	downloadData = (folderName) => {
		//var comp = this;

		const hide = message.loading('Your download is about to start. Please do not hit refresh or back button', 0);
		const instance = axios.create({
			headers: {
				'Accept': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN)
			}
		});
		instance.post(URL.url + 'downloadBrokerTieReport', {
			"downloadId": 0,
			"folderName": folderName,
			"userId": localStorage.getItem('userId')
		}).then(response => {
				setTimeout(hide, 10);
				var a = document.createElement("a");
				a.href = response.data.rows[0];
				a.download = "Broker Vendor Tie.zip";
				a.click();
			})
			.catch(response => {
				setTimeout(hide, 10);
				console.log(response);
			});

	}

	fetchBrokerTiesReportStatus(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + fetchStatusURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param)

		})
			.done(function (res) {
				$("#loaderDownload1").hide();
				var data = [];
				for (var el in res.rows) {
					var no = 0;
					var disableLink = checkLinkAvailable(res.rows[el].status);
					var activeColorCode = isLinkDisabled(disableLink);
					var cursorType = selectCursor(disableLink);
					data.push({
						"key": parseInt(el, 10),
						"fileName": res.rows[el].fileName,
						"path": res.rows[el].path,
						"createdDate": res.rows[el].createdDate,
						"status": res.rows[el].status,
						"download": <span><a style={{ color: activeColorCode, cursor: cursorType }} onClick={disableLink ? null : () => { comp.downloadData(res.rows[el].path) }}>Download</a>&nbsp;&nbsp;&nbsp;</span>
					})
				}
				comp.setState({ data });

				console.log('data', comp.state.data)
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.fetchBrokerTiesReportStatus(param, true);
				}
				$("#loaderDownload1").hide();
			});
	}

	render() {

		const columns = [{
			title: 'File Name',
			dataIndex: 'fileName',
			key: 'fileName',

			width: 70,


		}, {
			title: 'Created Date',
			dataIndex: 'createdDate',
			key: 'createdDate',
			sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
			width: 70,
			defaultSortOrder: 'descend',

		}, {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 70,
			sorter: (a, b) => a.status.length - b.status.length,
			defaultSortOrder: 'descend',
		},
		{
			title: 'Download',
			dataIndex: 'download',
			key: 'download',
			width: 100,

			// render: (text, record1) => {
			// 	console.log("text", text, "record", record1);
			// 	if (record1.status === "Completed") {
			// 		var text1 = record1.count1.map(count => {
			// 			return <span><a style={{ color: "#40a9ff" }} onClick={() => { this.downloadData(count, record1.path.slice(26)) }}>Download</a>&nbsp;&nbsp;&nbsp;</span>
			// 		})
			// 	}
			// 	console.log("text", text1)
			// 	return (
			// 		<div>{text1}</div>
			// 	)
			// }

		}];

		return (
			<div className="container-fluid">
				<NavBar1 currentUser={this.currentUser} name={this.name} group={this.group} brokerNumber={this.state.broker.brokerNumber} brokerCode={this.state.broker.brokerCode} brokerName={this.state.broker.brokerName} mailId={this.state.broker.mailId} />
				<br />
				<h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Download status</h1>
				<br />
				<br />
				<div>
					<Table columns={columns} pagination={{ pageSize: 6 }} dataSource={this.state.data} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} />
					<div id="loaderDownload1" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		)
	}
}

export default withAuth(BVRDownloadStatus);
