import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

var itemId;
'use strict';
const vendorURL = 'Vendor';
const supcURL = 'getSUPC';
//var itemId;


class ItemTable extends React.Component {
	constructor(props) {
		super(props);
		//var row='';
		this.state = {
			name: itemId,
			open: true,
			data: []
		};
	}
	close = () => {
		this.setState({ open: false });
		this.props.onUpdate(this.props.defaultValue);
		this.getSUPC = this.getSUPC.bind(this);
	}

	componentWillMount() {
		var comp = this;
		var param = {
			"itemId": this.state.name
		}
		comp.getSUPC(param, false);
	}

	getSUPC(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + supcURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader").hide();
			}
		})
			.done(function (data) {
				for (var el in data.rows) {
					comp.state.data.push({
						itemId: data.rows[el].itemId,
						itemPack: data.rows[el].itemPack,
						itemSize: data.rows[el].itemSize,
						itemDescr: data.rows[el].itemDescr,
						brand: data.rows[el].brand,
						category: data.rows[el].category,
						major: data.rows[el].major,
						intermediate: data.rows[el].intermediate,
						minor: data.rows[el].minor
					})
				}
				comp.setState({ data: comp.state.data })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getSUPC(param, true);
				}
			});
	}

	componentDidMount() {
		$("#loader").hide();
	}
	render() {
		const row = [];
		for (var i in this.state.data) {
			row.push(<tr key={i}>
				<td>{this.state.data[i].itemId}</td>
				<td>{this.state.data[i].itemPack}</td>
				<td>{this.state.data[i].itemSize}</td>
				<td>{this.state.data[i].itemDescr}</td>
				<td>{this.state.data[i].category}</td>
				<td>{this.state.data[i].minor}</td>
				<td>{this.state.data[i].intermediate}</td>
				<td>{this.state.data[i].major}</td>

			</tr>)
		}

		//const fadeIn = this.state.open ? 'in' : '';
		const display = this.state.open ? 'block' : 'none';
		return (
			<div><span>{this.state.name}</span>
				<div className="modal" id='myModal' role='dialog' style={{ display }} >
					<div className='modal-dialog modal-lg' style={{ margin: "32vh auto auto auto" }}>

						<div className='modal-content'>
							<div className="modal-header">
								<h5 className="modal-title">Item Details</h5>
								<button type="button" className="close" data-dismiss="modal" onClick={this.close} aria-label="Close" style={{ border: "1px solid red", backgroundColor: "red", color: "white" }}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className='modal-body' style={{ overflowX: "auto" }} >
								<div className="inside" style={{ width: "65vw" }}>
									<BootstrapTable ref='itemtable' data={this.state.data} maxHeight={'200px'}>
										<TableHeaderColumn ref='itemId' dataField='itemId' isKey={true} width='50' >
											Item Id</TableHeaderColumn>
										<TableHeaderColumn dataField='itemPack' ref='itemPack' width='50'>Item pack
        </TableHeaderColumn>


										<TableHeaderColumn ref='itemSize' dataField='itemSize' width='30' >Item Size</TableHeaderColumn>
										<TableHeaderColumn ref='itemDescr' dataField='itemDescr' width='100' >Item Description</TableHeaderColumn>

										<TableHeaderColumn ref='category' dataField='category' width='50' >Category</TableHeaderColumn>
										<TableHeaderColumn ref='minor' dataField='minor' width='50' >Minor</TableHeaderColumn>
										<TableHeaderColumn ref='intermediate' dataField='intermediate' width='50' >Intermediate</TableHeaderColumn>
										<TableHeaderColumn ref='major' dataField='major' width='50' >Major</TableHeaderColumn>
									</BootstrapTable>
								</div></div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const createItemTable = (onUpdate, props) => (<ItemTable onUpdate={onUpdate} {...props} />);


export default class VendorFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = { vendorNumber: this.props.vendorNumber, products: [] };
		this.getVendor = this.getVendor.bind(this);
	}

	componentWillMount() {
		var comp = this;
		$("#loaderVF").show();
		var param = {
			"vendorNumber": this.state.vendorNumber
		}
		comp.getVendor(param, false);
	}

	getVendor(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + vendorURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data) {
				$("#loaderVF").hide();
				for (var el in data.rows) {
					comp.state.products.push({
						"brokerCode": data.rows[el].brokerCode,
						"brokerNumber": data.rows[el].brokerNumber,
						"opcoName": data.rows[el].opcoName,
						"opcoId": data.rows[el].opcoId,
						"category": data.rows[el].category,
						"major": data.rows[el].major,
						"minor": data.rows[el].minor,
						"intermediate": data.rows[el].intermediate,
						"brand": data.rows[el].brand,
						"market": data.rows[el].market,
						"itemId": data.rows[el].itemId,
						"itemDescr": data.rows[el].itemDescr,
						"startDate": data.rows[el].startDate.slice(0, 10),
						"endDate": data.rows[el].endDate.slice(0, 10)
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getVendor(param, true);
				}
				else
					$("#loaderVF").hide();
			});
	}

	render() {

		const cellEditProp = {
			mode: 'click',
			blurToSave: true

		};

		const options = {
			onRowClick: function (row) {
				itemId = row.itemId

			},
			noDataText: ' ',
			sizePerPage: 8,
		}

		return (
			<div style={{ overflowX: "auto" }}>
				<BootstrapTable ref='table' data={this.state.products} pagination cellEdit={cellEditProp} options={options} search={true} striped hover condensed>
					<TableHeaderColumn refs="market" dataField='market' dataSort={true} width="100" editable={false}>Market</TableHeaderColumn>
					<TableHeaderColumn refs="opcoId" dataField='opcoId' dataSort={true} width="100" isKey={true} editable={false}>OpCo ID
					</TableHeaderColumn>
					<TableHeaderColumn refs="opcoName" dataField='opcoName' dataSort={true} width="150" editable={false} >OpCo Name</TableHeaderColumn>


					<TableHeaderColumn refs="brokerNumber" dataField='brokerNumber' dataSort={true} width="100" editable={false} >Broker No</TableHeaderColumn>
					<TableHeaderColumn refs="category" dataField='category' dataSort={true} width="50" editable={false}>Cat</TableHeaderColumn>
					<TableHeaderColumn refs="major" dataField='major' dataSort={true} width="50" editable={false}>Maj</TableHeaderColumn>
					<TableHeaderColumn refs="intermediate" dataField='intermediate' dataSort={true} width="50" editable={false}>Int</TableHeaderColumn>
					<TableHeaderColumn refs="minor" dataField='minor' dataSort={true} width="50" editable={false}>Min</TableHeaderColumn>
					<TableHeaderColumn refs="brand" dataField='brand' dataSort={true} width="100" editable={false}>Brand</TableHeaderColumn>
					<TableHeaderColumn refs="itemId" dataField='itemId' dataSort={true} width="100" customEditor={{ getElement: createItemTable }} >Item Id</TableHeaderColumn>
					<TableHeaderColumn ref='itemDescr' dataField='itemDescr' dataSort={true} width="200" editable={false}>Item descripton</TableHeaderColumn>
					<TableHeaderColumn ref='startDate' dataField='startDate' dataSort={true} width="100" editable={false}>Start date</TableHeaderColumn>
					<TableHeaderColumn ref='endDate' dataField='endDate' dataSort={true} width="100" editable={false}>End date</TableHeaderColumn>
				</BootstrapTable>
				<div id="loaderVF" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>

			</div>
		);
	}
}
