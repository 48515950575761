import { Input } from 'antd';
import 'antd/dist/antd.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import URL from '../url.js';
import img1 from './loading.gif';

const Search = Input.Search;
const vnameUrl = 'getVendors';
var rowKeys = [];
var comp;

export default class VendorName extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [], row: { selectedRowKeys: [] }// Check here to configure the default column
		};
		this.onClick = this.onClick.bind(this);
		this.getVendors = this.getVendors.bind(this);
		this.setVendor = this.setVendor.bind(this);
		comp = this;
	}
	onClick(value1) {
		var comp = this;
		let param = {
			"vendorName": value1
		}
		comp.getVendors(param, false);
	}

	getVendors(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader2").show();
			},
			'type': 'POST',
			'url': URL.url + vnameUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader2").hide();
			}
		})
			.done(function (data) {
				comp.state.items = [];
				comp.setState({ items: comp.state.items })

				for (var el in data.rows) {
					comp.state.items.push({

						vendorNumber: data.rows[el].vendorNumber,
						vendorName: data.rows[el].vendorName
					})
				}

				comp.setState({ items: comp.state.items })
			})

			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getVendors(param, true);
				}
			});
	}
	componentDidMount() {
		$("#loader2").hide();
	}

	setVendor(value) {
		if (this.props?.setProperty) {
			this.props.setProperty('vno', value);
		}
	}

	render() {
		const selectRow = {
			mode: 'checkbox',//radio or checkbox
			onSelect: function (row, isSelected) {
				if (isSelected) {
					rowKeys.push(row.vendorNumber);
				} else {
					rowKeys.splice(rowKeys.indexOf(row.vendorNumber), 1);
				}
				var string = rowKeys.join(",");
				comp.setVendor(string);
			},
			onSelectAll: function (isSelected, rows) {
				rowKeys = [];
				if (isSelected) {
					for (var el in comp.state.items) {
						rowKeys.push(comp.state.items[el].vendorNumber);
					}
					var string = rowKeys.join(",");
					comp.setVendor(string);
					return comp.state.items.map(row => row.vendorNumber);
				}
				else {
					rowKeys = [];
					var string1 = rowKeys.join(",");
					comp.setVendor(string1);
					return [];
				}

			}
		};

		const optionss = {
			sizePerPage: 6,
			noDataText: ' '
		};

		return (
			<div>
				<div className="row" style={{ marginLeft: '0px' }}>
					<Search
						placeholder="Search vendor name" className="col-md-7 col-sm-6 col-xs-6"
						onSearch={value1 => { this.onClick(value1) }}
						enterButton
					/>
				</div>
				<div className="row">
					<BootstrapTable ref='table' data={this.state.items} className="col-md-12 col-sm-12" pagination selectRow={selectRow} options={optionss} striped hover condensed>
						<TableHeaderColumn dataField='vendorNumber' isKey={true} width="60" dataSort={true}  >Vendor No
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='vendorName' dataSort={true} width="150" >Vendor Name</TableHeaderColumn>
					</BootstrapTable>
					<div id="loader2" style={{ textAlign: "center", margin: "auto" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		)
	}
}
