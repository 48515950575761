import { Input } from 'antd';
import 'antd/dist/antd.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import URL from '../url.js';
import img1 from './loading.gif';

const Search = Input.Search;
const itemUrl = 'getItemDescr';

var rowKeys = [];
var comp;
export default class ItemDescr extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			selectedRowKeys: []// Check here to configure the default column
		};
		this.onClick = this.onClick.bind(this);
		this.getItemDescr = this.getItemDescr.bind(this);
		this.setItemId = this.setItemId.bind(this);
	}

	componentWillMount() {
		comp = this;
	}
	componentDidMount() {
		$("#loader1").hide();
	}
	onClick(value) {

		var comp = this;
		let param = {
			"itemDescr": value
		}
		comp.getItemDescr(param, false);
	}

	getItemDescr(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader1").show();
			},
			'type': 'POST',
			'url': URL.url + itemUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader1").hide();
			}
		})
			.done(function (data) {
				comp.setState({ items: [] })
				for (var el in data.rows) {
					comp.state.items.push({
						key: el,
						itemId: data.rows[el].itemId,
						itemDescr: data.rows[el].itemDescr
					})
				}
				comp.setState({ items: comp.state.items })
			})

			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getItemDescr(param, true);
				}
			});
	}

	setItemId(value) {
		if (this.props?.setProperty) {
			this.props.setProperty('itemId', value);
		}
	}

	render() {
		const selectRow = {
			mode: 'checkbox',//radio or checkbox
			onSelect: function (row, isSelected) {
				if (isSelected) {
					rowKeys.push(row.itemId);
				} else {
					rowKeys.splice(rowKeys.indexOf(row.itemId), 1);
				}
				var string = rowKeys.join(",");
				comp.setItemId(string);
			},
			onSelectAll: function (isSelected, rows) {
				rowKeys = [];
				if (isSelected) {
					for (var el in comp.state.items) {
						rowKeys.push(comp.state.items[el].itemId);
					}
					var string = rowKeys.join(",");
					comp.setItemId(string);
					return comp.state.items.map(row => row.itemId);
				}
				else {
					rowKeys = [];
					var string1 = rowKeys.join(",");
					comp.setItemId(string1);
					return [];
				}

			}
		};

		const options = {
			sizePerPage: 6,
			noDataText: ' '
		};
		return (
			<div>
				<div className="row" style={{ marginLeft: '0px' }}>
					<Search id="inputIdesc" className="col-md-7 col-sm-6 col-xs-6"
						placeholder="Search item description"
						onSearch={value => { this.onClick(value); }}
						enterButton
					/>
				</div>
				<div className="row">
					<BootstrapTable ref='table' data={this.state.items} className="col-md-12 col-sm-12" pagination selectRow={selectRow} options={options} striped hover condensed>
						<TableHeaderColumn dataField='key' dataSort={true} hidden>key
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='itemId' width="60" isKey={true} dataSort={true}  >Item Id
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='itemDescr' width="150" dataSort={true} >Item Description</TableHeaderColumn>


					</BootstrapTable>
					<div style={{ textAlign: "center", margin: "auto" }}><img src={img1} id="loader1" style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>

		)
	}
}