import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

const auditbroker = "getBrokerTiesAudit";

class AuditLogs2 extends React.Component {

	constructor(props) {
		super(props)
		this.state = { data: [], brokerData: { brokerName: '', brokerCode: '', brokerNumber: '', mailId: '' }, vendor: { vendorNumber: '' }, opco: { opcoId: '' } };

		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.currentUser = this.props.history.location.state.currentUser;
		this.getBrokerTiesAudit = this.getBrokerTiesAudit.bind(this);
	}

	componentWillMount() {
		var comp = this;
		if (this.group == "user") {
			this.state.brokerData.brokerNumber = this.props.history.location.state.brokerNumber;
			this.state.brokerData.brokerCode = this.props.history.location.state.brokerCode;
			this.state.brokerData.brokerName = this.props.history.location.state.brokerName;
			this.state.brokerData.mailId = this.props.history.location.state.mailId;
			this.setState({ brokerData: this.state.brokerData })
			var param = {
				"userName": comp.currentUser
			}
			$("#loaderBrokerTies").show();
			comp.getBrokerTiesAudit(param, false);
		}
	}

	getBrokerTiesAudit(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + auditbroker,
			'contentType': 'application/json',
			'data': JSON.stringify(param),
			'dataType': 'json',

		})
			.done(function (data) {
				$("#loaderBrokerTies").hide();
				for (var el in data.rows) {
					comp.state.data.push({
						"opcoId": data.rows[el].opcoId,
						"itemId": data.rows[el].itemId,
						"vendorNumber": data.rows[el].vendorNumber,
						"startDate": data.rows[el].startDate,
						"endDate": data.rows[el].endDate,
						"auditDate": data.rows[el].auditDate,
						"auditType": data.rows[el].auditType,
						"doneBy": data.rows[el].deletionType,
					})
				}
				comp.setState({ data: comp.state.data });

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getBrokerTiesAudit(param, true);
				}
				else
					$("#loaderBrokerTies").hide();
			});
	}

	componentDidMount() {
		initGA();
		PageView();

		$("#loader").hide();
	}


	render() {
		const options = {
			sizePerPage: 14,
			noDataText: ' '
		}

		return <div>

			<NavBar1 currentUser={this.currentUser} name={this.name} group={this.group} brokerNumber={this.state.brokerData.brokerNumber} brokerCode={this.state.brokerData.brokerCode} brokerName={this.state.brokerData.brokerName} mailId={this.state.brokerData.mailId} vendorNumber={this.state.vendor.vendorNumber} opcoId={this.state.opco.opcoId} /> <br />
			<br />
			<div className="row tab">
				<h1 className="headings" style={{ textAlign: "left" }}>Tie Audits</h1>
				<div className="au-lo" style={{ width: "2000px" }}>
					<BootstrapTable ref='table' data={this.state.data} options={options} pagination style={{ width: "10px", overflowX: 'scroll' }} search={true} striped hover condensed>
						<TableHeaderColumn width="150" ref='vendorNumber' dataField='vendorNumber' isKey={true} dataSort={true} width='150'>Vendor Number</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='opcoId' dataField='opcoId' dataSort={true} width='150' >OPCO Id</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='itemId' dataField='itemId' dataSort={true} width='150' >Item Id</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='startDate' dataField='startDate' dataSort={true} width='150'>Start Date</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='endDate' dataField='endDate' dataSort={true} width='150'>End Date</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='auditType' dataField='auditType' dataSort={true} width='150'>Type of Audits</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='auditDate' dataField='auditDate' dataSort={true} width='150'> Audit Date</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='doneBy' dataField='doneBy' dataSort={true} width='150'>Done By</TableHeaderColumn>
					</BootstrapTable>
					<div id="loaderBrokerTies" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		</div>
	}
}
export default withAuth(AuditLogs2);
