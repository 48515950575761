import { message, Table } from 'antd';
import axios from 'axios';
import saveAs from 'file-saver';
import $ from 'jquery';
import React from 'react';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import URL from '../url.js';
import img1 from './loading.gif';
import {checkLinkAvailable, isLinkDisabled, selectCursor} from './reportlogic'

const FileDownload = require('js-file-download');
const fetchStatusURL = 'fetchDuplicateReportStatus';
var href1;

class DownloadReport extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: ''
		}
	}

	render() {
		return (
			<a>{this.props.row.download}</a>
		)
	}
}

const downloadReport = (onUpdate, props) => (<DownloadReport onUpdate={onUpdate} {...props} />);

class DuplicateDownloadStatus extends React.Component {
	constructor(props) {
		super(props);
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.state = { data: [], broker: { brokerCode: '', brokerNumber: '', brokerName: '', mailId: '' } }
		this.currentUser = this.props.history.location.state.currentUser;
		this.brokerCode = this.props.history.location.state.brokerCode;
		this.fetchDuplicateReportStatus = this.fetchDuplicateReportStatus.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {
		$("#loaderDownload1").show();
		var comp = this;
		var param1 = {
			"userName": localStorage.getItem("userId")
		}
		comp.fetchDuplicateReportStatus(param1, false);

		if (this.group == "user") {
			this.state.broker.brokerCode = this.props.history.location.state.brokerCode;
			this.state.broker.brokerName = this.props.history.location.state.brokerName;
			this.state.broker.brokerNumber = this.props.history.location.state.brokerNumber;
			this.state.broker.mailId = this.props.history.location.state.mailId;
			this.setState({ broker: this.state.broker })
		}
	}

	fetchDuplicateReportStatus(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + fetchStatusURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param)

		})
			.done(function (resp) {
				$("#loaderDownload1").hide();
				var data = [];
				for (var el in resp.rows) {
					var no = 0;
					var disableLink = checkLinkAvailable(resp.rows[el].status);
					var activeColorCode = isLinkDisabled(disableLink);
					var cursorType = selectCursor(disableLink);
					data.push({
						"key": parseInt(el, 10),
						"fileName": resp.rows[el].fileName,
						"path": resp.rows[el].path,
						"createdDate": resp.rows[el].createdDate,
						"status": resp.rows[el].status,
						"download": <span><a style={{ color: activeColorCode, cursor: cursorType }} onClick={disableLink ? null : () => { comp.downloadData(resp.rows[el].path) }}>Download</a>&nbsp;&nbsp;&nbsp;</span>
					})
				}
				comp.setState({ data });
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.fetchDuplicateReportStatus(param, true);
				}
				else
					$("#loaderDownload1").hide();
			});
	}

	downloadData = (folderName) => {
		const hide = message.loading('Your download is about to start. Please do not hit refresh or back button', 0);
		const instance1 = axios.create({
			headers: {
				'Accept': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN)
			}
		});

		instance1.post(URL.url + 'downloadBrokerTieReport', {
			"downloadId": 0,
			"folderName": folderName,
			"userId": localStorage.getItem('userId')

		}).then(response => {
				setTimeout(hide, 10);
				var a = document.createElement("a");
				a.href = response.data.rows[0];
				a.download = "Duplicate report.zip";
				a.click();
			})
			.catch(response => {
				setTimeout(hide, 10);
				console.log(response);
			});

	}

	render() {
		const columns1 = [{
			title: 'File Name',
			dataIndex: 'fileName',
			key: 'fileName',
			width: 70,
		}, {
			title: 'Created Date',
			dataIndex: 'createdDate',
			key: 'createdDate',
			width: 70,
			sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
			defaultSortOrder: 'descend',
		}, {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 70,
			sorter: (a, b) => a.status.length - b.status.length,
			defaultSortOrder: 'descend',
		},
		{
			title: 'Download',
			dataIndex: 'download',
			key: 'download',
			width: 100,

			// render: (text, record) => {
			// 	console.log("text", text, "record", record);
			// 	//var text1="-";
			// 	if (record.status === "Completed") {
			// 		var text1 = record.count1.map(count => {
			// 			return <span><a style={{ color: "#40a9ff" }} onClick={() => { this.downloadData(count, record.path.slice(26)) }}>Download</a>&nbsp;&nbsp;&nbsp;</span>
			// 		})
			// 	}
			// 	console.log("text", text1)
			// 	return (
			// 		<div>{text1}</div>
			// 	)
			// }

		}];

		return (
			<div className="container-fluid">
				<NavBar1 brokerCode={this.brokerCode} name={this.name} group={this.group} brokerNumber={this.state.broker.brokerNumber} brokerCode={this.state.broker.brokerCode} brokerName={this.state.broker.brokerName} mailId={this.state.broker.mailId} currentUser={this.currentUser} />
				<br />
				<h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Download status</h1>
				<br />
				<div>
					<Table columns={columns1} pagination={{ pageSize: 6 }} dataSource={this.state.data} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} />
					<div id="loaderDownload1" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>

		)
	}
}


export default withAuth(DuplicateDownloadStatus);