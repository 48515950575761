import { Input, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import setKeys from '../generalUse/utility';
import URL from '../url.js';
import img1 from './loading.gif';

const Search = Input.Search;
const marketUrl = 'getMarket';
var comp;

export default class MarketFilter extends React.Component {
    state = {
        items: [],
        selectedRows: [],
        loading: false,
        marketFilter: ""
    };

    onClickSearch = () => {
        var comp = this;
        this.setState({ loading: true });

        this.getMarkets(false);
    }

    getMarkets(didFail) {
        let market = this.state.marketFilter;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'GET',
            'url': URL.url + "markets",
            'contentType': 'application/json',
            'dataType': 'json',
            'data': 'market=' + market.trim(),
        }).done(data => {
            let newItems = [];
            this.setState({ loading: true });
            data.rows.map(item => {
                newItems.push(item);
            });

            newItems = setKeys(newItems);
            this.setState({ items: newItems });

        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                this.getMarkets(true);
            }
        })
    }

    displayLoading = () => {
        if (this.state.loading) {
            return <div style={{ textAlign: "center", margin: "auto" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
        }
        else {
            return null;
        }
    }

    onChangeSearch = (event) => {
        this.setState({ marketFilter: event.target.value });
        console.log(event.target.value);
    }

    onOk = () => {
        let rows = [...this.state.selectedRows]
        let markets = rows.join(',');
        this.props.setMarket(markets);
    }

    render() {
        const options = {
            sizePerPage: 6,
            noDataText: ' '
        };

        const selectRow = {
            mode: 'checkbox',
            onSelect: (row, isSelected) => {
                let newRows = [...this.state.selectedRows];
                if (isSelected) {
                    newRows.push(row.market);
                }
                else {
                    newRows.splice(newRows.indexOf(row.market), 1);
                }
                this.setState({ selectedRows: newRows });
            },
            onSelectAll: (isSelected, rows) => {
                if (isSelected) {
                    let newRows = [];
                    rows.map(row => {
                        newRows.push(row.market)
                    })
                    this.setState({ selectedRows: newRows })
                }
                else {
                    this.setState({ selectedRows: [] })
                }
            }
        };

        return (
            <Modal
                title="Search Vendors"
                visible={this.props.visible}
                onOk={this.onOk}
                onCancel={this.props.onCancel}
            >
                <div>
                    <div className="row" style={{ marginLeft: '0px' }}>
                        <Search
                            placeholder="Search Market Name"
                            className="col-md-7 col-sm-6 col-xs-6"
                            onSearch={() => this.onClickSearch()}
                            onChange={(event) => this.onChangeSearch(event)}
                            enterButton
                        />
                    </div>
                    <div className="row">
                        <BootstrapTable ref='table' data={this.state.items} selectRow={selectRow} className="col-md-12 col-sm-12" pagination options={options} striped hover condensed>
                            <TableHeaderColumn
                                dataField='market'
                                isKey={true}
                                dataSort={true}  >
                                Market
                        </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </Modal>
        )
    }
}
