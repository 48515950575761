import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';

var comp;
var rowKeys = [];
var rowDataCode;
var rowDataName;

const adminURL = 'Admin';
const deleteURL = 'deleteUser';

const bNo = {};
function enumFormatter(cell, row, enumObject) {
	return enumObject[cell];
}
var flag;
var clickedButton = false;
var clickedViewButton = false;

class Login extends React.Component {
	constructor(props) {

		super(props);

		comp = this;
		this.state = { data: [], show: "none" };
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.currentUser = this.props.history.location.state.currentUser;
		this.routeToNotf = this.routeToNotf.bind(this);
		this.adda = this.adda.bind(this);
		this.addb = this.addb.bind(this);
		this.addv = this.addv.bind(this);
		this.addo = this.addo.bind(this);
		this.addcm = this.addcm.bind(this);
		this.viewa = this.viewa.bind(this);
		this.viewv = this.viewv.bind(this);
		this.viewo = this.viewo.bind(this);
		this.viewcm = this.viewcm.bind(this);
		this.viewBc = this.viewBc.bind(this);
		this.viewb = this.viewb.bind(this);
		this.addBrokerFromTable = this.addBrokerFromTable.bind(this);
		this.viewBrokerFromTable = this.viewBrokerFromTable.bind(this);

	}

	adda(e) {
		comp.props.history.push({ pathname: '/suite/vbt/add', state: { data: this.name, group: this.group, groupName: 'admin', links: this.links, typePassed: "" } });

	}
	addb(e) {
		comp.props.history.push({ pathname: '/suite/vbt/add', state: { data: this.name, group: this.group, groupName: 'broker', links: this.links, typePassed: "Normal user" } });
	}
	addBrokerFromTable(e) {
		clickedButton = true
		comp.props.history.push({ pathname: '/suite/vbt/add', state: { brokerCode: e.code, brokerName: e.name, data: this.name, group: this.group, groupName: 'broker', links: this.links, typePassed: "Broker Corporate", clickedFromTable: true } });
	}
	addv(e) {
		comp.props.history.push({ pathname: '/suite/vbt/add', state: { data: this.name, group: this.group, groupName: 'viewer', links: this.links, typePassed: "" } });


	}
	addo(e) {
		comp.props.history.push({ pathname: '/suite/vbt/add', state: { data: this.name, group: this.group, groupName: 'opco', links: this.links, typePassed: "" } });


	}
	addcm(e) {
		comp.props.history.push({ pathname: '/suite/vbt/add', state: { data: this.name, group: this.group, groupName: 'cm', links: this.links, typePassed: "" } });


	}
	componentWillMount() {
		comp = this;
		if (this.group == "admin") {
			this.setState({ show: "block" })
		}
		comp.fetchBrokers(false);
	}

	fetchBrokers(didFail) {
		$("#loader").show();
		comp.state.data = [];
		comp.setState({ data: comp.state.data })
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				// 
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + adminURL,
			'contentType': 'application/json',
			'dataType': 'json',
		})
			.done(function (data) {
				$("#loader").hide();
				for (var el in data.rows) {
					comp.state.data.push({
						code: data.rows[el].brokerCode,
						name: data.rows[el].brokerName,
						brokerNumber: data.rows[el].brokerNumber,
						contactPersonName: data.rows[el].contactPersonName,
						phoneNumber: data.rows[el].phoneNumber,
						mailId: data.rows[el].mailId,
						userId: data.rows[el].userId,
						userName: data.rows[el].userName,
						userGroup: data.rows[el].userGroup,
						addedBy: data.rows[el].addedBy,
						branch: data.rows[el].branch
					})
					comp.setState({ data: comp.state.data });
					bNo[el] = data.rows[el].brokerNumber;
				}

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.fetchBrokers(true);
				}
				else
					$("#loader").hide();
			});
	}

	componentDidMount() {
		initGA();
		PageView();

		if (comp.group === "cm") {
			$("#delete").css("display", "none");
			flag = true
		} else {
			flag = false;
		}
	}


	viewa(e) {
		comp.props.history.push({ pathname: '/suite/vbt/vadmin', state: { data: this.name, group: this.group, links: this.links } });

	}
	viewv(e) {
		comp.props.history.push({ pathname: '/suite/vbt/vvendor', state: { data: this.name, group: this.group, links: this.links } });
	}

	viewo(e) {
		comp.props.history.push({ pathname: '/suite/vbt/vopco', state: { data: this.name, group: this.group, links: this.links } });
	}

	viewb(e) {
		comp.props.history.push({ pathname: '/suite/vbt/viewbrokerScreen', state: { data: this.name, group: this.group, groupName: 'broker', links: this.links } });
	}

	viewBc(e) {
		comp.props.history.push({ pathname: '/suite/vbt/viewbrokerScreenCorporates', state: { data: this.name, code: "IB", editBasicData: true, group: this.group, groupName: 'broker', links: this.links } });
	}

	viewBrokerFromTable(e) {
		clickedViewButton = true
		comp.props.history.push({ pathname: '/suite/vbt/viewbrokerScreenCorporates', state: { data: this.name, code: e.code, editBasicData: false, group: this.group, groupName: 'broker', links: this.links, brokerNumber: e.brokerNumber, branch: e.branch } });
	}

	viewcm(e) {
		comp.props.history.push({ pathname: '/suite/vbt/vcm', state: { data: this.name, group: this.group, links: this.links } });

	}

	handleEditButtonClick = (onClick) => {
		//   message.error(<span className="errorMsg">("Brokers already tied to one or more items will not be deleted");
		//var comp=this;
		//var brokerNumber="";
		$("#edit").prop('disabled', true);
		if (rowKeys.length == 1) {
			this.props.history.push({ pathname: '/suite/vbt/editBaseData', state: { data: this.name, group: this.group, code: rowDataCode, name: rowDataName } })
		}
	}
	handleDeleteButtonClick = (onClick) => {
		// message.error(<span className="errorMsg">("Brokers already tied to one or more items will not be deleted");
		var comp = this;
		var brokerNumber = "";
		$("#delete").prop('disabled', true);
		if (rowKeys.length > 1) {
			brokerNumber = rowKeys.join(",");
		} else {
			brokerNumber = rowKeys[0];
		}

		var param = {
			"brokerNumber": brokerNumber,
			"doneBy": comp.name
		};

		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,


			'type': 'POST',
			'url': URL.url + deleteURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data) {
				$("#delete").prop('disabled', false);
				rowKeys = [];

				window.location.reload(true);
				// alert("Broker code " + brokerCode + " delete successfully!");
			})
			.fail(function (jqXhr) {
				$("#delete").prop('disabled', false);
			});


	}
	// createCustomDeleteButton = (onClick) => {
	//   return (

	//                 <Button type="button" className="btn btn-lg buttonDelete" id="delete" style={{marginRight:"14px",color: '#fff', backgroundColor: '#0082cb',borderColor: '#0082cb',fontSize:'14px'}} onClick={ () => this.handleDeleteButtonClick(onClick)}>
	//                     <span><Glyphicon glyph="trash"/><span style={{fontSize:"14px",fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif'}}> Delete </span></span>
	//                 </Button>

	//   );
	// }
	createCustomEditButton = (onClick) => {
		return (

			<Button type="button" className="btn btn-lg buttonDelete" id="edit" style={{ marginLeft: "93px", marginBottom: "-47px", color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '14px' }} onClick={() => this.handleEditButtonClick(onClick)}>
				<span><Glyphicon glyph="edit" /><span style={{ fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }}> Edit </span></span>
			</Button>

		);
	}

	routeToNotf(e) {
		this.props.history.push({
			pathname: '/suite/vbt/notification', state: {
				data: this.name, group: this.group
			}
		})
	}

	showModal = () => {
		this.props.history.push({ pathname: '/suite/vbt/editBaseData' })
	};
	cellButton(cell, row, enumObject, rowIndex) {
		clickedButton = false
		clickedViewButton = false
		return (
			<div>
				{/* <Button type="button" onClick={this.handleEditButtonClick} className="btn btn-sm" style={{marginLeft:"119px",color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '10px' }} >
			   Edit<span><Glyphicon glyph="view" /></span>
			</Button> */}

				{row.branch == "corporate" ? (<div>

					<Button type="button" onClick={() => this.addBrokerFromTable(row)} className="btn btn-md" style={{ marginLeft: "15px", color: '#fff', backgroundColor: 'rgb(0, 140, 210)', borderColor: 'rgb(0, 140, 210)', fontSize: '10px' }} >
						Add {row.code} Brokers<span><Glyphicon glyph="add" /></span>
					</Button>
					<Button type="button" onClick={() => this.viewBrokerFromTable(row)} className="btn btn-md" style={{ marginLeft: "14px", color: '#fff', backgroundColor: 'rgb(0, 140, 210)', borderColor: 'rgb(0, 140, 210)', fontSize: '10px' }} >
						View {row.code} Brokers<span><Glyphicon glyph="view" /></span>
					</Button></div>) : null}
			</div>
		)
	}

	render() {

		const selectRow = {
			// mode: 'checkbox',//radio or checkbox
			onSelect: (row, isSelect, rowIndex, e) => {
				if (isSelect) {
					console.log(row)
					rowKeys.push(row.brokerNumber);
					rowDataCode = row.code
					rowDataName = row.name

				} else {
					rowKeys.splice(rowKeys.indexOf(row.brokerNumber), 1);
				}
			},

			hideSelectColumn: flag,

		};
		const options = {
			noDataText: ' ',
			onRowClick: function (row) {
				!clickedButton && !clickedViewButton ?
					(comp.props.history.push({
						pathname: '/suite/vbt/viewbroker',
						state: {
							data: comp.name,
							group: comp.group,
							row: row
						}
					})) : null

			},
			sizePerPage: 6
		};


		return (
			<div className="container-fluid">

				<NavBar1 name={this.name} group={this.group} currentUser={this.currentUser} />
				<br />

				<div><h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Strategic Brokers Details</h1>
					<span style={{ float: "right" }}>
						<span className="dropdown dd-span">
							<button className="button button2 btn btn-primary" style={{ display: this.state.show, color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '12px', fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }} onClick={this.add}>+ Add</button>
							<span className="dropdown-content" style={{ cursor: "pointer" }}>
								<a onClick={this.adda} style={{ display: this.state.ur }}>Add Admin</a>
								<a onClick={this.addb} style={{ display: this.state.dr }}>Add Broker Company</a>
								<a onClick={this.addv} style={{ display: this.state.bv }}>Add Vendor</a>
								<a onClick={this.addo} style={{ display: this.state.bv }}>Add OpCo</a>
								<a onClick={this.addcm} style={{ display: this.state.bv }}>Add CM</a>
							</span>
						</span>

						<span className="dropdown  dd-span">
							<button className="button button2 btn btn-primary" style={{ color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '12px', fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }} onClick={this.add}>+ View</button>
							<span className="dropdown-content" style={{ cursor: "pointer" }}>
								<a onClick={this.viewa} style={{ display: this.state.ur }}>View Admin</a>
								{this.props.history.location.state.group == "admin" ? (<a onClick={this.viewb} style={{ display: this.state.ur }}>View Broker</a>) : ""}
								{this.props.history.location.state.group == "admin" ? (<a onClick={this.viewBc} style={{ display: this.state.ur }}>View Broker Company</a>) : ""}
								<a onClick={this.viewv} style={{ display: this.state.bv }}>View Vendor</a>
								<a onClick={this.viewo} style={{ display: this.state.bv }}>View OpCo</a>
								<a onClick={this.viewcm} style={{ display: this.state.bv }}>View CM</a>
							</span>
						</span>

						<button className="button button2 btn btn-primary" style={{ display: this.state.show, float: "right", color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '12px', fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }} onClick={this.routeToNotf}>
							Resolve Duplicates</button>
					</span></div>
				<br /><br />
				<div style={{ overflowX: "auto" }}>
					{/* <Button type="button" className="btn btn-lg buttonDelete" id="delete" style={{marginLeft:"93px",marginBottom:"-47px",color: '#fff', backgroundColor: '#0082cb',borderColor: '#0082cb',fontSize:'14px'}} onClick={ () => this.handleEditButtonClick()}>
									<span><Glyphicon glyph="edit"/><span style={{fontSize:"14px",fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif'}}> Edit </span></span>
									</Button> */}
					<BootstrapTable ref='table' data={this.state.data} pagination search={true} options={options} striped hover condensed selectRow={selectRow} >
						{/* selectRow={selectRow} */}
						{/* <TableHeaderColumn width="30" dataFormat={formatName}>Edit
										</TableHeaderColumn> */}
						<TableHeaderColumn dataField='code' width="80" isKey={true} dataSort={true}  >Broker Code
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='name' width="130" dataSort={true}>Broker Name
										</TableHeaderColumn>
						<TableHeaderColumn width="100" dataFormat={this.cellButton.bind(this)}>
						</TableHeaderColumn>
						{/* <TableHeaderColumn  width="150" >Buttons
										</TableHeaderColumn> */}
						{/* <TableHeaderColumn dataField='contactPersonName' width="150"  dataSort={ true } hidden>Contact Person Name</TableHeaderColumn>
										<TableHeaderColumn  dataField='branch' width="180"  dataSort={ true } hidden>Branch</TableHeaderColumn>
                                        <TableHeaderColumn  dataField='mailId' width="180"  dataSort={ true } hidden>Email</TableHeaderColumn>
                                        <TableHeaderColumn  dataField='phoneNumber' width="100" dataSort={ true } hidden >Contact Number</TableHeaderColumn>
                                        <TableHeaderColumn  dataField='userName' width="100"  dataSort={ true } hidden >User Name</TableHeaderColumn>
                                        <TableHeaderColumn  dataField='userId' width="100"  dataSort={ true } hidden>User Id</TableHeaderColumn>
                                        <TableHeaderColumn  dataField='userGroup' width="100" dataSort={ true } hidden >User Group</TableHeaderColumn>
                                        <TableHeaderColumn  dataField='addedBy' width="100" dataSort={ true } hidden>Added By</TableHeaderColumn>  */}

					</BootstrapTable>
					<div id="loader" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>

				</div>
			</div>

		)
	}



}


export default withAuth(Login);