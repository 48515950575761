import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

const duplicateURL = 'getDuplicateTieReport';

export default class Duplicate_report_table extends React.Component {

	constructor(props) {
		super(props);
		this.name = this.props.name;
		this.group = this.props.group;
		this.currentUser = this.props.currentUser;
		this.brokerType = this.props.brokerType;

		this.state = {
			counter: 1,
			products: []
		};

		this.getDuplicateTieReport = this.getDuplicateTieReport.bind(this);
	}


	componentWillMount() {
		var comp = this;
		$("#loaderDuplicate").show();
		comp.getDuplicateTieReport(false);
	}

	incrementCounter = () => {
		var counter = this.state.counter;
		counter += 1;
		console.log("local counter", counter);
		this.setState({ counter }, () => {
			console.log("value", $('.search').val());
			console.log("counter", this.state.counter);
			this.getDuplicateTieReport();
		})
	}

	decrementCounter = () => {
		var counter = this.state.counter;
		counter -= 1;
		this.setState({ counter }, () => {
			console.log("value", $('.search').val());
			console.log("counter", this.state.counter);
			this.getDuplicateTieReport();
		})
	}

	search = () => {
		this.setState({ counter: 1 }, () => { this.getDuplicateTieReport(); });
	}

	getDuplicateTieReport(didFail) {
		var dataInput = {
			"pageNumber": this.state.counter,
			"searchFunctionality": $('.searchBar').val() ? "yes" : "no",
			"searchValue": $('.searchBar').val()
		}
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + duplicateURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(dataInput)
		})
			.done(function (data) {
				comp.setState({ products: [] });
				$("#loaderDuplicate").hide();
				//console.log("data.rows",data.rows)
				for (var el in data.rows) {
					comp.state.products.push({
						"vendorNumber": data.rows[el].vendorNumber,
						"vendorName": data.rows[el].vendorName,
						"itemId": data.rows[el].itemId,
						"itemDescr": data.rows[el].itemDescr,
						"opcoId": data.rows[el].opcoId,
						"opcoName": data.rows[el].opcoName,
						"brokerCode": data.rows[el].brokerCode,
						"market": data.rows[el].market,
						"brand": data.rows[el].brand,
						"category": data.rows[el].category,
						"major": data.rows[el].major,
						"intermediate": data.rows[el].intermediate,
						"minor": data.rows[el].minor,
						"startDate": data.rows[el].startDate.slice(0, 10),
						"endDate": data.rows[el].endDate.slice(0, 10)
						//"resolvedDate":data.rows[el].conflictResolveDate===null ? "" :data.rows[el].conflictResolveDate.slice(0,10)
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getDuplicateTieReport(true);
				}
				else
					$("#loaderDuplicate").hide();
			});
	}

	render() {
		const options = {
			sizePerPage: 14,
			noDataText: ' '
		};
		return (
			<div>
				<div className="row">
					<div className="searchGroup col-md-4 col-md-offset-8" style={{ padding: "0px 15px !important", float: 'right', display: 'flex' }}>
						<input type="text" className="form-control searchBar" style={{ width: '87%' }} placeholder="Search" ariaLabel="Search" ariaDescribedBy="button-addon2" />
						<div className="input-group-append" style={{ position: 'sticky' }}>
							<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.search.bind(this)}><span className="glyphicon glyphicon-search" aria-hidden="true"></span></button>
						</div>
					</div>
				</div>
				<div style={{ width: "95vw", overflowX: "auto", overflowY: "hidden" }}>
					<BootstrapTable ref='table' data={this.state.products} options={options} striped hover condensed>
						<TableHeaderColumn width="100" ref='vendorNumber' dataField='vendorNumber' dataSort={true} >Vendor No</TableHeaderColumn>
						<TableHeaderColumn width="250" ref='vendorName' dataField='vendorName' dataSort={true}>Vendor Name</TableHeaderColumn>
						<TableHeaderColumn width="120" ref='brokerCode' dataField='brokerCode' isKey={true} dataSort={true} >Broker Code</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='market' dataField='market' dataSort={true}>Market</TableHeaderColumn>
						<TableHeaderColumn width="80" ref='opcoId' dataField='opcoId' dataSort={true} >OpCo</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='opcoName' dataField='opcoName' dataSort={true} >OpCo Name</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='category' dataField='category' dataSort={true}>Category</TableHeaderColumn>
						<TableHeaderColumn width="80" ref='major' dataField='major' dataSort={true}>Major</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='intermediate' dataField='intermediate' dataSort={true}>Intermediate</TableHeaderColumn>
						<TableHeaderColumn width="80" ref='minor' dataField='minor' dataSort={true}>Minor</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='brand' dataField='brand' dataSort={true}>Brand</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='itemId' dataField='itemId' dataSort={true}>Item Id</TableHeaderColumn>
						<TableHeaderColumn width="250" ref='itemDescr' dataField='itemDescr' dataSort={true}>Item Description</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='startDate' dataField='startDate' dataSort={true}>Start Date</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='endDate' dataField='endDate' dataSort={true}>End Date</TableHeaderColumn>
					</BootstrapTable>
					<button className="btn btn-primary" onClick={this.decrementCounter} disabled={this.state.counter === 1 ? true : false}>Previous</button>
					<button className="btn btn-primary" onClick={this.incrementCounter}>Next</button>
					<div id="loaderDuplicate" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		);
	}
}
