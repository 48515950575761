import { Button, Col, Row } from 'antd';
import $ from 'jquery';
import React, { Component } from 'react';
import { ID_TOKEN, GetToken } from '../../APIC Generator/APICTokenGenerator';
import '../../style.css';
import URL from '../../url';
import PocDetailForm from './PocDetailForm/PocDetailForm';

class PocSubmission extends Component {
    state = {
        allOpcos: [],
        brokerNumber: "",
        opcoNumber: "",
        name: "",
        title: "",
        addressLane1: "",
        addressLane2: "",
        addressLane3: "",
        addressLane4: "",
        phone: "",
        fax: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        email: ""
    }

    componentWillMount = () => {
        this.getOpcos();
    }

    getOpcos() {
        let param = {
            "opcoName": ""
        }
        this.getOpcoName(param, false);
    }

    getOpcoName(param, didFail) {
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + "getOpcoName",
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),
        }).done(data => {
            console.log(data)
            this.setState({ allOpcos: data.rows })
        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                this.getOpcoName(true);
            }
        })
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {
        // const name = this.props.history.location.state.data
        // const group = this.props.history.location.state.group
        return (
            <div>
                <PocDetailForm
                    title={this.props.title}
                    handleInput={this.handleInput}
                    input={this.state} />
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} style={{ marginRight: '40px' }} />
                    <Col span={1}>
                        <Button
                            style={{ color: 'white' }}
                            onClick={() => this.props.onSubmit(this.state)}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default PocSubmission;