import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';

var comp;
var rowKeys = [];

const vendorURL = 'viewVendor';



class ViewVendor extends React.Component {
	constructor(props) {
		super(props);
		this.state = { data: [] };
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.ViewVendors = this.ViewVendors.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {
		$("#loaderVendor").show();
		var comp2 = this;
		comp2.ViewVendors(false);
	}

	ViewVendors(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'contentType': 'application/json',
			'url': URL.url + vendorURL,
			'dataType': 'json',

		})
			.done(function (data) {
				$("#loaderVendor").hide();

				for (var el in data.rows) {
					comp.state.data.push({
						"vendorNumber": data.rows[el].vendorNumber,
						"contactPersonName": data.rows[el].contactPersonName,
						"phoneNumber": data.rows[el].phoneNumber,
						"mailId": data.rows[el].mailId,
						"userName": data.rows[el].userName,
						"userGroup": data.rows[el].userGroup,
						"userId": data.rows[el].userId,
						"addedBy": data.rows[el].addedBy,
					})
					comp.setState({ data: comp.state.data });
				}


			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.ViewVendors(true);
				}
				$("#loaderVendor").hide();
			});
	}

	render() {
		var comp = this;
		const options1 = {

			onRowClick: function (row1) {
				comp.props.history.push({
					pathname: '/suite/vbt/vendor',
					state: {
						contactPersonName: comp.name,
						group: comp.group,
						row: row1
					}
				})

			},
			noDataText: ' ',
			sizePerPage: 6
		};

		return (
			<div className="container-fluid">

				<NavBar1 name={this.name} group={this.group} />
				<br />
				<h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Vendor Details</h1>
				<br />
				<div style={{ overflowX: "auto" }}>
					<BootstrapTable ref='table' data={this.state.data} pagination options={options1} search={true} >
						<TableHeaderColumn dataField='vendorNumber' width="100" dataSort={true}  >Vendor Number
									</TableHeaderColumn>
						<TableHeaderColumn dataField='contactPersonName' width="100" isKey={true} dataSort={true}  >Vendor Name
									</TableHeaderColumn>
						<TableHeaderColumn dataField='mailId' width="180" dataSort={true} >Email Id</TableHeaderColumn>
						<TableHeaderColumn dataField='phoneNumber' width="100" dataSort={true} >Contact Number</TableHeaderColumn>
						<TableHeaderColumn dataField='userName' width="100" dataSort={true} >User Name</TableHeaderColumn>
						<TableHeaderColumn dataField='userGroup' width="100" dataSort={true} hidden>Group</TableHeaderColumn>
						<TableHeaderColumn dataField='addedBy' width="100" dataSort={true} hidden>Added by</TableHeaderColumn>
						<TableHeaderColumn dataField='userId' width="100" dataSort={true} hidden>user id</TableHeaderColumn>


					</BootstrapTable>

				</div>
				<div id="loaderVendor" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>


			</div>

		)
	}



}


export default withAuth(ViewVendor);