import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import NavBar1 from '../admin welcome/navbar.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import VendorFilters from './vendorfilters.jsx';

var comp;
class Vendor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				vendorNumber: '',
				contactPersonName: '',
				mailId: '',
				phoneNumber: ''
			}, edit: "none",
		};
		this.name = this.props.history.location.state.contactPersonName;
		this.group = this.props.history.location.state.group;
		this.setEmpState = this.setEmpState.bind(this)
		this.row = this.props.history.location.state.row;
		comp = this;
		this.edit = this.edit.bind(this);

	}

	componentWillMount() {
		if (this.group === "admin" || this.group === "cm") {
			this.state.data = {
				vendorNumber: this.row.vendorNumber,
				contactPersonName: this.row.contactPersonName,
				mailId: this.row.mailId,
				phoneNumber: this.row.phoneNumber
			};

			this.setState({
				data: this.state.data
			})

		}
		else if (this.group === "viewer") {

			this.state.data = {
				vendorNumber: this.props.history.location.state.vendorNumber,
				contactPersonName: this.props.history.location.state.contactPersonName,
				mailId: this.props.history.location.state.mailId,
				phoneNumber: this.props.history.location.state.phoneNumber

			};

			this.setState({
				data: this.state.data
			})
		}
		if (this.group === "admin") {
			this.setState({ edit: "inline-block" })
		}
	}

	setEmpState(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data, value: e.target.value });
	}

	edit(e) {
		this.props.history.push({
			pathname: "/suite/vbt/editVendor",
			state: {
				data: this.name,
				row: this.row,
				group: this.group
			}
		})
	}

	render() {
		return <div className="container-fluid">
			<NavBar1 name={this.name} group={this.group} vendorNumber={this.state.data.vendorNumber} /><br />

			<h1 className="headings" style={{ textAlign: "left" }}><span>{this.state.data.vendorNumber} </span><span>{this.state.data.contactPersonName}</span>
			</h1><span style={{ float: "right" }}> <Button className="btn btn-primary btn-lg" onClick={this.edit} style={{ backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif', display: this.state.edit }}>Edit vendor details</Button></span><br /><br />
			<div><VendorFilters vendorNumber={this.state.data.vendorNumber} /></div>
		</div>

	}

}
export default withAuth(Vendor);
