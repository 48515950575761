import $ from "jquery";

export const GetToken = () => {
    $.ajax({
        'type': 'GET',
        'url': '/token',
        async: false
    }).done(function (data) {
        var jsonObj = JSON.parse(data);
        localStorage.setItem(ID_TOKEN, 'Bearer ' + jsonObj.access_token);
    }).fail(function (jqXhr) {
        console.log("Failed to retrieve token.");
    });
}

export const ID_TOKEN = 'id_token';
