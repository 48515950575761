import { message } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import sysco1 from './handshake.png';
import NavBar1 from './navbar.jsx';

const updateURL = 'updateCorporates';

class EditScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {
				contactPersonName: this.props.history.location.state.row.contactPersonName,
				userName: this.props.history.location.state.row.userName,
				phoneNumber: this.props.history.location.state.row.phoneNumber,
				addedBy: this.props.history.location.state.row.addedBy,
				userGroup: this.props.history.location.state.row.userGroup,
				mailId: this.props.history.location.state.row.mailId,
				userId: this.props.history.location.state.row.userId,
			}
		};
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.setEmpState2 = this.setEmpState2.bind(this);
		this.editRows2 = this.editRows2.bind(this);
		this.updateCorporate = this.updateCorporate.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}


	setEmpState2(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data });
	}
	editRows2(e) {
		e.preventDefault();
		$("#edit").prop('disabled', true);
		var comp = this;
		var param11 = {
			contactPersonName: comp.state.data.contactPersonName,
			userName: comp.state.data.userName,
			phoneNumber: comp.state.data.phoneNumber,
			addedBy: comp.state.data.addedBy,
			mailId: comp.state.data.mailId,
			doneBy: comp.name,
			userGroup: comp.state.data.userGroup,
			userId: comp.state.data.userId,
		}
		comp.updateCorporate(param11, false);
	}

	updateCorporate(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + updateURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data1) {
				$("#edit").prop('disabled', false);
				if (data1.result) {
					message.success(<span className="errorMsg">'Edited successfully'</span>)
				} else {
					message.error(<span className="errorMsg">"Duplicate entry"</span>)
				}
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.updateCorporate(param, true);
				}
				else
					$("#edit").prop('disabled', false);
			});
	}
	render() {
		return (<div>
			<NavBar1 name={this.name} group={this.group} />
			<br />
			<div className="class1">

				<div className="row"><h1 className="headings tab">Update Corporate Merchandiser details</h1></div>
				<br />
				<div className="row" style={{ fontSize: "15px" }}>
					<form className="form-horizontal col-md-7 col-sm-11 editForm" onSubmit={this.editRows2}>
						<div className="col-md-offset-3 col-md-7"><h5 style={{ color: "red" }} >Note: All fields are mandatory</h5></div>

						<FormGroup>
							<div className="col-md-3 aname"><label>Corporate Merchandiser Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="contactPersonName" value={this.state.data.contactPersonName} onChange={this.setEmpState2} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Email: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="email" value={this.state.data.mailId} name="mailId" onChange={this.setEmpState2} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>User Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="userName" value={this.state.data.userName} onChange={this.setEmpState2} required />
							</div>
						</FormGroup>

						<FormGroup>
							<div className="col-md-3 aname"><label>Contact No: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="phoneNumber" value={this.state.data.phoneNumber} pattern="[0-9]{10}" maxLength="10" title="Please enter a 10 digit phone number" onChange={this.setEmpState2} required />
							</div>
						</FormGroup>
						<div className="col-md-offset-3 col-md-4">
							<button type="submit" className=" btn btn-primary btn-lg" id="edit"> Update </button>
						</div>
					</form>
					<div className="col-md-3">
						<img src="/static/media/handshake.c4db6082.png" style={{ paddingTop: "10%" }} className="handshake" alt="hi" />
					</div>
				</div>
			</div>
		</div>)
	}
}

export default withAuth(EditScreen);