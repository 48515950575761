import { message } from 'antd';
import $ from 'jquery';
import React from 'react';
import Workbook from 'react-excel-workbook';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import AuthService from '../login/AuthService';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import AllFilters from './filters.jsx';
const Auth = new AuthService();
const uploadURL = 'upload';
const fetchErrorURL = 'uploadErrorData';

var error = [];
var comp;
class MainScreen2 extends React.Component {
	constructor(props) {
		super(props);
		comp = this;
		this.state = {
			data: {
				brokerName: '',
				mailId: '',
				brokerCode: '',
				brokerNumber: '',
				branch: "",
				file: '',
				currentUser: '',
			},
			edit: "none",
			viewAll: "block",
			upload: "none",
			download1: "none",
			item: "block",
			fileList: []
		};
		this.name = this.props.history.location.state.data;

		this.group = this.props.history.location.state.group;
		this.row = this.props.history.location.state.row;
		this.setEmpState = this.setEmpState.bind(this);
		this.fetchError = this.fetchError.bind(this);
		this.currentUser = props.history.location.state.currentUser;
		this.upload = this.upload.bind(this);
		this.uploadErrorData = this.uploadErrorData.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {

		if (this.group === "admin" || this.group === "cm") {
			let newData = {
				brokerCode: this.row.code,
				brokerName: this.row.name,
				brokerNumber: this.row.brokerNumber,
				mailId: this.row.mailId,
				branch: this.row.branch
			};

			this.setState({ data: newData })


		}
		else if (this.group === "user") {

			let newData = {
				brokerCode: this.props.history.location.state.brokerCode,
				brokerName: this.props.history.location.state.brokerName,
				brokerNumber: this.props.history.location.state.brokerNumber,
				mailId: this.props.history.location.state.mailId,
				brokerType: this.props.history.location.state.brokerType

			};

			this.setState({ data: newData })
		}

		if (this.group === "admin") {
			this.setState({ edit: "inline-block", viewAll: "none", item: "inline-block", download1: "inline-block", upload: "inline-block" });
		}
		console.log("mscreen props", this.props)
		console.log("mscreen state", this.state)
	}

	setEmpState(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data, value: e.target.value });
	}

	uploadAction() {
		const hide = message.loading('Adding Ties. Please do not reload or exit the page', 0);
		var date1 = new Date()
		var dateString = String(date1)
		var date = dateString.split(" ").join('').split(":").join('').split("(").join("").split(")").join("").split("+").join("");

		var data = new FormData();
		var imagedata = document.querySelector('input[type="file"]').files[0];
		data.append("file", imagedata);

		data.append("userId", comp.name);
		data.append("createdDate", date);

		$("#upload").attr("disabled", true);
		$("#errors").attr("disabled", true);
		this.upload(data, date, hide, false);
	}

	upload(data, date, hide, didFail) {
		$.ajax({
			method: "POST",
			headers: {
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			enctype: 'multipart/form-data',
			url: URL.url + uploadURL + "/" + comp.name + "/" + date + "/",
			data: data,
			processData: false,
			contentType: false,
			cache: false,
			timeout: 600000,

			success: function (data) {

				$("#upload").attr("disabled", false);
				comp.fetchError(date);
				setTimeout(hide, 10);
			},
			error: function (e) {
				setTimeout(hide, 10);

				$("#upload").prop("disabled", false);
			}
		}).fail(function (jqXhr) {
			if (!didFail && jqXhr.status == 401) {
				GetToken()
				comp.upload(data, date, hide, true);
			}
		});
	}

	enableDesc() {
		this.setState({ searchDesc: true });
	}

	check(e) {
		e.preventDefault();
		var fileName = $("input[type=file]").val();
		var ext = fileName.slice(-3);
		if (ext !== "csv") {

			message.error(<span className="errorMsg">"Upload CSV file only"</span>);

			return false;

		} else {
			this.uploadAction();
		}
	}
	fetchError(date) {
		comp.setState({ error: [] });
		var param = {
			"createdDate": date,
			"uploadedBy": comp.name
		}
		comp.uploadErrorData(param, false);
	}

	uploadErrorData(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + fetchErrorURL,

			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data) {
				for (var el in data.rows) {
					comp.state.error.push({
						brokerNumber: data.rows[el].brokerNumber,
						vendorNumber: data.rows[el].vendorNumber,
						opcoId: data.rows[el].opcoId,
						itemId: data.rows[el].itemId,
						startDate: data.rows[el].startDate,
						endDate: data.rows[el].endDate,
					})
				}
				comp.setState({ error: comp.state.error })
				if (comp.state.error.length > 0) {
					$("#errors").prop("disabled", false);
					message.error(<span className="errorMsg">"There are errors in the uploaded data.Click 'check errors' to view details"</span>);
				}
				else {
					$("#errors").prop("disabled", true);
					message.success(<span className="errorMsg">"Ties are added successfully"</span>);
					window.location.reload(true);
				}
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken()
					comp.uploadErrorData(param, true);
				}
				else
					message.error(<span className="errorMsg">"Something went wrong!"</span>)
			});
	}
	render() {
		const data = []

		return <div className="container-fluid" style={{ overflowX: "hidden" }}>
			<NavBar1 name={this.name} group={this.group} brokerNumber={this.state.data.brokerNumber} brokerCode={this.state.data.brokerCode} brokerName={this.state.data.brokerName} mailId={this.state.data.mailId} brokerType={this.state.data.brokerType} currentUser={this.currentUser} />
			<br />


			<div className="row tab" style={{ align: "center" }}>
				<div><AllFilters branch={this.state.data.branch} brokerType={this.state.data.brokerType} data={this.name} group={this.group} row={this.row} brokerNumber={this.state.data.brokerNumber} brokerCode={this.state.data.brokerCode} brokerName={this.state.data.brokerName} mailId={this.state.data.mailId} edit={this.state.edit} item={this.state.item} currentUser={this.currentUser} brokerNumber={this.state.data.brokerNumber} /></div>
			</div>	<br />
			<div className="row" style={{ position: "relative" }}>
				<div className="row text-center download1" style={{ display: this.state.download1 }}>
					<Workbook filename="Template.xlsx" element={<button className="btn btn-lg btn-primary " style={{
						cursor: 'pointer', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif',
						marginLeft: "4vw"
					}}>Download Template</button>}>
						<Workbook.Sheet data={data} name="Sheet A" >
							<Workbook.Column label="OPCO_ID" value="" />
							<Workbook.Column label="ITEM_ID" value="" />
							<Workbook.Column label="VENDOR_NUMBER" value="" />
							<Workbook.Column label="BROKER_NUMBER" value="" />
							<Workbook.Column label="START_DATE (YYYY-MM)" value="" />
							<Workbook.Column label="END_DATE (YYYY-MM)" value="" />
							<Workbook.Column label="USER NAME" value="" />
						</Workbook.Sheet>
					</Workbook>
				</div>
				<div className="col-md-12 col-xs-12" style={{ position: "relative", marginLeft: "1.8vw", marginTop: "4vh", display: this.state.download1, }} >
					<form encType="multipart/form-data" onSubmit={this.check.bind(this)} style={{ fontSize: "small", left: "6px", color: "black" }} >
						<input type="file" name="txtFile" id="bulk_upload_file" accept=".csv" id="txtFile" required />
						<button type="submit" id="upload" className="btn btn-primary btn-lg" >Upload</button>
					</form>

					<Workbook filename="Errors.xlsx" element={<button style={{ marginBottom: "35px" }} className="btn btn-danger btn-lg" id="errors" disabled="true">Check errors</button>}>
						<Workbook.Sheet data={comp.state.error} name="Errors">
							<Workbook.Column label="OPCO_ID" value={row => row.opcoId} />
							<Workbook.Column label="ITEM_ID" value={row => row.itemId} />
							<Workbook.Column label="VENDOR_NUMBER" value={row => row.vendorNumber} />
							<Workbook.Column label="BROKER_NUMBER" value={row => row.brokerNumber} />
							<Workbook.Column label="START_DATE (YYYY-MM)" value={row => row.startDate} />
							<Workbook.Column label="END_DATE (YYYY-MM)" value={row => row.endDate} />
							<Workbook.Column label="USER NAME" value={row => row.userName} />
						</Workbook.Sheet>
					</Workbook>
				</div>

			</div>
		</div>
	}
}
export default withAuth(MainScreen2);