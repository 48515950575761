import { message } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import sysco1 from './handshake.png';
import NavBar1 from './navbar.jsx';

const editURL = 'editBroker';
const updateURL = 'updateUser';

class EditScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {
				brokerNumber: this.props.history.location.state.row.brokerNumber,
				phoneNumber: this.props.history.location.state.row.phoneNumber,
				addedBy: this.props.history.location.state.row.addedBy,
				userGroup: this.props.history.location.state.row.userGroup,
				userId: this.props.history.location.state.row.userId,
				brokerCode: this.props.history.location.state.row.code,
				brokerName: this.props.history.location.state.row.name,
				contactPersonName: this.props.history.location.state.row.contactPersonName,
				mailId: this.props.history.location.state.row.mailId,
				userName: this.props.history.location.state.row.userName,
			}
		};
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.setEmpState4 = this.setEmpState4.bind(this);
		this.editRows4 = this.editRows4.bind(this);
		this.updateUser = this.updateUser.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}


	setEmpState4(ee) {
		var field = ee.target.name;
		var value = ee.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data });
	}
	editRows4(event1) {
		event1.preventDefault();
		$("#edit4").prop('disabled', true);
		var comp = this;
		var inputBean = {
			"brokerCode": comp.state.data.brokerCode,
			"brokerName": comp.state.data.brokerName,
			"contactPersonName": comp.state.data.contactPersonName,
			"mailId": comp.state.data.mailId,
			"userName": comp.state.data.userName,
			"phoneNumber": comp.state.data.phoneNumber,

			"userGroup": comp.state.data.userGroup,
			"userId": comp.state.data.userId,
			"addedBy": comp.state.data.addedBy,
			"doneBy": comp.name,
			"brokerNumber": comp.state.data.brokerNumber
		}
		comp.updateUser(inputBean, false);
	}
	updateUser(inputBean, didfail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + updateURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(inputBean),
		})
			.done(function (data1) {
				$("#edit4").prop('disabled', false);
				if (data1.result) {
					message.success(<span className="errorMsg">'Edited successfully'</span>)
				} else {
					message.error(<span className="errorMsg">"Duplicate entry"</span>)
				}
			})
			.fail(function (jqXhr) {
				if (!didfail && jqXhr.status == 401) {
					GetToken();
					comp.updateUser(inputBean, true);
				}
				else
					$("#edit4").prop('disabled', false);
			});
	}
	render() {
		return (<div>
			<NavBar1 name={this.name} group={this.group} />
			<br />
			<div className="class1">
				<div className="row"><h1 className="headings tab">Update Broker details</h1></div>
				<br />
				<div className="row" style={{ fontSize: "15px" }}>
					<form className="form-horizontal col-md-7 col-sm-11 editForm" onSubmit={this.editRows4}>
						<div className="col-md-offset-3 col-md-7"><h5 style={{ color: "red" }} >Note: All fields are mandatory</h5></div>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Code: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" value={this.state.data.brokerCode} name="brokerCode" pattern="[A-Z]{2}" onChange={this.setEmpState4} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Number: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="brokerNumber" value={this.state.data.brokerNumber} pattern="[0-9]{6}" onChange={this.setEmpState4} title="Please enter a 6 digit number" required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="brokerName" value={this.state.data.brokerName} onChange={this.setEmpState4} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Contact Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="contactPersonName" value={this.state.data.contactPersonName} onChange={this.setEmpState4} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Email: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="email" value={this.state.data.mailId} name="mailId" onChange={this.setEmpState4} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>User Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="userName" value={this.state.data.userName} onChange={this.setEmpState4} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Contact No: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="phoneNumber" value={this.state.data.phoneNumber} maxlength="10" pattern="\d{10}" maxLength="10" onChange={this.setEmpState4} title="Please enter a 10 digit phone number" required />
							</div>
						</FormGroup>
						<div className="col-md-offset-3 col-md-4">
							<button type="submit" className=" btn btn-primary btn-lg" id="edit4"> Update </button>
						</div>
					</form>
					<div className="col-md-3">
						<img src="/static/media/handshake.c4db6082.png" style={{ paddingTop: "10%" }} className="handshake" alt="hi" />
					</div>
				</div>

			</div>
		</div>)
	}


}
export default withAuth(EditScreen);