import * as FileSaver from 'file-saver';
import React from 'react';
import * as XLSX from 'xlsx';

export const ExportCSVButton = ({ csvData, fileName, showDropdownVal }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        //Modifying the headers in the excel spreadsheet
        ws.A1.v = 'Broker Name';
        ws.B1.v = 'Broker Number';
        ws.C1.v = 'Username';
        ws.D1.v = 'OPCO Id';
        ws.E1.v = 'OPCO Name';
        ws.F1.v = 'Name';
        ws.G1.v = 'Title';
        ws.H1.v = 'Phone';
        ws.I1.v = 'Email';
        ws.J1.v = 'Fax';
        ws.K1.v = 'Address Line 1';
        ws.L1.v = 'Address Line 2';
        ws.M1.v = 'Address Line 3';
        ws.N1.v = 'Address Line 4';
        ws.O1.v = 'City';
        ws.P1.v = 'State';
        ws.Q1.v = 'Zip';
        ws.R1.v = 'Country';


        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const exportToCSVuSER = (csvData, fileName) => {
        const ws1 = XLSX.utils.json_to_sheet(csvData);
        //Modifying the headers in the excel spreadsheet
        ws1.A1.v = 'OPCO Id';
        ws1.B1.v = 'OPCO Name';
        ws1.C1.v = 'Name';
        ws1.D1.v = 'Title';
        ws1.E1.v = 'Phone';
        ws1.F1.v = 'Email';
        ws1.G1.v = 'Fax';
        ws1.H1.v = 'Address Line 1';
        ws1.I1.v = 'Address Line 2';
        ws1.J1.v = 'Address Line 3';
        ws1.K1.v = 'Address Line 4';
        ws1.L1.v = 'City';
        ws1.M1.v = 'State';
        ws1.N1.v = 'Zip';
        ws1.O1.v = 'Country';


        const wb = { Sheets: { 'data': ws1 }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <button className=' btn btn-primary btn-lg searching' onClick={(e) => (showDropdownVal ? exportToCSV(csvData, fileName) : exportToCSVuSER(csvData, fileName))}>Download Report</button>
    )
}

export default ExportCSVButton;