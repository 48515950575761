import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';

var comp;
var rowKeys = [];

const adminURL = 'viewAdmin';



class ViewAdmin extends React.Component {
	constructor(props) {

		super(props);

		this.state = { data: [] };
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.viewAdmin = this.viewAdmin.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {
		$("#loaderAdmin").show();
		comp = this;
		comp.viewAdmin(false);
	}

	viewAdmin(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + adminURL,
			'contentType': 'application/json',
			'dataType': 'json',
		}).done(function (data) {
			$("#loaderAdmin").hide();
			for (var el in data.rows) {
				comp.state.data.push({
					"contactPersonName": data.rows[el].contactPersonName,
					"mailId": data.rows[el].mailId,
					"phoneNumber": data.rows[el].phoneNumber,
					"addedBy": data.rows[el].addedBy,
					"userGroup": data.rows[el].userGroup,
					"userName": data.rows[el].userName,
					"userId": data.rows[el].userId,
				})
				comp.setState({ data: comp.state.data });
			}
		}).fail(function (jqXhr) {
			if (!didFail && jqXhr.status == 401) {
				GetToken();
				comp.viewAdmin(true);
			}
			$("#loaderAdmin").hide();
		});
	}


	render() {

		const options2 = {
			onRowClick: function (currentRow) {
				if (comp.group == "admin") {
					comp.props.history.push({
						pathname: '/suite/vbt/editAdmin',
						state: {
							data: comp.name,
							group: comp.group,
							row: currentRow
						}
					})
				}
			},
			noDataText: ' ',
			sizePerPage: 6
		};


		return (
			<div className="container-fluid">

				<NavBar1 name={this.name} group={this.group} />
				<br />
				<h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Admin Details</h1>
				<br />
				<div style={{ overflowX: "auto" }}>
					<BootstrapTable ref='table' data={this.state.data} pagination options={options2} search={true} >
						<TableHeaderColumn dataField='contactPersonName' width="100" dataSort={true}  >Admin Name
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='mailId' isKey={true} width="150" dataSort={true}  >Email Id
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='phoneNumber' width="150" dataSort={true}  >Contact Number
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='userName' width="100" dataSort={true}  >User Name
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='userGroup' width="100" dataSort={true} hidden>Group
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='addedBy' width="100" dataSort={true} hidden >Added By
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='userId' width="100" dataSort={true} hidden >User id
                                        </TableHeaderColumn>
					</BootstrapTable>
					<div id="loaderAdmin" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>

				</div>



			</div>

		)
	}



}


export default withAuth(ViewAdmin);