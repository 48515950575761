import { message } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import sysco2 from './handshake.png';
import NavBar1 from './navbar.jsx';

const updateURL = 'updateBrokerList';

class EditBasicBrokerData extends React.Component {
	constructor(props) {
		super(props);
		console.log(this.props.history.location)
		this.state = {

			data: {

				brokerCode: this.props.history.location.state.row.brokerCode,
				brokerName: this.props.history.location.state.row.brokerName,
				brokerNumber: this.props.history.location.state.row.brokerNumber,
				id: this.props.history.location.state.row.id
			}
		};
		this.setEmpState1 = this.setEmpState1.bind(this);
		this.editRows1 = this.editRows1.bind(this);
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.updateBrokerList = this.updateBrokerList.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	setEmpState1(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		this.setState({ data: this.state.data });
	}

	editRows1(e2) {
		e2.preventDefault();
		$("#edit1").prop('disabled', true);
		var comp = this;
		var paramm = {
			// contactPersonName: comp.state.data.contactPersonName,
			// brokerType: comp.state.data.brokerType,
			brokerName: comp.state.data.brokerName,
			brokerCode: comp.state.data.brokerCode,
			brokerNumber: comp.state.data.brokerNumber,
			id: comp.state.data.id
			// mailId: comp.state.data.mailId,
			// userName: comp.state.data.userName,
			// phoneNumber: comp.state.data.phoneNumber,
			// addedBy: comp.state.data.addedBy,
			// doneBy: comp.name,
			// userGroup: comp.state.data.userGroup,
			// userId: comp.state.data.userId,
		}

		this.updateBrokerList(paramm, false);
	}

	updateBrokerList(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + updateURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data) {
				$("#edit1").prop('disabled', false);
				if (data.result) {
					message.success(<span className="errorMsg">'Edited successfully'</span>)
					comp.props.history.push({
						pathname: '/suite/vbt/admin',
						state: {
							code: comp.state.data.brokerCode,
							data: comp.name,
							group: comp.group,
							editBasicData: true
						}
					})
				} else {
					message.error(<span className="errorMsg">"Duplicate entry"</span>)
				}
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.updateBrokerList(param, true);
				}
				else
					$("#edit1").prop('disabled', false);
			});
	}

	render() {
		return (<div>
			<NavBar1 name={this.name} group={this.group} />
			<br />
			<div className="class1">
				<div className="row"><h1 className="headings tab">Update Broker details</h1></div>
				<br />
				<div className="row" style={{ fontSize: "15px" }}>
					<form className="form-horizontal col-md-7 col-sm-11 editForm" onSubmit={this.editRows1}>
						<div className="col-md-offset-3 col-md-7"><h5 style={{ color: "red" }} >Note: All fields are mandatory</h5></div>

						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Code: </label></div>
							<div className="col-md-7">
								<input disabled className="form-control" type="text" name="brokerCode" value={this.state.data.brokerCode} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="brokerName" value={this.state.data.brokerName} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Number: </label></div>
							<div className="col-md-7">
								<input disabled className="form-control" type="text" name="brokerNumber" value={this.state.data.brokerNumber} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>

						<div className="col-md-offset-3 col-md-4">
							<button type="submit" className=" btn btn-primary btn-lg" id="edit1"> Update </button>
						</div>
					</form>
					<div className="col-md-3">
						<img src="/static/media/handshake.c4db6082.png" style={{ paddingTop: "10%" }} className="handshake" alt="hi" />
					</div>
				</div>
			</div>
		</div>)
	}
}
export default withAuth(EditBasicBrokerData);