export function checkLinkAvailable(status){
    return status=="In progress"
}

export function isLinkDisabled(disableLink){
    return disableLink ? "#808080" : "#40a9ff"
}

export function selectCursor(disableLink){
    return disableLink ?  "not-allowed" : "pointer"
}
