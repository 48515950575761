import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';

var comp;
var rowKeys = [];

const adminURL = 'viewAdmin';
const CorpBrokerList = "getBrokerNumbersOfCompany";
const BrokerList = "viewBrokerList";
const deleteURL = "deleteCompany";

class ViewBrokerScreenC extends React.Component {
    constructor(props) {

        super(props);
        this.state = { data: [], visible: false };
        this.name = this.props.history.location.state.data;
        this.group = this.props.history.location.state.group;
        this.code = this.props.history.location.state.code;
        this.editBasicData = this.props.history.location.state.editBasicData
        this.viewBrokers = this.viewBrokers.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
    }

    componentDidMount() {
        initGA();
        PageView();
    }

    componentWillMount() {
        $("#loaderAdmin").show();
        comp = this;
        console.log(this.props.history.location.state.group)
        var param1 = {
            "brokerCode": this.code,
        }
        var param2 = {
            "brokerCode": this.code,
            "brokerNumber": this.props.history.location.state.brokerNumber,
            "branch": this.props.history.location.state.branch
        }
        comp.viewBrokers(param1, param2, false);
    }

    viewBrokers(param1, param2, didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            beforeSend: function () {
                $("#loader").show();
            },
            'type': 'POST',
            'url': this.editBasicData ? URL.url + BrokerList : URL.url + CorpBrokerList,
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(this.editBasicData ? param1 : param2),
            complete: function (data) {
                $("#loader").hide();
            }
        }).done(function (data) {
            $("#loaderAdmin").hide();
            for (var el in data.rows) {
                comp.state.data.push({
                    "contactPersonName": data.rows[el].contactPersonName,
                    "userName": data.rows[el].userName,
                    "userId": data.rows[el].userId,
                    "addedBy": data.rows[el].addedBy,
                    "mailId": data.rows[el].mailId,
                    "phoneNumber": data.rows[el].phoneNumber,
                    "userGroup": data.rows[el].userGroup,
                    "brokerName": data.rows[el].brokerName,
                    "id": data.rows[el].id,
                    "brokerCode": data.rows[el].brokerCode,
                    "brokerNumber": data.rows[el].brokerNumber,
                    "brokerType": data.rows[el].brokerType,

                })
                comp.setState({ data: comp.state.data });
            }

        }).fail(function (jqXhr) {
            if (!didFail && jqXhr.status == 401) {
                GetToken();
                comp.viewBrokers(param1, param2, true);
            }
        });
    }
    createCustomDeleteButton1 = (onClick) => {
        return (
            <Button type="button" className="btn btn-lg buttonDelete" id="delete" style={{ marginRight: "14px", color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '14px', marginTop: "10px", marginBottom: "10px" }} onClick={() => this.handleDeleteButtonClick(onClick)}>
                <span><Glyphicon glyph="trash" /><span style={{ fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }}> Delete </span></span>
            </Button>
        );
    }
    handleOk = e => {
        this.setState({
            visible: false,
        });
        var comp = this;
        var brokerNumber = "";

        $("#delete").prop('disabled', true);
        if (rowKeys.length > 1) {
            brokerNumber = rowKeys.join(",");
        } else {
            brokerNumber = rowKeys[0];
        }

        var param = {
            "doneBy": comp.name,
            "brokerNumber": brokerNumber
        };

        comp.deleteCompany(param, false)
    };

    deleteCompany(param, didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,

            'type': 'POST',
            'url': URL.url + deleteURL,
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),

        }).done(function (successData) {
            $("#delete").prop('disabled', false);
            rowKeys = [];
            window.location.reload(true);
            message.info(<span className="errorMsg">{successData.message}</span>);
        }).fail(function (failMessage) {
            if (!didFail && failMessage.status == 401) {
                GetToken();
                comp.deleteCompany(param, true);
            }
            else {
                message.error(<span className="errorMsg">{failMessage.message}</span>);
                $("#delete").prop('disabled', false);
            }
        });
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    handleDeleteButtonClick = (onClick) => {
        this.setState({ visible: true })
        // message.error(<span className="errorMsg">("Brokers already tied to one or more items will not be deleted");
    }

    render() {
        const selectRow = {
            mode: 'checkbox',//radio or checkbox
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    rowKeys.push(row.brokerNumber);
                } else {
                    rowKeys.splice(rowKeys.indexOf(row.brokerNumber), 1);
                }
            },

            // hideSelectColumn: flag,

        };

        const options2 = {
            deleteBtn: this.createCustomDeleteButton1,
            noDataText: ' ',
            onRowClick: function (currentRow) {
                if (comp.group == "admin") {
                    comp.props.history.push({
                        pathname: '/suite/vbt/editBaseData',
                        state: {
                            data: comp.name,
                            group: comp.group,
                            id: comp.state.data.id,
                            row: currentRow,
                        }
                    })
                }
            },
            sizePerPage: 6
        };

        return (
            <div className="container-fluid">

                <NavBar1 name={this.name} group={this.group} />
                <br />
                <h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Broker Details</h1>
                <br />
                <div style={{ overflowX: "auto" }}>
                    <div>
                        <Modal
                            title="Do you want to delete this account?"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                        // footer={[
                        //     <Button onClick={this.handleCancel}>
                        //       Cancel
                        //     </Button>,
                        //     <Button  onClick={this.handleOk}>
                        //       Delete
                        //     </Button>,
                        //   ]}
                        >
                            <p>You cannot undo this action!</p>
                        </Modal></div>
                    <BootstrapTable ref='table' className={"selectAll"} data={this.state.data} pagination options={options2} deleteRow selectRow={selectRow} search={true} >
                        {/* <TableHeaderColumn dataField='contactPersonName' width="100" dataSort={ true }  >Admin Name
                                        </TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='mailId' isKey={ true } width="150" dataSort={ true }  >Email Id
                                        </TableHeaderColumn> */}
                        <TableHeaderColumn dataField='brokerCode' width="150" dataSort={true}  >Broker Code
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='brokerNumber' isKey={true} width="150" dataSort={true}  >Broker Number
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='brokerName' width="100" dataSort={true} >Broker Name
                                        </TableHeaderColumn>
                        {/* <TableHeaderColumn dataField='phoneNumber' width="150" dataSort={ true }  >Contact Number
                                        </TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='userName' width="100" dataSort={ true }  >User Name
                                        </TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='userGroup' width="100" dataSort={ true } hidden>Group
                                        </TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='addedBy' width="100" dataSort={ true } >Added By
                                        </TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='userId' width="100" dataSort={ true } >User id
                                        </TableHeaderColumn> */}
                    </BootstrapTable>
                    <div id="loaderAdmin" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>

                </div>
            </div>

        )
    }

}

export default withAuth(ViewBrokerScreenC);