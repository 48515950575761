import React from 'react';
import NavBar1 from '../admin welcome/navbar.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import Unassigned from './unassigned_report.jsx';

class VendorBroker extends React.Component {
	constructor(props) {
		super(props);
		console.log(props)
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.state = { broker: { brokerCode: '', brokerNumber: '', brokerName: '', mailId: '' } }
		this.currentUser = this.props.history.location.state.currentUser;
		this.brokerType = this.props.history.location.state.brokerType;
	}

	componentWillMount() {
		if (this.group == "user") {
			this.state.broker.brokerCode = this.props.history.location.state.brokerCode;
			this.state.broker.brokerName = this.props.history.location.state.brokerName;
			this.state.broker.brokerNumber = this.props.history.location.state.brokerNumber;
			this.state.broker.mailId = this.props.history.location.state.mailId;
			this.setState({ broker: this.state.broker })
		}
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	render() {
		return <div className="container-fluid">
			<NavBar1 currentUser={this.currentUser} name={this.name} group={this.group} brokerNumber={this.state.broker.brokerNumber} brokerCode={this.state.broker.brokerCode} brokerName={this.state.broker.brokerName} mailId={this.state.broker.mailId} /> <br />
			<h1 className="headings" style={{ textAlign: "left" }}>Unassigned and New Item Report</h1>
			<div> <Unassigned brokerType={this.brokerType} currentUser={this.currentUser} name={this.name} group={this.group} brokerNumber={this.state.broker.brokerNumber} brokerCode={this.state.broker.brokerCode} brokerName={this.state.broker.brokerName} mailId={this.state.broker.mailId} /> </div>
		</div>
	}
}
export default withAuth(VendorBroker);
