import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

const unassignURL = 'getUnassignedItemReport';
const insertStatusURL = 'insertUnassignedReportStatus';

class Table1 extends React.Component {

	constructor(props) {
		super(props);
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.currentUser = this.props.currentUser;
		this.brokerType = this.props.brokerType;
		this.state = {
			broker: {
				brokerCode: '',
				brokerNumber: '',
				brokerName: '',
				mailId: ''
			},
			products: [],
			counter: 1
		};
		this.viewStatus1 = this.viewStatus1.bind(this);
		this.getUnassignedItemReport = this.getUnassignedItemReport.bind(this);
		this.insertUnassignedReportStatus = this.insertUnassignedReportStatus.bind(this);
	}

	componentDidMount() {
		if (this.group === "user") {
			let newBroker = {
				brokerNumber: this.props.brokerNumber,
				brokerCode: this.props.brokerCode,
				brokerName: this.props.brokerName,
				mailId: this.props.mailId
			}
			this.setState({ broker: newBroker })
		}
		this.fetchReport();
	}

	fetchReport = () => {
		var comp = this;
		$("#loader4").show();
		comp.setState({ products: [] });
		console.log("val", $('.searchBar').val());
		var dataInput = {
			"pageNumber": this.state.counter,
			"searchFunctionality": $('.searchBar').val() ? "yes" : "no",
			"searchValue": $('.searchBar').val()
		}
		comp.getUnassignedItemReport(dataInput, false);
	}

	getUnassignedItemReport(dataInput, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + unassignURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(dataInput)
		})
			.done(function (dataa) {
				$("#loader4").hide();
				for (var el in dataa.rows) {
					comp.state.products.push({
						"vendorNumber": dataa.rows[el].vendorNumber,
						"vendorName": dataa.rows[el].vendorName,
						"itemId": dataa.rows[el].itemId,
						"itemDescr": dataa.rows[el].itemDescr,
						"opcoId": dataa.rows[el].opcoId,
						"opcoName": dataa.rows[el].opcoName,
						"market": dataa.rows[el].market,
						"brand": dataa.rows[el].brand,
						"category": dataa.rows[el].category,
						"major": dataa.rows[el].major,
						"intermediate": dataa.rows[el].intermediate,
						"minor": dataa.rows[el].minor,

					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getUnassignedItemReport(dataInput, true);
				}
			});
	}

	incrementCounter = () => {
		var counter = this.state.counter;
		counter += 1;
		console.log("local counter", counter);
		this.setState({ counter }, () => {
			console.log("value", $('.search').val());
			console.log("counter", this.state.counter);
			this.fetchReport();
		})
	}

	decrementCounter = () => {
		var counter = this.state.counter;
		counter -= 1;
		this.setState({ counter }, () => {
			console.log("value", $('.search').val());
			console.log("counter", this.state.counter);
			this.fetchReport();
		})
	}

	search = () => {
		this.setState({ counter: 1 }, () => { this.fetchReport(); });

	}

	downloadReport = () => {
		var comp = this;
		var data = {
			"userName": comp.currentUser,
			"userGroup": comp.group
		}
		comp.insertUnassignedReportStatus(data, false);
		if (window.confirm("Your Report will be available for download within few moments")) {
			this.viewStatus1();
		}
	}

	insertUnassignedReportStatus(userDetails, didFail) {
		var comp = this;
		const data = {
			...userDetails,			
			userName: localStorage.getItem("userId")
		}
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + insertStatusURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(data),
		})
			.done(function (data) {
				//console.log("downloaded")
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.insertUnassignedReportStatus(data, true);
				}
				else
					$("#edit").prop('disabled', false);
			});
	}

	viewStatus1 = () => {
		this.props.history.push({ pathname: '/suite/vbt/view_download_status', state: { data: this.name, group: this.group, mailId: this.state.broker.mailId, brokerNumber: this.state.broker.brokerNumber, brokerCode: this.state.broker.brokerCode, brokerName: this.state.broker.brokerName, currentUser: this.currentUser, brokerType: this.brokerType } })
	}

	testState = () => {
		console.log(this.state)
	}

	render() {
		const options = {
			sizePerPage: 14,
			noDataText: ' '
		};
		return (
			<div >
				<div className="row">
					<div className="btn-group col-md-4" role="group" ariaLabel="First group">
						<button type="button" className="btn btn-primary" onClick={this.downloadReport}>Download Report</button>
						<button type="button" className="btn btn-primary" onClick={this.viewStatus1}>View download status</button>
					</div>
					<div className="searchGroup col-md-4 col-md-offset-4" style={{ padding: "0px 15px !important", float: 'right', display: 'flex' }}>
						<input type="text" className="form-control searchBar" style={{ width: '93%' }} placeholder="Search" ariaLabel="Search" ariaDescribedBy="button-addon2" />
						<div className="input-group-append" style={{ position: 'sticky' }}>
							<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.search.bind(this)}><span className="glyphicon glyphicon-search" aria-hidden="true"></span></button>
						</div>
					</div>
				</div>
				<BootstrapTable ref='table' data={this.state.products} options={options} striped hover condensed>
					<TableHeaderColumn width="100" ref='vendorNumber' dataField='vendorNumber' isKey={true} dataSort={true} >Vendor No</TableHeaderColumn>
					<TableHeaderColumn width="150" ref='vendorName' dataField='vendorName' dataSort={true}>Vendor Name</TableHeaderColumn>
					<TableHeaderColumn width="100" ref='market' dataField='market' dataSort={true}>Market</TableHeaderColumn>
					<TableHeaderColumn width="80" ref='opcoId' dataField='opcoId' dataSort={true}>OpCo Id</TableHeaderColumn>
					<TableHeaderColumn width="100" ref='opcoName' dataField='opcoName' dataSort={true}>OpCo Name</TableHeaderColumn>
					<TableHeaderColumn width="50" ref='category' dataField='category' dataSort={true}>Cat</TableHeaderColumn>
					<TableHeaderColumn width="50" ref='major' dataField='major' dataSort={true}>Maj</TableHeaderColumn>
					<TableHeaderColumn width="50" ref='intermediate' dataField='intermediate' dataSort={true}>Int</TableHeaderColumn>
					<TableHeaderColumn width="50" ref='minor' dataField='minor' dataSort={true}>Min</TableHeaderColumn>
					<TableHeaderColumn width="100" ref='brand' dataField='brand' dataSort={true}>Brand</TableHeaderColumn>
					<TableHeaderColumn width="100" ref='itemId' dataField='itemId' dataSort={true}>Item Id</TableHeaderColumn>
					<TableHeaderColumn width="200" dataField='itemDescr' dataSort={true} >Item description</TableHeaderColumn>
				</BootstrapTable>
				<div style={{ textAlign: "center" }}><img src={img1} id="loader4" style={{ height: "2em", width: "2em" }} /></div>
				<button className="btn btn-primary" onClick={this.decrementCounter} disabled={this.state.counter === 1 ? true : false}>Previous</button>
				<button className="btn btn-primary" onClick={this.incrementCounter}>Next</button>
			</div>
		);
	}
}

export default withRouter(Table1);
