import { message, Modal } from 'antd';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
//import AuthService from '../login/AuthService';
import withAuth from '../login/withAuth';
//import ReactDOM from 'react-dom';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';

var comp;
var rowKeys = [];
var rowKey2 = [];
const deleteURL = 'deleteUser';
const adminURL = 'viewAdmin';
const adminBrokerList = "viewBrokers";


class ViewBroker extends React.Component {
    constructor(props) {

        super(props);

        this.state = { data: [], visible: false };
        this.name = this.props.history.location.state.data;
        this.group = this.props.history.location.state.group;
        this.viewBrokers = this.viewBrokers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {
        initGA();
        PageView();
    }

    componentWillMount() {
        $("#loaderAdmin").show();
        comp = this;
        console.log(this.props.history.location.state.group)
        comp.viewBrokers(false);
    }

    viewBrokers(didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            beforeSend: function () {
                $("#loader").show();
            },
            'type': 'POST',
            'url': URL.url + adminBrokerList,
            'dataType': 'json',
            'contentType': 'application/json',
            complete: function (data) {
                $("#loader").hide();
            }
        }).done(function (data) {
            $("#loaderAdmin").hide();

            for (var el in data.rows) {
                comp.state.data.push({

                    "mailId": data.rows[el].mailId,
                    "phoneNumber": data.rows[el].phoneNumber,
                    "userName": data.rows[el].userName,
                    "userGroup": data.rows[el].userGroup,
                    "brokerType": data.rows[el].brokerType,
                    "contactPersonName": data.rows[el].contactPersonName,
                    "userId": data.rows[el].userId,
                    "brokerCode": data.rows[el].brokerCode,
                    "addedBy": data.rows[el].addedBy,
                    "brokerNumber": data.rows[el].brokerNumber,

                })
                comp.setState({ data: comp.state.data });
            }

        }).fail(function (jqXhr) {
            if (!didFail && jqXhr.status == 401) {
                GetToken();
                comp.viewBrokers();
            }
        });
    }
    createCustomDeleteButton = (onClick) => {
        return (
            <Button type="button" className="btn buttonDelete btn-lg " id="delete" style={{ marginRight: "14px", backgroundColor: '#0082cb', color: '#fff', borderColor: '#0082cb', fontSize: '14px', marginTop: "10px", marginBottom: "10px" }} onClick={() => this.handleDeleteButtonClick(onClick)}>
                <span><Glyphicon glyph="trash" /><span style={{ fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Microsoft YaHei,Helvetica Neue,Hiragino Sans GB,Helvetica,Arial,sans-serif' }}> Delete </span></span>
            </Button>
        );
    }

    handleOk1 = e => {
        this.setState({
            visible: false,
        });
        var comp = this;
        var userName = "";

        $("#delete").prop('disabled', true);
        if (rowKeys.length > 1) {
            userName = rowKeys.join(",");
        } else {
            userName = rowKeys[0];
        }

        var param1 = {
            "doneBy": comp.name,
            "userName": userName
        };
        console.log(param1)
        comp.deleteUser(param1, false);
    };

    deleteUser(param, didFail) {
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,

            'type': 'POST',
            'url': URL.url + deleteURL,
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),

        }).done(function (data) {
            $("#delete").prop('disabled', false);
            rowKeys = [];
            window.location.reload(true);
            message.info(<span className="errorMsg">{data.message}</span>);
        }).fail(function (failMsg) {
            if (!didFail && failMsg.status == 401) {
                GetToken();
                comp.deleteUser(param, true);
            }
            else {
                message.error(<span className="errorMsg">{failMsg.message}</span>);
                $("#delete").prop('disabled', false);
            }
        });
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleDeleteButtonClick = (onClick) => {
        // message.error(<span className="errorMsg">("Brokers already tied to one or more items will not be deleted");
        this.setState({ visible: true })
    }
    render() {

        const selectRow = {
            mode: 'checkbox',//radio or checkbox
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    rowKeys.push(row.userName);
                } else {
                    rowKeys.splice(rowKeys.indexOf(row.userName), 1);
                    console.log(rowKeys)

                }
            },

            // hideSelectColumn: flag,

        };

        const options2 = {
            deleteBtn: this.createCustomDeleteButton,
            noDataText: ' ',
            onRowClick: function (currentRow) {
                if (comp.group == "admin") {
                    comp.props.history.push({
                        pathname: '/suite/vbt/editBroker',
                        state: {
                            data: comp.name,
                            group: comp.group,
                            row: currentRow
                        }
                    })
                }
            },
            sizePerPage: 6
        };

        return (
            <div className="container-fluid">

                <NavBar1 name={this.name} group={this.group} />
                <br />
                <h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Broker User Details</h1>
                <br />
                <div style={{ overflowX: "auto" }}>
                    <div>
                        <Modal
                            title="Do you want to delete this account?"
                            visible={this.state.visible}
                            onOk={this.handleOk1}
                            onCancel={this.handleCancel}
                        // footer={[
                        //     <Button onClick={this.handleCancel}>
                        //       Cancel
                        //     </Button>,
                        //     <Button  onClick={this.handleOk1}>
                        //       Delete
                        //     </Button>,
                        //   ]}
                        >
                            <p>You cannot undo this action!</p>
                        </Modal></div>
                    <BootstrapTable ref='table' className={"selectAll"} data={this.state.data} pagination deleteRow selectRow={selectRow} search={true} options={options2}>
                        <TableHeaderColumn dataField='contactPersonName' width="150" dataSort={true}  >Contact Person Name
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='mailId' isKey={true} width="150" dataSort={true}  >Email Id
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='brokerCode' width="120" dataSort={true}  >Broker Code
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='brokerNumber' width="150" dataSort={true}  >Broker Number
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='brokerType' width="100" dataSort={true} >Broker Type
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='phoneNumber' width="150" dataSort={true}  >Contact Number
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='userName' width="100" dataSort={true}  >User Name
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='userGroup' width="100" dataSort={true} hidden>Group
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='addedBy' width="100" dataSort={true} >Added By
                                        </TableHeaderColumn>
                        {/* <TableHeaderColumn dataField='userId' width="100" dataSort={ true } >User id
                                        </TableHeaderColumn> */}
                    </BootstrapTable>
                    <div id="loaderAdmin" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>

                </div>
            </div>

        )
    }

}

export default withAuth(ViewBroker);