import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

const auditbrokerPOC = "fetchPOCAudits";
const auditAllPOC = "fetchallPOCAudits"
class BrokerPOCAudit extends React.Component {

	constructor(props) {
		super(props)
		this.state = { data: [], brokerData: { brokerName: '', brokerCode: '', brokerNumber: '', mailId: '' }, vendor: { vendorNumber: '' }, opco: { opcoId: '' } };

		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.brokerNumber = this.props.history.location.state.brokerNumber;
		this.currentUser = this.props.history.location.state.currentUser;
		this.fetchAllPOCAudit = this.fetchAllPOCAudit.bind(this);
		this.fetchPOCAudit = this.fetchPOCAudit.bind(this);
		this.setData = this.setData.bind(this);
	}

	componentWillMount() {
		var comp = this;
		if (this.group === "user") {
			console.log("data from props is", this.props.history.location)
			this.state.brokerData.brokerNumber = this.props.history.location.state.brokerNumber;
			this.state.brokerData.brokerCode = this.props.history.location.state.brokerCode;
			this.state.brokerData.brokerName = this.props.history.location.state.brokerName;
			this.state.brokerData.mailId = this.props.history.location.state.mailId;
			this.setState({ brokerData: this.state.brokerData }, () => console.log("broker data is", this.state.brokerData))
			//var userName=this.props.history.location.state.brokerNumber
			var userName = this.currentUser

			console.log("broker Number is", userName)

			$("#loaderBrokerpocTies").show();
			comp.fetchPOCAudit(userName, false);
		}
		if (this.group === "admin") {
			console.log("brokers are", this.props.history)
			$("#loaderBrokerpocTies").show();
			comp.fetchAllPOCAudit(false);
		}
	}

	fetchPOCAudit(userName, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + auditbrokerPOC,
			'contentType': 'application/json',
			'data': userName,
			'dataType': 'json',
		})
			.done(function (data) {
				comp.setData(data)
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.fetchPOCAudit(userName, true);
				}
				else
					$("#loaderBrokerpocTies").hide();
			});
	}

	fetchAllPOCAudit(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + auditAllPOC,
			'contentType': 'application/json',
			'dataType': 'json',
		})
			.done(function (data) {
				comp.setData(data);
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.fetchAllPOCAudit(true);
				}
				else
					$("#loaderBrokerpocTies").hide();
			});
	}

	setData(data) {
		var comp1 = this;
		$("#loaderBrokerpocTies").hide();
		for (var el in data.rows) {
			comp1.state.data.push({
				"opcoid": data.rows[el].opcoid,
				"brokerNumber": data.rows[el].brokerNumber,
				"pocName": data.rows[el].pocName,
				"title": data.rows[el].title,
				"phone": data.rows[el].phone,
				"email": data.rows[el].email,
				"addressLine1": data.rows[el].addressLine1,
				"addressLine2": data.rows[el].addressLine2,
				"addressLine3": data.rows[el].addressLine3,
				"addressLine4": data.rows[el].addressLine4,
				"fax": data.rows[el].fax,
				"city": data.rows[el].city,
				"state": data.rows[el].state,
				"zip": data.rows[el].zip,
				"country": data.rows[el].country,
				"processFlag": data.rows[el].processFlag,
				"createdUser": data.rows[el].createdUser,
				"createdDate": data.rows[el].createdDate,
			});
		}
		comp1.setState({ data: comp1.state.data });
	}

	componentDidMount() {
		initGA();
		PageView();

		$("#loader").hide();
	}


	render() {
		const options2 = {
			sizePerPage: 14,
			noDataText: ' '
		}

		return <div>
			<NavBar1 currentUser={this.currentUser} name={this.name} group={this.group} brokerNumber={this.state.brokerData.brokerNumber} brokerCode={this.state.brokerData.brokerCode} brokerName={this.state.brokerData.brokerName} mailId={this.state.brokerData.mailId} vendorNumber={this.state.vendor.vendorNumber} opcoId={this.state.opco.opcoId} /> <br />
			<br />
			<div className="row tab">
				<h1 className="headings" style={{ textAlign: "center", position: "fixed", paddingLeft: '380px' }}>Broker-POC Audits</h1><br /><br />
			</div>
			<div className="au-lo">
				<div className="col-xs-6 col-sm-6 col-md-6 col-lg-12" style={{ width: "2000px", overflowX: 'scroll' }}>
					<BootstrapTable ref='table' data={this.state.data} options={options2} pagination style={{ width: "8px" }} search={true} striped hover condensed>
						<TableHeaderColumn width="150" ref='brokerNumber' dataField='brokerNumber' isKey={true} dataSort={true} >Broker Number</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='opcoid' dataField='opcoid' dataSort={true}  >Opco Id</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='pocName' dataField='pocName' dataSort={true}  >Name</TableHeaderColumn>
						<TableHeaderColumn width="80" ref='title' dataField='title' dataSort={true} >Title</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='addressLine1' dataField='addressLine1' dataSort={true}>Address Line 1</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='addressLine2' dataField='addressLine2' dataSort={true} >Address Line 2</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='addressLine3' dataField='addressLine3' dataSort={true}>Address Line 3</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='addressLine4' dataField='addressLine4' dataSort={true} >Address Line 4</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='phone' dataField='phone' dataSort={true} >Phone</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='fax' dataField='fax' dataSort={true} >Fax</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='city' dataField='city' dataSort={true} >City</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='state' dataField='state' dataSort={true} >State</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='zip' dataField='zip' dataSort={true} >Zip</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='country' dataField='country' dataSort={true} >Country</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='email' dataField='email' dataSort={true} >Email</TableHeaderColumn>
						<TableHeaderColumn width="100" ref='processFlag' dataField='processFlag' dataSort={true} >Audit Type</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='createdUser' dataField='createdUser' dataSort={true} >Done By</TableHeaderColumn>
						<TableHeaderColumn width="150" ref='createdDate' dataField='createdDate' dataSort={true} >Audit Date</TableHeaderColumn>
					</BootstrapTable>
					<div id="loaderBrokerpocTies" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		</div>
	}
}
export default withAuth(BrokerPOCAudit);
