import { Input } from 'antd';
import 'antd/dist/antd.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import URL from '../url.js';
import img1 from './loading.gif';

const Search = Input.Search;
const catUrl = 'getCategory';
var rowKeys = [];
var comp;

export default class Cat extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [], row: { selectedRowKeys: [] }// Check here to configure the default column
		};
		comp = this;
		this.onClick = this.onClick.bind(this);
		this.getCategory = this.getCategory.bind(this);
		this.setCategory = this.setCategory.bind(this);
	}
	onClick(value) {
		var comp = this;
		let param = {
			"categoryDescr": value
		}
		comp.getCategory(param, false);
	}

	getCategory(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				//
			},
			crossDomain: true,
			beforeSend: function () {
				// Show image container
				$("#loader4").show();
			},
			'type': 'POST',
			'url': URL.url + catUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				// Hide image container
				$("#loader4").hide();
			}
		})
			.done(function (data) {
				comp.state.items = [];
				comp.setState({ items: comp.state.items })
				for (var el in data.rows) {
					comp.state.items.push({

						category: data.rows[el].category,
						catDescr: data.rows[el].categoryDesc
					})
				}
				comp.setState({ items: comp.state.items })
			})

			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getCategory(param, true);
				}
			});
	}
	componentDidMount() {
		$("#loader4").hide();
	}

	setCategory(value) {
		if (this.props?.setProperty) {
			this.props.setProperty('category', value);
		}
	}

	render() {
		const selectRow = {
			mode: 'checkbox',//radio or checkbox
			onSelect: function (row, isSelected) {
				if (isSelected) {
					rowKeys.push(row.category);
				} else {
					rowKeys.splice(rowKeys.indexOf(row.category), 1);
				}
				var string = rowKeys.join(",");
				comp.setCategory(string);
			},
			onSelectAll: function (isSelected, rows) {
				rowKeys = [];
				if (isSelected) {
					for (var el in comp.state.items) {
						rowKeys.push(comp.state.items[el].category);
					}
					var string = rowKeys.join(",");
					comp.setCategory(string);
					return comp.state.items.map(row => row.category);
				}
				else {
					rowKeys = [];
					var string1 = rowKeys.join(",");
					comp.setCategory(string1);
					return [];
				}
			}
		};

		const options = {
			sizePerPage: 6,
			noDataText: ' '
		};

		return (
			<div>
				<div className="row" style={{ marginLeft: '0px' }}>
					<Search id="inputCat" className="col-md-7 col-sm-6 col-xs-6"
						placeholder="Search category"
						onSearch={value => { this.onClick(value); }}
						enterButton
					/>
				</div>
				<div className="row">
					<BootstrapTable ref='table' data={this.state.items} className="col-md-12 col-sm-12 col-xs-12" pagination selectRow={selectRow} options={options} striped hover condensed>
						<TableHeaderColumn dataField='category' isKey={true} width="60" dataSort={true}  >Category
                                        </TableHeaderColumn>
						<TableHeaderColumn dataField='catDescr' dataSort={true} width="150" >Category Description</TableHeaderColumn>
					</BootstrapTable>
					<div id="loader4" style={{ textAlign: "center", margin: "auto" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		)
	}
}
