import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';


const brokerURL = 'Broker';
const vendorURL = 'Vendor';
const getBVRURL = 'getVendorBrokerTieReport';
const vendorOpcoURL = 'Opco';
const products = [];
const insertStatusURL = "insertBrokerTieReportStatus";

class Table2 extends React.Component {
	constructor(props) {
		super(props);
		this.name = this.props.history.location.state.data;
		this.group = this.props.group;
		this.currentUser = this.props.currentUser;
		this.brokerType = this.props.brokerType;
		this.brokerCode = this.props.brokerCode;
		this.state = {
			broker: {
				brokerNumber: this.props.brokerNumber,
				brokerCode: this.props.brokerCode,
				mailId: this.props.mailId,
				brokerName: this.props.brokerName
			},
			vnumber: this.props.vendorNumber,
			oId: this.props.opcoId,
			products: [],
			counter: 1
		};


	}

	componentDidMount() {
		console.log("this.name is", this.name)
		console.log("this.group is", this.group)
		$("#loaderBV").show();
		this.fetchReport();
		console.log("bvt props", this.props);
		console.log("bvt state", this.state)

	}

	getAdminVBTReport(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + getBVRURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param)

		})
			.done(function (data) {
				$("#loaderBV").hide();
				for (var el in data.rows) {
					comp.state.products.push({
						"vendorNumber": data.rows[el].vendorNumber,
						"vendorName": data.rows[el].vendorName,
						"brokerCode": data.rows[el].brokerCode,
						"brokerNumber": data.rows[el].brokerNumber,
						"brand": data.rows[el].brand,
						"market": data.rows[el].market,
						"itemId": data.rows[el].itemId,
						"itemDescr": data.rows[el].itemDescr,
						"opcoId": data.rows[el].opcoId,
						"opcoName": data.rows[el].opcoName,
						"startDate": data.rows[el].startDate.slice(0, 10),
						"endDate": data.rows[el].endDate.slice(0, 10)
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getAdminVBTReport(param, true);
				}
			});
	}

	fetchReport = () => {
		var comp = this;
		comp.setState({ products: [] });
		if (this.group === "admin" || this.group === "cm") {
			var data = {
				"pageNumber": this.state.counter,
				"searchFunctionality": $('.searchBar').val() ? "yes" : "no",
				"searchValue": $('.searchBar').val(),
				"userGroup": this.group,
				"userName": this.currentUser
			}
			this.getAdminVBTReport(data, false);
		}
		else if (this.group === "user") {

			var data1 = {
				"pageNumber": this.state.counter,
				"searchFunctionality": $('.searchBar').val() ? "yes" : "no",
				"searchValue": $('.searchBar').val(),
				"userGroup": "broker",
				"userName": this.currentUser,
				"brokerType": this.brokerType,
				"brokerCode": this.brokerCode,
				"brokerNumber": this.state.broker.brokerNumber
			}
			this.getBrokerVBTReport(data1, false);
		}

		if (this.group == "viewer") {
			var param = {
				"vendorNumber": this.state.vnumber
			}
			this.getVendorVBTReport(param, false);
		}

		if (this.group == "opcoviewer") {
			var param1 = {
				"opcoId": this.state.oId
			}
			this.getOpcoVBTReport(param1, false);
		}
	}

	getBrokerVBTReport(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + getBVRURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data) {
				$("#loaderBV").hide();
				for (var el in data.rows) {
					comp.state.products.push({
						"brokerCode": data.rows[el].brokerCode,
						"brokerNumber": data.rows[el].brokerNumber,
						"vendorNumber": data.rows[el].vendorNumber,
						"vendorName": data.rows[el].vendorName,
						"market": data.rows[el].market,
						"itemId": data.rows[el].itemId,
						"itemDescr": data.rows[el].itemDescr,
						"opcoId": data.rows[el].opcoId,
						"opcoName": data.rows[el].opcoName,
						"brand": data.rows[el].brand,
						"startDate": data.rows[el].startDate.slice(0, 10),
						"endDate": data.rows[el].endDate.slice(0, 10)
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getBrokerVBTReport(param, true);
				}
				else
					$("#loaderBV").hide();
			});
	}

	getVendorVBTReport(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + vendorURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data) {
				$("#loaderBV").hide();
				for (var el in data.rows) {
					comp.state.products.push({
						"vendorNumber": comp.state.vnumber,
						"brokerCode": data.rows[el].brokerCode,
						"brokerNumber": data.rows[el].brokerNumber,
						"market": data.rows[el].market,
						"opcoId": data.rows[el].opcoId,
						"brand": data.rows[el].brand,
						"itemId": data.rows[el].itemId,
						"itemDescr": data.rows[el].itemDescr,
						"startDate": data.rows[el].startDate.slice(0, 10),
						"endDate": data.rows[el].endDate.slice(0, 10)
					})
				}

				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken()
				}
				else
					$("#loaderBV").hide();
			});
	}

	getOpcoVBTReport(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + vendorOpcoURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data) {
				$("#loaderBV").hide();
				for (var el in data.rows) {
					comp.state.products.push({
						"vendorNumber": data.rows[el].vendorNumber,
						"brokerCode": data.rows[el].brokerCode,
						"brokerNumber": data.rows[el].brokerNumber,
						"market": data.rows[el].market,
						"opcoId": comp.state.oId,
						"brand": data.rows[el].brand,
						"itemDescr": data.rows[el].itemDescr,
						"startDate": data.rows[el].startDate.slice(0, 10),
						"itemId": data.rows[el].itemId,
						"endDate": data.rows[el].endDate.slice(0, 10)
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken()
				}
				else
					$("#loaderBV").hide();
			});
	}

	incrementCounter1 = () => {
		var counter = this.state.counter;
		counter += 1;
		console.log("local counter", counter);
		this.setState({ counter }, () => {
			console.log("value", $('.search').val());
			console.log("counter", this.state.counter);
			this.fetchReport();
		})
	}

	decrementCounter1 = () => {
		var counter = this.state.counter;
		counter -= 1;
		this.setState({ counter }, () => {
			console.log("value", $('.search').val());
			console.log("counter", this.state.counter);
			this.fetchReport();
		})
	}

	search = () => {
		this.setState({ counter: 1 }, () => { this.fetchReport(); });

	}

	downloadReport1 = () => {
		var comp = this;
		var inputdata = {
			"userName": localStorage.getItem("userId"),
			"userGroup": comp.group === "user" ? "broker" : comp.group,
			"brokerType": comp.brokerType == "Broker Corporate" ? "Broker Corporate" : comp.brokerType,
			"brokerGroupId": this.brokerCode,
			"brokerNumber": comp.state.broker.brokerNumber
		}
		this.insertBrokerTieReportStatus(inputdata, false);
		if (window.confirm("Your Report will be available for download within few moments")) {
			this.viewStatus();
		}
	}

	viewStatus = () => {
		this.props.history.push({ pathname: '/suite/vbt/view_bvr_download_status', state: { data: this.name, group: this.group, mailId: this.state.broker.mailId, brokerNumber: this.state.broker.brokerNumber, brokerCode: this.state.broker.brokerCode, brokerName: this.state.broker.brokerName, currentUser: this.currentUser, brokerType: this.brokerType } })
	}

	insertBrokerTieReportStatus(inputdata, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + insertStatusURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(inputdata),
		})
			.done(function (data) {

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.insertBrokerTieReportStatus(inputdata, true);
				}
				else
					$("#edit").prop('disabled', false);
			});
	}

	render() {

		const options = {
			sizePerPage: 14,
			noDataText: ' '
		}
		return (
			<div>
				<div className="row">

					<div className="btn-group col-md-4" role="group" ariaLabel="First group">
						<button type="button" className="btn btn-primary" onClick={this.downloadReport1}>Download Report</button>
						<button type="button" className="btn btn-primary" onClick={this.viewStatus}>View download status</button>
					</div>
					<div className="searchGroup col-md-4 col-md-offset-4" style={{ padding: "0px 15px !important", float: 'right', display: 'flex' }}>
						<input type="text" className="form-control searchBar" style={{ width: '83%' }} placeholder="Search" ariaLabel="Search" ariaDescribedBy="button-addon2" />
						<div className="input-group-append" style={{ position: 'sticky' }}>
							<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.search.bind(this)}><span className="glyphicon glyphicon-search" aria-hidden="true"></span></button>
						</div>
					</div>
				</div>
				<div style={{ width: "95vw", overflowX: "auto", overflowY: "hidden" }}><br />
					<BootstrapTable ref='table' data={this.state.products} style={{ overflowX: "auto" }} options={options} striped hover condensed>
						<TableHeaderColumn ref='brokerCode' width="110" dataField='brokerCode' dataSort={true} >Broker Code</TableHeaderColumn>
						<TableHeaderColumn ref='brokerNumber' width="100" dataField='brokerNumber' dataSort={true} >Broker No</TableHeaderColumn>
						<TableHeaderColumn ref='vendorNumber' width="100" dataField='vendorNumber' dataSort={true} >Vendor No</TableHeaderColumn>
						<TableHeaderColumn ref='vendorName' width="110" dataField='vendorName' dataSort={true} >Vendor Name</TableHeaderColumn>
						<TableHeaderColumn ref='market' width="100" dataField='market' isKey={true} dataSort={true}>Market</TableHeaderColumn>
						<TableHeaderColumn ref='opcoId' width="100" dataField='opcoId' dataSort={true} >OpCo Id</TableHeaderColumn>
						<TableHeaderColumn ref='opcoName' width="100" dataField='opcoName' dataSort={true} >OpCo Name</TableHeaderColumn>
						<TableHeaderColumn ref='brand' width="100" dataField='brand' dataSort={true} >Brand</TableHeaderColumn>
						<TableHeaderColumn ref='itemId' width="100" dataField='itemId' dataSort={true} >Item Id</TableHeaderColumn>
						<TableHeaderColumn dataField='itemDescr' width="200" dataSort={true}>Item descripton</TableHeaderColumn>
						<TableHeaderColumn ref='startDate' width="100" dataField='startDate' dataSort={true} >Start date</TableHeaderColumn>
						<TableHeaderColumn ref='endDate' width="100" dataField='endDate' dataSort={true}>End date</TableHeaderColumn>
					</BootstrapTable>
				</div>
				<div id="loaderBV" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				<button style={{ marginBottom: "35px" }} className="btn btn-primary" onClick={this.decrementCounter1} disabled={this.state.counter === 1 ? true : false}>Previous</button>
				<button style={{ marginBottom: "35px" }} className="btn btn-primary" onClick={this.incrementCounter1}>Next</button>
			</div>
		);
	}
}

export default withRouter(Table2);
