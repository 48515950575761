import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { Checkbox, message } from 'antd';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import sysco from './handshake.png';
import NavBar1 from './navbar.jsx';
var userId, group;

const addBrokerURL = 'addBrokerList';
const addOpcoURL = 'addOpco';
const addVendorURL = 'addVendor';
const addCorporateURL = 'addCorporate';
const addAdminURL = 'addAdmin';
const addBrokerURLTable = "addCompanyBranch";

function checkProperties(obj) {
	for (var key in obj) {
		if (obj[key].length == 0) {
			message.error(<span className="errorMsg">"Please fill all the details"</span>)
			$("#add").prop('disabled', false);
			return false;
		}
	}
	$("#add").prop('disabled', true);
	return true;
}
class AddRow extends React.Component {

	constructor(props) {

		super(props)
		this.state = {
			data: { code: '', name: '', userName: '', email: '', syscoId: '', contactnumber: '', brokerNumber: '', vno: '', oid: '', bname: '', brokerTypeSelected: "", subBrokerName: '' },
			upload: "none", vendor1: "none", opco1: "none", admin1: "none", cm1: "none"
		}
		this.setEmpState = this.setEmpState.bind(this)

		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.groupName = this.props.history.location.state.groupName;
		this.typePassed = this.props.history.location.state.typePassed
		this.bCode = this.props.history.location.state.brokerCode
		this.brName = this.props.history.location.state.brokerName
		this.addRows = this.addRows.bind(this)
	}

	componentDidMount() {
		initGA();
		PageView();
	}
	componentWillMount() {
		if (this.props.history.location.state.groupName == "broker") {
			this.setState({ upload: "block", notUpload: "none" });
		}
		if (this.props.history.location.state.groupName == "viewer") {
			this.setState({ vendor1: "block" });
		}
		if (this.props.history.location.state.groupName == "opco") {
			this.setState({ opco1: "block" });
		}
		if (this.props.history.location.state.groupName == "admin") {
			this.setState({ admin1: "block" });
		}
		if (this.props.history.location.state.groupName == "cm") {
			this.setState({ cm1: "block" });
		}

	}

	setEmpState(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.state.data[field] = value;
		if (e.target.checked) {
			this.state.data["brokerTypeSelected"] = "corporate";
		}
		else {
			this.state.data["brokerTypeSelected"] = "normal";
		}
		this.setState({ data: this.state.data });
	}

	addRows(e) {
		e.preventDefault();
		var param;
		var comp = this;
		if (this.props.history.location.state.groupName == "broker") {
			if (this.props.history.location.state.typePassed == "Normal user") {
				param = {
					"brokerCode": comp.state.data.code.toString(),
					"brokerName": comp.state.data.bname.toString(),
					"brokerNumber": comp.state.data.brokerNumber.toString(),
					//    "contactPersonName": comp.state.data.name.toString(),
					//    "brokerType":this.props.history.location.state.typePassed
					"branch": comp.state.data.brokerTypeSelected.toString(),
					//    "mailId": comp.state.data.email.toString(),
					//    "userName": comp.state.data.userName.toString(),
					//    "phoneNumber": comp.state.data.contactnumber.toString(),
					//    "userGroup": comp.groupName.toString(),
					//    "addedBy":comp.name
				}
			}
			else {
				param = {
					"brokerCode": this.bCode,
					"brokerName": comp.state.data.bname,
					"brokerNumber": comp.state.data.brokerNumber.toString(),
					"branch": "normal",
					//    "contactPersonName": comp.state.data.name.toString(),
					//  "brokerType":this.props.history.location.state.typePassed
					//    "mailId": comp.state.data.email.toString(),
					//    "userName": comp.state.data.userName.toString(),
					//    "phoneNumber": comp.state.data.contactnumber.toString(),
					//    "userGroup": comp.groupName.toString(),
					//    "addedBy":comp.name
				}
			}
			if (checkProperties(param)) {
				comp.addUser(this.props.history.location.state.clickedFromTable ? addBrokerURLTable : addBrokerURL, param, false);
			}
		}
		else if (this.props.history.location.state.groupName == "cm") {
			var param1 = {
				"contactPersonName": comp.state.data.name.toString(),
				"mailId": comp.state.data.email.toString(),
				"userName": comp.state.data.userName.toString(),
				"phoneNumber": comp.state.data.contactnumber.toString(),

				"userGroup": comp.groupName.toString(),
				"addedBy": comp.name
			}
			if (checkProperties(param1)) {
				comp.addUser(addCorporateURL, param1, false);
			}
		} else if (this.props.history.location.state.groupName == "viewer") {
			var param2 = {

				"vendorNumber": comp.state.data.vno.toString(),

				"contactPersonName": comp.state.data.name.toString(),
				"mailId": comp.state.data.email.toString(),
				"userName": comp.state.data.userName.toString(),
				"phoneNumber": comp.state.data.contactnumber.toString(),

				"userGroup": "vendor",
				"addedBy": comp.name
			}
			if (checkProperties(param2)) {
				comp.addUser(addVendorURL, param2, false);
			}
		} else if (this.props.history.location.state.groupName == "opco") {
			var param3 = {

				"opcoId": comp.state.data.oid.toString(),
				"contactPersonName": comp.state.data.name.toString(),
				"mailId": comp.state.data.email.toString(),
				"userName": comp.state.data.userName.toString(),
				"phoneNumber": comp.state.data.contactnumber.toString(),

				"userGroup": comp.groupName.toString(),
				"addedBy": comp.name
			}
			if (checkProperties(param3)) {
				comp.addUser(addOpcoURL, param3, false);
			}
		} else if (this.props.history.location.state.groupName == "admin") {
			var param4 = {
				"contactPersonName": comp.state.data.name.toString(),
				"mailId": comp.state.data.email.toString(),
				"userName": comp.state.data.userName.toString(),
				"phoneNumber": comp.state.data.contactnumber.toString(),
				"userGroup": comp.groupName.toString(),
				"addedBy": comp.name
			}
			if (checkProperties(param4)) {
				comp.addUser(addAdminURL, param4, false);
			}
		}
	}

	addUser(urlPath, param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + urlPath,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				$("#loader").hide();
			}
		}).done(function (data) {
			$("#add").prop('disabled', false);
			if (data.result) {
				message.success(<span className="errorMsg">'AddedSuccessfully'</span>);
				comp.props.history.push({
					pathname: '/suite/vbt/admin',
					state: {
						data: comp.name,
						group: "admin"
					}
				})
			} else {
				message.error(<span className="errorMsg">"duplicate records"</span>);
			}
		}).fail(function (jqXhr) {
			if (!didFail && jqXhr.status == 401) {
				GetToken();
				comp.addUser(urlPath, param, true);
			}
			else
				$("#add").prop('disabled', false);
		});
	}

	render() {
		return (<div className="container-fluid">
			<NavBar1 name={this.name} group={this.group} />
			<br />
			<div className="class1">
				<div className="row">
					<h1 className="headings tab" style={{ display: this.state.upload }}>Add Broker Details</h1>
					<h1 className="headings tab" style={{ display: this.state.vendor1 }}>Add Vendor Details</h1>
					<h1 className="headings tab" style={{ display: this.state.opco1 }}>Add OpCo User Details</h1>
					<h1 className="headings tab" style={{ display: this.state.admin1 }}>Add Admin Details</h1>
					<h1 className="headings tab" style={{ display: this.state.cm1 }}>Add Corporate Merchandiser Details</h1>

				</div><br />
				<div className="row" style={{ fontSize: "15px" }}>
					<form className="form-horizontal col-md-7 col-sm-11 addForm" onSubmit={this.addRows}>
						<div className="col-md-offset-3 col-md-7"><h5 style={{ color: "red" }} >Note: All fields are mandatory</h5></div>
						<FormGroup style={{ display: (this.state.upload && (this.typePassed === "Normal user" ? "block" : "none")) }}>
							<div className="col-md-3 aname"><label>Broker Code: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="code" value={this.state.data.code} pattern="[A-Z]{2}" title="Please enter the code in caps" maxLength="2" onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup style={{ display: (this.state.upload && (this.typePassed === "Normal user" ? "block" : "none")) }}>
							<div className="col-md-3 aname"><label>Broker Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="bname" value={this.state.data.bname} onChange={this.setEmpState} />
							</div>
						</FormGroup>


						<FormGroup style={{ display: (this.state.upload && (this.typePassed == "Broker Corporate" ? "block" : "none")) }}>
							<div className="col-md-3 aname"><label>Broker Code: </label></div>
							<div className="col-md-6">
								<input disabled className="form-control" type="text" name="code" value={this.bCode} pattern="[A-Z]{2}" title="Please enter the code in caps" maxLength="2" onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup style={{ display: (this.state.upload && (this.typePassed == "Broker Corporate" ? "block" : "none")) }}>
							<div className="col-md-3 aname"><label>Broker Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="bname" value={this.state.data.bname} onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup style={{ display: (this.state.upload) }}>
							<div className="col-md-3 aname"><label>
								Broker Number: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="brokerNumber" value={this.state.data.brokerNumber} title="Please enter a 5 or 6 digit broker number" pattern="[0-9]{5,6}" maxLength="6" onChange={this.setEmpState} />
							</div>
						</FormGroup>

						{/* <FormGroup style={{ display: (this.state.upload )}}>
							<div className="col-md-3 aname"><label>
								Sub Broker Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="subBrokerName" value={this.state.data.subBrokerName}  onChange={this.setEmpState} />
							</div>
						</FormGroup>  */}

						<FormGroup style={{ display: (this.state.upload && (this.typePassed == "Normal user" ? "block" : "none")) }}>
							<div className="col-md-3 aname"><label>
								Is Broker Corporate: </label></div>
							<div className="col-md-6">
								<Checkbox name="brokerTypeSelected" onChange={this.setEmpState} value={this.state.data.brokerTypeSelected} />
							</div>
						</FormGroup>

						<FormGroup style={{ display: this.state.vendor1 }}>
							<div className="col-md-3 aname"><label>Vendor Number: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="number" name="vno" value={this.state.data.vno} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup style={{ display: this.state.vendor1 }}>
							<div className="col-md-3 aname"><label>Vendor Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="name" value={this.state.data.name} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup style={{ display: this.state.opco1 }}>
							<div className="col-md-3 aname"><label>OpCo Id: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="oid" title="Please enter a 3 digit opco id" pattern="[0-9]{3}" value={this.state.data.oid} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup style={{ display: this.state.cm1 }}>
							<div className="col-md-3 aname"><label>Corporate Merchandiser Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="name" value={this.state.data.name} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup style={{ display: this.state.admin1 }}>
							<div className="col-md-3 aname"><label>Admin Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="name" value={this.state.data.name} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup style={{ display: this.state.notUpload }}>
							<div className="col-md-3 aname"><label>Email: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="email" name="email" value={this.state.data.email} onChange={this.setEmpState} />
							</div>
						</FormGroup>
						<FormGroup style={{ display: this.state.notUpload }}>
							<div className="col-md-3 aname"><label><span className="addalign">User Name</span>: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="userName" value={this.state.data.userName} onChange={this.setEmpState} />
							</div>
						</FormGroup>

						{/* <FormGroup style={{ display: this.state.upload }}>
							<div className="col-md-3 aname"><label>Contact Person Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="name" value={this.state.data.name} onChange={this.setEmpState} />
							</div>
						</FormGroup> */}
						<FormGroup style={{ display: this.state.opco1 }}>
							<div className="col-md-3 aname"><label>Contact Person Name: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="name" value={this.state.data.name} onChange={this.setEmpState} />
							</div>
						</FormGroup>

						<FormGroup style={{ display: this.state.notUpload }}>
							<div className="col-md-3 aname"><label>Contact no: </label></div>
							<div className="col-md-6">
								<input className="form-control" type="text" name="contactnumber" value={this.state.data.contactnumber} pattern="[0-9]{10}" title="Please enter a 10 digit phone number" maxLength="10" onChange={this.setEmpState} />
							</div>
						</FormGroup>


						<div className="col-md-offset-3 col-md-4">
							<Button type="submit" className="btn btn-primary btn-lg" id="add"> Submit </Button>
						</div>
					</form>
					<div className="col-md-3">
						<img src="/static/media/handshake.c4db6082.png" style={{ paddingTop: "15%" }} className="handshake" alt="image" />
					</div>

				</div>

			</div>
		</div>)
	}
}
export default withAuth(AddRow);