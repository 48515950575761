import { message } from 'antd';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';


const duplicateURL = 'duplicateBrokers';
const deleteupURL = 'deleteDuplicateTie';
const products = [];
var rowKeys = [];
var rowPushed = [];



var comp;
class Notif extends React.Component {

	constructor(props) {
		super(props);


		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.state = { products: [] };
		this.duplicateBrokers = this.duplicateBrokers.bind(this);
	}

	componentWillMount() {
		comp = this;

		comp.fetchDuplicateBrokers();
	}

	fetchDuplicateBrokers() {

		$("#loaderDupli").show();

		comp.state.products = [];
		comp.setState({ products: comp.state.products });
		comp.duplicateBrokers(false);
	}

	duplicateBrokers(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + duplicateURL,
			'contentType': 'application/json',
			'dataType': 'json',


		})
			.done(function (data) {
				$("#loaderDupli").hide();
				for (var el in data.rows) {
					comp.state.products.push({
						"key": el,
						"opcoId": data.rows[el].opcoId,
						"vendorNumber": data.rows[el].vendorNumber,
						"brokerNumber": data.rows[el].brokerNumber,
						"brokerCode": data.rows[el].brokerCode,
						"itemId": data.rows[el].itemId,
						//"conflictResolveDate":data.rows[el].conflictResolveDate,
						"itemDescr": data.rows[el].itemDescr,
						"startDate": data.rows[el].startDate.slice(0, -2),
						"endDate": data.rows[el].endDate.slice(0, -2),
						"sd": data.rows[el].startDate.slice(0, 10),
						"ed": data.rows[el].endDate.slice(0, 10),
						"doneBy": comp.name,
						"userName": data.rows[el].userName
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.duplicateBrokers(true);
				}
				else
					$("#loaderDupli").hide();
			});
	}
	handleDeleteButtonClick = (onClick) => {

		//var comp=this;
		//var brokerCode1="";

		var param = rowKeys;
		comp.deleteDuplicateTie(param, false);

	}

	deleteDuplicateTie(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + deleteupURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data) {
				message.success(<span className="errorMsg">"Selected Ties are deleted"</span>);
				window.location.reload(true);

			})
			.fail(function (data) {
				if (!didFail && data.status == 401) {
					GetToken();
					comp.deleteDuplicateTie(param, true);
				}
			});
	}
	createCustomDeleteButton = (onClick) => {
		return (

			<button type="button" id="delete" className="btn btn-warning react-bs-table-del-btn buttonDelete" id="delete" style={{ marginRight: "14px", color: '#fff', backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: '14px' }} onClick={() => this.handleDeleteButtonClick(onClick)}>
				<span><i className="glyphicon glyphicon-trash"> </i>Delete</span>
			</button>

		);
	}

	render() {

		const selectRowProp = {
			mode: "checkbox",
			onSelect: function (row, isSelected) {
				if (isSelected) {
					var obj = {};
					obj.brokerNumber = row.brokerNumber;
					obj.conflictResolveDate = row.conflictResolveDate;
					obj.endDate = row.endDate;
					obj.itemId = row.itemId;
					obj.opcoId = row.opcoId;
					obj.startDate = row.startDate;
					obj.vendorNumber = row.vendorNumber;
					obj.doneBy = comp.name;
					obj.userName = row.userName

					rowKeys.push(obj);
				} else {
					rowKeys.splice(rowKeys.indexOf(row), 1);
				}
			},
			onSelectAll: function (isSelected, rows) {
				rowKeys = [];
				if (isSelected) {
					rowKeys = comp.state.products.map(row => row);
					return comp.state.products.map(row => row.key);
				}
				else {
					rowKeys = [];
					return [];
				}
			}


		};
		const options = {
			deleteBtn: this.createCustomDeleteButton,
			sizePerPage: 6,
			noDataText: ' '
		};





		return (
			<div className="container-fluid">
				<NavBar1 name={this.name} group={this.group} /> <br />
				<div style={{ fontSize: "14px" }}>
					<h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Resolve duplicates</h1>
				</div><br />
				<div style={{ width: "95vw", overflowX: "auto" }}>
					<BootstrapTable ref='table' data={this.state.products} selectRow={selectRowProp} options={options} deleteRow search={true} pagination striped hover condensed>



						<TableHeaderColumn ref='key' dataField='key' isKey={true} dataSort={true} hidden >Key</TableHeaderColumn>
						<TableHeaderColumn ref='brokerNumber' width='100' dataField='brokerNumber' dataSort={true} >Broker No</TableHeaderColumn>
						<TableHeaderColumn ref='brokerCode' width='120' dataField='brokerCode' dataSort={true} >Broker Code</TableHeaderColumn>
						<TableHeaderColumn ref='vendorNumber' width='100' dataField='vendorNumber' dataSort={true} >Vendor No</TableHeaderColumn>
						<TableHeaderColumn ref='opcoId' width='100' dataField='opcoId' dataSort={true} >OPCO Id</TableHeaderColumn>
						<TableHeaderColumn ref='itemId' width='100' dataField='itemId' dataSort={true} >Item ID</TableHeaderColumn>
						<TableHeaderColumn ref='itemDescr' width='250' dataField='itemDescr' dataSort={true} >Item Description</TableHeaderColumn>
						<TableHeaderColumn ref='startdate' width='100' dataField='startDate' dataSort={true} width="100" editable={false} hidden>Start date</TableHeaderColumn>
						<TableHeaderColumn ref='enddate' width='100' dataField='endDate' dataSort={true} width="100" editable={false} hidden>End date</TableHeaderColumn>
						<TableHeaderColumn ref='sd' width='100' dataField='sd' dataSort={true} width="100" editable={false}>Start date</TableHeaderColumn>
						<TableHeaderColumn ref='ed' width='100' dataField='ed' dataSort={true} width="100" editable={false} >End date</TableHeaderColumn>
						<TableHeaderColumn ref='doneBy' width='150' dataField='doneBy' dataSort={true} hidden>Done by</TableHeaderColumn>
						<TableHeaderColumn ref='userName' width='150' dataField='userName' dataSort={true} hidden>userName</TableHeaderColumn>


					</BootstrapTable>
				</div>
				<div id="loaderDupli" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
			</div>
		);
	}


}
export default withAuth(Notif);