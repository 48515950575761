import { Input, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import img1 from '../broker vendor/loading.gif';
import URL from '../url';
import setKeys from './utility';

const Search = Input.Search;

var comp;
export default class OpcoIdFilter extends React.Component {
    state = {
        items: [],
        selectedRows: [],
        loading: false,
        opcoIdSearch: ""
    };

    onClickSearch = () => {
        this.setState({ loading: true });
        let param = {
            opcoName: this.state.opcoIdSearch
        }
        console.log(param)
        this.getOpcoName(param, false);
    }

    getOpcoName(param, didFail) {
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + "getOpcoName",
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param)
        }).done(data => {
            let newItems = [];
            this.setState({ loading: true });
            data.rows.map(item => {
                newItems.push(item);
            });

            newItems = setKeys(newItems);
            this.setState({ items: newItems });
            console.log(data)

        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                this.getOpcoName(param, true);
            }
        })
    }

    displayLoading = () => {
        if (this.state.loading) {
            return <div style={{ textAlign: "center", margin: "auto" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
        }
        else {
            return null;
        }
    }

    onChangeSearch = (event) => {
        this.setState({ opcoIdSearch: event.target.value });
    }

    onOk = () => {
        let rows = [...this.state.selectedRows]
        let opcoIds = rows.join(',');
        this.props.setOpcoId(opcoIds);
    }
    render() {

        const options = {
            sizePerPage: 6,
            noDataText: ' '
        };

        const selectRow = {
            mode: 'checkbox',
            onSelect: (row, isSelected) => {
                let newRows = [...this.state.selectedRows];
                if (isSelected) {
                    newRows.push(row.opcoId);
                }
                else {
                    newRows.splice(newRows.indexOf(row.opcoId), 1);
                }
                this.setState({ selectedRows: newRows });
            },
            onSelectAll: (isSelected, rows) => {
                if (isSelected) {
                    let newRows = [];
                    rows.map(row => {
                        newRows.push(row.opcoId)
                    })
                    this.setState({ selectedRows: newRows })
                }
                else {
                    this.setState({ selectedRows: [] })
                }
            }
        };

        return (
            <Modal
                title="Search OpCo"
                visible={this.props.visible}
                onOk={this.onOk}
                onCancel={this.props.onCancel}
            >
                <div>
                    <div className="row" style={{ marginLeft: '0px' }}>
                        <Search
                            id="opcoIdSearch"
                            placeholder="Search for Opco Name"
                            className="col-md-7 col-sm-6 col-xs-6"
                            onSearch={() => this.onClickSearch()}
                            onChange={(event) => this.onChangeSearch(event)}
                            value={this.state.opcoIdSearch}
                            enterButton
                        />
                    </div>
                    <div className="row">
                        <BootstrapTable ref='table' data={this.state.items} selectRow={selectRow} className="col-md-12 col-sm-12"
                            pagination options={options} striped hover condensed>
                            <TableHeaderColumn
                                dataField='opcoId'
                                isKey={true}
                                dataSort={true}
                                width="60">
                                Opco Id
                        </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='opcoName'
                                dataSort={true}
                                width="150">
                                Opco Name
                        </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </Modal>
        )
    }
}
