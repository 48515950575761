import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

var itemId;
'use strict';

const vendorOpcoURL = 'Opco';
const supcURL = 'getSUPC';
//var itemId;

class ItemTable extends React.Component {
	constructor(props) {
		super(props);
		//var row='';
		this.state = {
			name: itemId,
			open: true,
			data: []
		};
	}
	close2 = () => {
		this.setState({ open: false });
		this.props.onUpdate(this.props.defaultValue);
		this.getSUPC = this.getSUPC.bind(this);
	}

	componentWillMount() {
		var comp = this;
		var param = {
			"itemId": this.state.name
		}
		comp.getSUPC(param, false);
	}

	getSUPC(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + supcURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data9) {
				for (var el in data9.rows) {
					comp.state.data.push({
						itemId: data9.rows[el].itemId,
						itemPack: data9.rows[el].itemPack,
						itemSize: data9.rows[el].itemSize,
						itemDescr: data9.rows[el].itemDescr,
						brand: data9.rows[el].brand,
						category: data9.rows[el].category,
						major: data9.rows[el].major,
						intermediate: data9.rows[el].intermediate,
						minor: data9.rows[el].minor
					})
				}
				comp.setState({ data: comp.state.data })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getSUPC(param, true);
				}
			});
	}

	render() {
		const row = [];
		for (var i1 in this.state.data) {
			row.push(<tr key={i1}>
				<td>{this.state.data[i1].itemId}</td>
				<td>{this.state.data[i1].itemPack}</td>
				<td>{this.state.data[i1].itemSize}</td>
				<td>{this.state.data[i1].itemDescr}</td>
				<td>{this.state.data[i1].category}</td>
				<td>{this.state.data[i1].minor}</td>
				<td>{this.state.data[i1].intermediate}</td>
				<td>{this.state.data[i1].major}</td>

			</tr>)
		}

		//const fadeIn = this.state.open ? 'in' : '';
		const display = this.state.open ? 'block' : 'none';
		return (<div><span>{this.state.name}</span>
			<div className="modal" id='myModal' role='dialog' style={{ display }} >
				<div className='modal-dialog modal-lg' style={{ margin: "32vh auto auto auto" }}>

					<div className='modal-content'>
						<div className="modal-header">
							<h5 className="modal-title">Item Details</h5>
							<button type="button" className="close" data-dismiss="modal" onClick={this.close2} aria-label="Close" style={{ border: "1px solid red", backgroundColor: "red", color: "white" }}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className='modal-body' style={{ overflowX: "auto" }} >
							<div className="inside" style={{ width: "65vw" }}>
								<BootstrapTable ref='itemtable' data={this.state.data} maxHeight={'200px'}>
									<TableHeaderColumn ref='itemId' dataField='itemId' isKey={true} width='50' >Item Id</TableHeaderColumn>
									<TableHeaderColumn dataField='itemPack' ref='itemPack' width='50'>Item pack</TableHeaderColumn>
									<TableHeaderColumn ref='itemSize' dataField='itemSize' width='30' >Item Size</TableHeaderColumn>
									<TableHeaderColumn ref='itemDescr' dataField='itemDescr' width='100' >Item Description</TableHeaderColumn>
									<TableHeaderColumn ref='category' dataField='category' width='50' >Category</TableHeaderColumn>
									<TableHeaderColumn ref='minor' dataField='minor' width='50' >Minor</TableHeaderColumn>
									<TableHeaderColumn ref='intermediate' dataField='intermediate' width='50' >Intermediate</TableHeaderColumn>
									<TableHeaderColumn ref='major' dataField='major' width='50' >Major</TableHeaderColumn>
								</BootstrapTable>
							</div></div>
					</div>
				</div></div>
		</div>
		);
	}
}

const createItemTable = (onUpdate, props) => (<ItemTable onUpdate={onUpdate} {...props} />);



export default class VendorOpcoFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = { opcoId: this.props.opcoId, products: [] };
		this.getOPCO = this.getOPCO.bind(this);
	}

	componentWillMount() {
		var comp = this;
		$("#loaderOPCO1").show();
		var paramOpco = {
			"opcoId": this.state.opcoId
		}
		comp.getOPCO(paramOpco, false);
	}

	getOPCO(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + vendorOpcoURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data1) {
				$("#loaderOPCO1").hide();
				for (var el in data1.rows) {
					comp.state.products.push({
						"vendorNumber": data1.rows[el].vendorNumber,
						"vendorName": data1.rows[el].vendorName,
						"brokerCode": data1.rows[el].brokerCode,
						"brokerNumber": data1.rows[el].brokerNumber,
						"market": data1.rows[el].market,
						"category": data1.rows[el].category,
						"major": data1.rows[el].major,
						"minor": data1.rows[el].minor,
						"intermediate": data1.rows[el].intermediate,
						"brand": data1.rows[el].brand,
						"itemDescr": data1.rows[el].itemDescr,
						"startDate": data1.rows[el].startDate.slice(0, 10),
						"itemId": data1.rows[el].itemId,
						"endDate": data1.rows[el].endDate.slice(0, 10)
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getOPCO(param, true);
				}
				else
					$("#loaderOPCO1").hide();
			});
	}

	render() {

		const cellEditProp1 = {
			mode: 'click',
			blurToSave: true
		};
		const tableOptions = {
			onRowClick: function (row) {
				itemId = row.itemId

			},
			sizePerPage: 8,
			noDataText: ' '
		}
		return (
			<div style={{ overflowX: "auto" }}>
				<BootstrapTable ref='table' data={this.state.products} pagination cellEdit={cellEditProp1} options={tableOptions} search={true} striped hover condensed>
					<TableHeaderColumn ref="vendorNumber" dataField='vendorNumber' isKey={true} width="100" dataSort={true} editable={false}>Vendor No
			</TableHeaderColumn>
					<TableHeaderColumn ref='vendorName' dataField='vendorName' dataSort={true} width="150" editable={false} >Vendor Name</TableHeaderColumn>
					<TableHeaderColumn ref='market' dataField='market' dataSort={true} width="100" editable={false}>Market</TableHeaderColumn>
					<TableHeaderColumn refs="brokerNumber" dataField='brokerNumber' width="100" dataSort={true} editable={false} >Broker No</TableHeaderColumn>
					<TableHeaderColumn refs="category" dataField='category' width="50" dataSort={true} editable={false}>Cat</TableHeaderColumn>
					<TableHeaderColumn refs="major" dataField='major' width="50" dataSort={true} editable={false}>Maj</TableHeaderColumn>
					<TableHeaderColumn refs="intermediate" dataField='intermediate' width="50" dataSort={true} editable={false}>Int</TableHeaderColumn>
					<TableHeaderColumn refs="minor" dataField='minor' dataSort={true} width="50" editable={false}>Min</TableHeaderColumn>
					<TableHeaderColumn refs="brand" dataField='brand' dataSort={true} width="100" editable={false}>Brand</TableHeaderColumn>
					<TableHeaderColumn ref='itemId' dataField='itemId' dataSort={true} width="100" customEditor={{ getElement: createItemTable }}>Item Id</TableHeaderColumn>
					<TableHeaderColumn ref='itemDescr' dataField='itemDescr' dataSort={true} width="200" editable={false}>Item descripton</TableHeaderColumn>
					<TableHeaderColumn ref='startDate' dataField='startDate' dataSort={true} width="100" editable={false}>Start date</TableHeaderColumn>
					<TableHeaderColumn ref='endDate' dataField='endDate' dataSort={true} width="100" editable={false}>End date</TableHeaderColumn>
				</BootstrapTable>
				<div id="loaderOPCO1" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
			</div>
		);
	}
}
