import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import NavBar1 from '../admin welcome/navbar.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import img1 from './loading.gif';

const audit1 = "getUserAudit";

class AuditLogs1 extends React.Component {
	constructor(props) {
		super(props)
		this.state = { data: [] };
		this.group = this.props.history.location.state.group;
		this.name = this.props.history.location.state.data;
		this.getUserAudit = this.getUserAudit.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	componentWillMount() {
		var comp = this;
		$("#loaderUA").show();
		comp.getUserAudit(false);
	}

	getUserAudit(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + audit1,
			'contentType': 'application/json',
			'dataType': 'json',

		})
			.done(function (data) {
				$("#loaderUA").hide();
				for (var el in data.rows) {
					comp.state.data.push({
						userGroup: data.rows[el].userGroup,
						mailId: data.rows[el].mailId,
						brokerNumber: data.rows[el].brokerNumber,
						brokerName: data.rows[el].brokerName,
						brokerCode: data.rows[el].brokerCode,
						contactPersonName: data.rows[el].contactPersonName,
						phoneNumber: data.rows[el].phoneNumber,
						vendorNumber: data.rows[el].vendorNumber,
						opcoId: data.rows[el].opcoId,
						auditDate: data.rows[el].auditDate,
						auditType: data.rows[el].auditType,
						doneBy: data.rows[el].doneBy
					})
				}
				comp.setState({ data: comp.state.data });

			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getUserAudit(true);
				}
				else
					$("#loaderUA").hide();
			});
	}


	render() {
		const options = {
			sizePerPage: 14,
			noDataText: ' '
		}

		return <div>
			<NavBar1 name={this.name} group={this.group} /> <br />
			<br />
			<div className="row tab">
				<h1 className="headings" style={{ textAlign: "left" }}>User Audits</h1>
				<div className="au-lo" style={{ overflowX: "auto" }}>
					<BootstrapTable ref='table' data={this.state.data} options={options} pagination style={{ width: "10px", overflowX: 'scroll' }} search={true} striped hover condensed>
						<TableHeaderColumn ref='broker_name' dataField='contactPersonName' dataSort={true} width='100' >Name</TableHeaderColumn>
						<TableHeaderColumn ref='broker' dataField='brokerCode' isKey={true} dataSort={true} width='120'>Broker code</TableHeaderColumn>
						<TableHeaderColumn ref='broker_number' dataField='brokerNumber' dataSort={true} width='100' >Broker No</TableHeaderColumn>
						<TableHeaderColumn ref='broker_name' dataField='brokerName' dataSort={true} width='120' >Broker Name</TableHeaderColumn>
						<TableHeaderColumn ref='broker_name' dataField='vendorNumber' dataSort={true} width='120' >Vendor No </TableHeaderColumn>
						<TableHeaderColumn ref='broker_name' dataField='opcoId' dataSort={true} width='100' >OPCO Id</TableHeaderColumn>
						<TableHeaderColumn ref='broker_name' dataField='userGroup' dataSort={true} width='120' >User Group</TableHeaderColumn>
						<TableHeaderColumn ref='emailId' dataField='mailId' dataSort={true} width='180'>Email Id</TableHeaderColumn>
						<TableHeaderColumn ref='contactno' dataField='phoneNumber' dataSort={true} width='100'>Contact no</TableHeaderColumn>
						<TableHeaderColumn ref='audit_type' dataField='auditType' dataSort={true} width='130'>Type of Audits</TableHeaderColumn>
						<TableHeaderColumn ref='audit_date' dataField='auditDate' dataSort={true} width='130'>Audit Date</TableHeaderColumn>
						<TableHeaderColumn ref='doneBy' dataField='doneBy' dataSort={true} width='100'>Done By</TableHeaderColumn>
					</BootstrapTable>
					<div id="loaderUA" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>
				</div>
			</div>
		</div>
	}
}

export default withAuth(AuditLogs1);
