import $ from 'jquery';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ReactGA from 'react-ga';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import AuthService from './AuthService';
import img1 from './login_logo.jpg';

const loginURL = 'getLogin';
var comp;

class Login extends React.Component {
  constructor(props) {

    super(props);
    this.Auth = new AuthService();
    comp = this;
    this.reset = this.reset.bind(this);
    this.regdUser = this.regdUser.bind(this);
    this.state = { border: "none", borderBottom: '1px solid gray', data: { username: "", password: "", expirationTime: "0" } };
  };

  componentDidMount() {
    //initGA();
    //PageView();
    //GetToken();
    try {
      let username = this.Auth.login();
      let newData = this.state.data;
      newData.username = username;
      this.setState({ data: newData });
      comp.regdUser(false);
    } catch (e) {
      console.log(e);
    }

  }

  reset() {
    this.props.history.push("/suite/vbt/reset");
  }

  regdUser(didFail) {
    var param = {
      "userName": this.Auth.getUsername()
    }
    if (this.Auth.isEmail()) {
      param.email = 'Y';
    }

    console.log('process.env--------------------------------------------&&&');
    console.log(process.env);

    $.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem(ID_TOKEN)
      },
      crossDomain: true,

      'type': 'POST',
      'url': URL.url + loginURL,
      'contentType': 'application/json',
      'dataType': 'json',
      'data': JSON.stringify(param)

    })
      .done(function (data) {
        $("#login").prop('disabled', false);
        if (data.result == true) {
          localStorage.setItem("userId", comp.state.data.username)
          if (data.rows[0].userGroup.toLowerCase() == "admin") {
            ReactGA.event({
              category: 'Login',
              action: 'Admin logged in'
            });
            comp.props.history.push({
              pathname: '/suite/vbt/admin',
              state: {
                data: data.rows[0].contactPersonName,
                phoneNumber: data.rows[0].contactPersonName,
                currentUser: data.rows[0].userName,
                mailId: data.rows[0].mailId,
                group: "admin"
              }
            })
          }
          else if (data.rows[0].userGroup.toLowerCase() == "broker") {
            ReactGA.event({
              category: 'Login',
              action: 'Broker logged in'
            });
            comp.props.history.push({
              pathname: '/suite/vbt/broker',
              state: {
                brokerType: data.rows[0].brokerType,
                data: data.rows[0].contactPersonName,
                brokerNumber: data.rows[0].brokerNumber,
                mailId: data.rows[0].mailId,
                brokerCode: data.rows[0].brokerCode,
                brokerName: data.rows[0].brokerName,
                currentUser: data.rows[0].userName,
                group: "user"
              }
            })
          } else if (data.rows[0].userGroup.toLowerCase() == "vendor") {

            comp.props.history.push({
              pathname: '/suite/vbt/vendor',
              state: {
                contactPersonName: data.rows[0].contactPersonName,
                vendorNumber: data.rows[0].vendorNumber,
                group: "viewer",
                mailId: data.rows[0].mailId,
                currentUser: data.rows[0].userName,
                phoneNumber: data.rows[0].phoneNumber
              }
            })

          } else if (data.rows[0].userGroup.toLowerCase() === "opco") {
            comp.props.history.push({
              pathname: '/suite/vbt/opcoviewer',
              state: {
                contactPersonName: data.rows[0].contactPersonName,
                opcoId: data.rows[0].opcoId,
                mailId: data.rows[0].mailId,
                phoneNumber: data.rows[0].phoneNumber,
                userName: data.rows[0].userName,
                group: "opcoviewer"
              }
            })
          } else if (data.rows[0].userGroup.toLowerCase() == "cm") {
            comp.props.history.push({
              pathname: '/suite/vbt/corporate',
              state: {
                data: data.rows[0].contactPersonName,
                phoneNumber: data.rows[0].contactPersonName,
                mailId: data.rows[0].mailId,
                group: "cm"
              }
            })
          }
        } else {
          window.alert(`${param.userName} not registered!`);
        }
        $("#login").prop('disabled', false);
      }).fail(function (jqXhr) {
        if (!didFail && jqXhr.status == 401) {
          GetToken();
          comp.regdUser(true);
        }
        else
          $("#login").prop('disabled', false);
      });
  }

  loginInternal = () => {
    window.location.href = '/?user_type=associate';
  }

  loginExternal = () => {
    window.location.href = '/?user_type=supplier';
  }

  render() {

    let loginButtons = <div style={{ textAlign: 'center' }}>
      <div onClick={this.loginExternal} className="mainlogin">Login</div>
      <div>Sysco Colleague? <span onClick={this.loginInternal} className="syscologin">Login here</span></div>
    </div>;
    let signingSpan = <span style={{ fontSize: '22px' }}>Signing in...</span>;
    let loadingSpan = <span style={{ fontSize: '22px' }}>Loading...</span>;

    let innerContent = null;
    if (window.location.search.includes('user_type')) {
      innerContent = signingSpan;
    } else if (window.location.hash && window.location.hash.match(/id_token/)) {
      innerContent = loadingSpan;
    } else if (this.Auth.loggedIn()) {
      innerContent = loadingSpan;
    } else {
      innerContent = loginButtons;
    }

    return (
      <div className="row">
        <div className="rlogin" >{innerContent}</div>
      </div>
    )

  }
}

export default Login;