import React from 'react';
import NavBar1 from '../admin welcome/navbar.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import DRTable from './Duplicate_report_table.jsx';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import $ from 'jquery';
import URL from '../url.js';

const insertStatusURL = 'insertDuplicateReportStatus';
class DuplicateReport extends React.Component {
	constructor(props) {
		super(props);
		this.group = this.props.history.location.state.group;
		this.state = { broker: { brokerCode: '', brokerNumber: '', brokerName: '', mailId: '' } }
		this.name = this.props.history.location.state.data;
		this.currentUser = this.props.history.location.state.currentUser;

		this.viewDuplicateStatus = this.viewDuplicateStatus.bind(this);
		this.insertDuplicateReportStatus = this.insertDuplicateReportStatus.bind(this);
	}
	componentWillMount() {
		if (this.group == "user") {
			this.state.broker.brokerName = this.props.history.location.state.brokerName;
			this.state.broker.brokerNumber = this.props.history.location.state.brokerNumber;
			this.state.broker.brokerCode = this.props.history.location.state.brokerCode;
			this.state.broker.mailId = this.props.history.location.state.mailId;
			this.setState({ broker: this.state.broker })
		}
	}

	componentDidMount() {
		initGA();
		PageView();

		console.log("brokerNumber is")
		console.log(this.state.broker.brokerNumber)
	}

	viewDuplicateStatus = () => {
		this.props.history.push({ pathname: '/suite/vbt/view_duplicate_download_status', state: { data: this.name, group: this.group, mailId: this.state.broker.mailId, brokerNumber: this.state.broker.brokerNumber, brokerCode: this.state.broker.brokerCode, brokerName: this.state.broker.brokerName, currentUser: this.currentUser, brokerType: this.brokerType } })
	}

	downloadReport = () => {
		var comp = this;
		var data = {
			"userName": comp.currentUser,
			"userGroup": comp.group
		}
		comp.insertDuplicateReportStatus(data, false);
		if (window.confirm("Your Report will be available for download within few moments")) {
			this.viewDuplicateStatus();
		}
	}

	insertDuplicateReportStatus(userDetails, didFail) {
		const data = {
			...userDetails,			
			userName: localStorage.getItem("userId")
		}
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + insertStatusURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(data),
		})
			.done(function (data) {
				//console.log("downloaded")
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.insertDuplicateReportStatus(data, true);
				}
				else
					$("#edit").prop('disabled', false);
			});
	}


	render() {
		return <div className="container-fluid">
			<NavBar1 name={this.name} group={this.group} brokerNumber={this.state.broker.brokerNumber} brokerCode={this.state.broker.brokerCode} brokerName={this.state.broker.brokerName} mailId={this.state.broker.mailId} currentUser={this.currentUser} /> <br />
			<h1 className="headings" style={{ textAlign: "left" }}>Duplicate Broker-Vendor Tie Report</h1>
			<div className="row">
					<div className="btn-group col-md-4" role="group" ariaLabel="First group">
						<button type="button" className="btn btn-primary" onClick={this.downloadReport}>Download Report</button>
						<button type="button" className="btn btn-primary" onClick={this.viewDuplicateStatus}>View download status</button>
					</div>
			</div>
			<div> <DRTable name={this.name} group={this.group} brokerNumber={this.state.broker.brokerNumber} brokerCode={this.state.broker.brokerCode} brokerName={this.state.broker.brokerName} mailId={this.state.broker.mailId} /> </div>


		</div>

	}

}
export default withAuth(DuplicateReport);