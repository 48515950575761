import { Button, Col, Icon, Input, message, Modal, Row, Select, Table } from 'antd';
import $ from 'jquery';
import _ from "lodash";
import React, { Component } from 'react';
import Navbar1 from '../admin welcome/navbar';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import OpcoId from '../generalUse/OpcoIdFilter';
import URL from '../url';
import './BrokerPoc.css';
import ExportCSVButton from './ExportPOCToCSVButton';
import PocModalForm from './PocModalForm/PocModalForm';

const Option = Select.Option;
const corpBrokerList = "getBrokerNumbersOfCompany";
const corpUserList = "getUsersForBrokerNumber";

var loggedUserName;
var groupName;
class BrokerPoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formInformation: {
                opcoId: '',
                pocName: '',
                title: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                phone: '',
                fax: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email: '',
                loggedUser: '',
                createdUser: '',
                updatedUser: '',
                userName: (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "" : this.props.history.location.state.currentUser,
                brokerName: (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "" : this.props.history.location.state.name,
                brokerNumber: (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "" : this.props.history.location.state.brokerNumber,
            },
            brokerNumber: this.props.history.location.state.brokerNumber,
            userName: "All",
            formValidation: {
                pocName: '',
                title: '',
                phone: '',
                fax: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email: '',
            },

            searchCriteria: {
                opcoId: "",
                pocName: "",
                brokerNumber: this.props.history.location.state.brokerNumber
            },

            selectedRowKeys: [],
            selectedRows: [],
            dataSource: [],
            adminList: [],
            adminUserList: [],
            adminUserListValAdd: [],
            adminListForAdd: [],
            userNameAdd: "",
            opcos: [],
            filterData: [],
            csvFormattedData: [],
            opcoIdFilter: "",
            pocNameFilter: "",

            updateModalOpen: false,
            addModalOpen: false,
            opcoidModalOpen: false,

            brokerType: this.props.history.location.state.brokerType,
            name: this.props.history.location.state.name,
            brokerName: this.props.history.location.state.brokerName,
            group: this.props.history.location.state.group,
            currentBroker: this.props.history.location.state.brokerNumber,
            currentUser: this.props.history.location.state.currentUser
        }
        this.currentUser = this.props.history.location.state.currentUser;
    }


    componentWillMount = () => {
        this.retrieveAllPoc();
        this.retrieveOpcos();
        this.brokerList();
    }
    componentDidMount = () => {
        initGA();
        PageView();

        //console.log("window.props.history.location.state.brokerNumber is",this.state.formInformation.brokerNumber)
        console.log("current broker is", this.state.currentBroker)
        console.log("logged in person is", this.props.history.location.state.name)
        loggedUserName = this.props.history.location.state.name;
        console.log("this.group is", this.props.history.location.state.group)
        groupName = this.props.history.location.state.group;
        console.log("this.props.history.location.state is", this.props.history.location.state)
        if (this.props.history.location.state.group === "user") {
            this.props.history.push({
                state: { brokerNumber: this.state.currentBroker, group: this.props.history.location.state.group, name: this.props.history.location.state.name, brokerType: this.props.history.location.state.brokerType, brokerCode: this.props.history.location.state.brokerCode, currentUser: this.props.history.location.state.currentUser }
            })
        }


    }

    brokerList = () => {
        var comp = this;
        if (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") {
            let param1 = {
                "brokerCode": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.code : this.props.history.location.state.brokerCode,
                "branch": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.branch : "corporate",
                "brokerNumber": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber : this.props.history.location.state.brokerNUmber
            }
            comp.getBrokerNumbersOfCompany(param1, false);
        }
    }

    getBrokerNumbersOfCompany(param, didFail) {
        var comp = this;
        var adminListVal = []
        var adminListForAdd = []
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            beforeSend: function () {
                $("#loader").show();
            },
            'type': 'POST',
            'url': URL.url + corpBrokerList,
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),
            complete: function (data) {
                $("#loader").hide();
            }
        })
            .done(data1 => {
                if (data1.rows == null) {
                    adminListVal.push(<Option key={this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber : this.props.history.location.state.brokerNumber}>{this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber + " - " + this.props.history.location.state.row.name : this.props.history.location.state.brokerNumber + " - " + this.props.history.location.state.brokerName}</Option>)
                    adminListForAdd.push(<Option key={this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber + "_" + this.props.history.location.state.row.name : this.props.history.location.state.brokerNumber + "_" + this.props.history.location.state.brokerName}>{this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber + " - " + this.props.history.location.state.row.name : this.props.history.location.state.brokerNumber + " - " + this.props.history.location.state.brokerName}</Option>)
                }
                else {
                    adminListVal.push(<Option key={"All"}>All</Option>);
                }
                for (var e in data1.rows) {
                    adminListVal.push(<Option key={data1.rows[e].brokerNumber}>{data1.rows[e].brokerNumber + " - " + data1.rows[e].brokerName}</Option>)
                    adminListForAdd.push(<Option key={data1.rows[e].brokerNumber + "_" + data1.rows[e].brokerName}>{data1.rows[e].brokerNumber + " - " + data1.rows[e].brokerName}</Option>)
                }
                this.setState({ adminList: adminListVal })
                this.setState({ adminListForAdd: adminListForAdd })
                this.setState({ brokerNumber: "All" })
            })
            .fail(function (jqXhr) {
                if (!didFail && jqXhr.status == 401) {
                    GetToken();
                    comp.getBrokerNumbersOfCompany(param, true);
                }
            });
    }

    retrieveAllPoc = () => {
        var comp = this;
        comp.viewPOC(false);
    }

    viewPOC(didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + 'viewPOC',
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify({
                'userName': (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.userName : this.state.currentUser,
                'brokerType': (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "Broker Corporate" : "",
                'brokerCode': (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.props.history.location.state.brokerCode : ""
            })
        }).done(data => {
            var rows = [];
            var response = data.rows
            _.forEach(response, (i) => {
                i.key = response.indexOf(i);

                //Setting the opco name for each opco id
                this.state.opcos.forEach((opco) => {
                    if (opco.opcoNumber.includes(i.opcoId))
                        i.opcoName = opco.opcoNumber.substring(4);
                })

                //Destructuring response to extract a subset of fields
                var { opcoId, opcoName, pocName, title, phone, email, fax,
                    addressLine1, addressLine2, addressLine3, addressLine4,
                    city, state, zip, country, brokerName, brokerNumber, userName } = i;

                var rowData = {
                    opcoId,
                    opcoName,
                    pocName,
                    title,
                    phone,
                    email,
                    fax,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    addressLine4,
                    city,
                    state,
                    zip,
                    country
                }
                var rowData1 = {
                    brokerName,
                    brokerNumber,
                    userName,
                    opcoId,
                    opcoName,
                    pocName,
                    title,
                    phone,
                    email,
                    fax,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    addressLine4,
                    city,
                    state,
                    zip,
                    country
                }

                //Adding dashes to the phone number for readability
                rowData.phone = rowData.phone.slice(0, 3) + "-" + rowData.phone.slice(3, 6) + "-" + rowData.phone.slice(6);
                i.phone = rowData.phone;
                rowData1.phone = rowData1.phone.slice(0, 3) + "-" + rowData1.phone.slice(3, 6) + "-" + rowData1.phone.slice(6);
                i.phone = rowData1.phone;
                (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? rows.push(rowData1) : rows.push(rowData);
            });
            this.setState({ dataSource: response, filterData: response, csvFormattedData: rows }, () => { console.log("records after adding keys are", this.state.dataSource) });
        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                comp.viewPOC(true);
            }
            else
                message.error(<span className="errorMsg">"Something went wrong!"</span>)
        })
    }

    onAddPoc = () => {
        let param = this.state.formInformation;
        param.loggedUser = loggedUserName;
        param.createdUser = loggedUserName;
        param.brokerNumber = (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.brokerNumberAdd : this.state.brokerNumber;
        param.userName = (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.userNameAdd : this.state.currentUser;
        console.log("param", this.state.formInformation)

        if (param.opcoId === "" || param.opcoId === "Select opco" || param.pocName === "" || param.title === "" || param.phone === "" || param.email === "") {
            //if manadatory fields are filled out
            message.error(<span className="errorMsg">"Please enter all the mandatory fields"</span>);
        }
        // else if(((this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType =="Broker Corporate") && (this.state.userName == "" || this.state.userName == "All"))){
        //     message.error(<span className="errorMsg">"Please select a user"</span>);
        // }
        else {
            if (this.validateFormInput1()) {
                var comp = this;
                comp.addPOC(param, false);
            }
        }
    }

    addPOC(param, didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + 'addPOC',
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),
        }).done(data => {
            if (data.result) {
                this.retrieveAllPoc();
                this.onCloseModal();
                message.success(<span className="errorMsg">{data.message}</span>)
            }
            else {
                console.log("entered else of add")
                message.error(<span className="errorMsg">{data.message}</span>);
            }
            console.log("data", data);
        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                comp.addPOC(param, true);
            }
            else
                message.error(<span className="errorMsg">"Something went wrong, please try again later."</span>)
        })
    }

    updatePoc() {
        var param1 = this.state.formInformation;
        param1.loggedUser = loggedUserName;
        param1.updatedUser = loggedUserName;
        param1.userName = (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.formInformation.userName : this.state.currentUser;
        console.log("param is", param1)
        if (param1.opcoId === "" || param1.opcoId === "Select opco" || param1.pocName === "" || param1.title === "" || param1.phone === "" || param1.email === "") {
            message.error(<span className="errorMsg">"Please enter all the mandatory fields"</span>);
        }
        else {
            if (this.validateFormInput1()) {
                var comp = this;
                comp.updatePOC(param1, false);
            }
        }
    }

    updatePOC(param, didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + 'updatePOC',
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),
        })
            .done(data => {
                if (data.result) {
                    message.success(<span className="errorMsg">{data.message}</span>)
                    this.retrieveAllPoc();
                    this.onCloseModal();
                } else {
                    message.error(<span className="errorMsg">{data.message}</span>)
                }
            })
            .fail(data => {
                if (!didFail && data.status == 401) {
                    GetToken();
                    comp.updatePOC(param, true);
                }
            });
    }

    onDeletePoc = () => {

        let param = this.state.selectedRows;
        console.log("delete param is", param)
        if (param.length !== 0) {
            message.success(<span className="errorMsg">"The selected rows will be deleted"</span>);

        }
        else {
            message.error(<span className="errorMsg">"Select atleast one row"</span>);
        }
        //var inputparam=[];
        for (let i in param) {
            console.log("i is", i)
            param[i].loggedUser = loggedUserName;
            if (this.props.history.location.state.group !== "admin" || (this.props.history.location.state.brokerType !== "Broker Corporate" && this.props.history.location.state.brokerType)) {
                param[i].userName = this.currentUser;
            }
        }
        let param1 = param;
        this.deletePOC(param1, false);
    }

    deletePOC(param, didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + "deletePOC",
            'contentType': 'application/json',
            //'dataType': 'json',
            'data': JSON.stringify(param)

        })
            .done(data => {
                window.location.reload(true);
                this.retrieveAllPoc();
                this.setState({
                    selectedRows: [],
                    selectedRowKeys: []
                });
            })
            .fail(data => {
                if (!didFail && data.status == 401) {
                    GetToken()
                    comp.deletePOC(param, true);
                }
                else {
                    message.error(<span className="errorMsg">"Failed to delete Poc"</span>);
                    this.retrieveAllPoc();
                }
            });
    }

    retrieveOpcos = () => {
        this.opcoNumberSearch(false);
    }

    opcoNumberSearch(didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + "opcoNumbersSearch",
            'contentType': 'application/json',
            'dataType': 'json',
        }).done(data => {
            console.log(data.rows);
            this.setState({ opcos: data.rows })
        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                this.opcoNumberSearch(true);
            }
        })
    }

    onSearch = () => {
        let param = this.state.searchCriteria;

        param.opcoId = param.opcoId.trim();
        param.pocName = param.pocName.trim();
        param.addressLine1 = "string";
        param.addressLine2 = "string";
        param.addressLine3 = "string";
        param.addressLine4 = "string";
        param.brokerName = "string";
        param.city = "string";
        param.country = "string";
        param.createdDate = "string";
        param.createdUser = "string";
        param.email = "string";
        param.fax = "string";
        param.loggedUser = "string";
        param.opcoName = "string";
        param.phone = "string";
        param.processFlag = "string";
        param.state = "string";
        param.title = "string";
        param.updatedDate = "string";
        param.updatedUser = "string";
        param.userName = (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.userName : this.state.currentUser;
        param.zip = "string";
        param.brokerNumber = (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? this.state.brokerNumber : this.props.history.location.state.brokerNumber;


        //if there is nothing in the search, retrieve all points of contact
        if (param.opcoId === "" && param.pocName === "" && param.userName === "All" && param.brokerNumber === "All") {
            console.log(param)
            this.retrieveAllPoc();
        }
        else {
            // if(param.opcoid === ""){
            //     param.opcoid = "ibt.OPCO_ID"
            // }
            this.searchPOC(param, false);
        }
    }

    searchPOC(param, didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + "searchPOC",
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param)
        }).done(data => {
            var rows = [];
            var response = data.rows
            _.forEach(response, (i1) => {
                i1.key = response.indexOf(i1);

                //Setting the opco name for each opco id
                this.state.opcos.forEach((opco) => {
                    if (opco.opcoNumber.includes(i1.opcoId))
                        i1.opcoName = opco.opcoNumber.substring(4);
                })

                //Destructuring response to extract a subset of fields
                var { opcoId, opcoName, pocName, title, phone, email, fax,
                    addressLine1, addressLine2, addressLine3, addressLine4,
                    city, state, zip, country, brokerName, brokerNumber, userName } = i1;


                var rowData1 = {
                    brokerName,
                    brokerNumber,
                    userName,
                    opcoId,
                    opcoName,
                    pocName,
                    title,
                    phone,
                    email,
                    fax,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    addressLine4,
                    city,
                    state,
                    zip,
                    country,
                }

                var rowData = {
                    opcoId,
                    opcoName,
                    pocName,
                    title,
                    phone,
                    email,
                    fax,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    addressLine4,
                    city,
                    state,
                    zip,
                    country,
                }

                //Adding dashes to the phone number for readability
                rowData.phone = rowData.phone.slice(0, 3) + "-" + rowData.phone.slice(3, 6) + "-" + rowData.phone.slice(6);
                i1.phone = rowData.phone;
                rowData1.phone = rowData1.phone.slice(0, 3) + "-" + rowData1.phone.slice(3, 6) + "-" + rowData1.phone.slice(6);
                i1.phone = rowData1.phone;
                (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? rows.push(rowData1) : rows.push(rowData);
            });
            this.setState({
                csvFormattedData: rows,
                dataSource: data.rows,
                filterData: data.rows
            })
        }).fail(data => {
            if (!didFail && data.status == 401) {
                GetToken();
                comp.searchPOC(param, true);
            }
        })
    }

    onClickRow = (record, rowIndex) => {
        this.setState({
            updateModalOpen: true,
            formInformation: record
        });
    }

    onCloseModal = () => {
        this.setState(
            {
                updateModalOpen: false,
                addModalOpen: false,
                opcoidModalOpen: false,
                userNameAdd: "",
                brokerNumberAdd: "",
                formInformation: {
                    opcoId: '',
                    pocName: '',
                    title: '',
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    addressLine4: '',
                    phone: '',
                    fax: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    email: '',
                    userName: (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "" : this.props.history.location.state.currentUser,
                    brokerName: (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "" : this.props.history.location.state.name,
                    brokerNumber: (this.props.history.location.state.group == "admin" || this.props.history.location.state.brokerType == "Broker Corporate") ? "" : this.props.history.location.state.brokerNumber,
                },
                formValidation: {
                    pocName: '',
                    title: '',
                    phone: '',
                    fax: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    email: '',
                }
            }
        )
    }

    openAddModal = () => {
        this.setState({ addModalOpen: true })
    }

    openOpcoidModal = () => {
        this.setState({ opcoidModalOpen: true })
    }

    closeOpecoidModal = () => {
        this.setState({ opcoidModalOpen: false })
    }

    //handle input for modal form
    handleInput = (event) => {
        this.setState({
            formInformation: {
                ...this.state.formInformation,
                [event.target.id]: event.target.value
            }
        });
    }

    //handle input for search criteria
    handleSearchInput = (event) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [event.target.id]: event.target.value
            }
        })
    }

    onSetSearchOpcoId = (opcoIds) => {
        console.log(opcoIds)
        this.setState({
            opcoidModalOpen: false,
            searchCriteria: {
                ...this.state.searchCriteria,
                opcoId: opcoIds
            }
        })
    }

    handleFilterInput = (event, dataIndex) => {
        this.setState({
            [dataIndex]: event.target.value
        }, this.handleFilter)
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log("selectedRowKeys are", selectedRowKeys)

        //console.log("selectedRows are",selectedRows)
        this.setState({
            selectedRowKeys,
            selectedRows: selectedRows
        });
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder={'Search ' + dataIndex}
                        onChange={(event) => this.handleFilterInput(event, dataIndex + "Filter")}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={this.getFilterState(dataIndex)}
                    />
                    <Button
                        onClick={() => this.onResetFilter(dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
            </Button>
                </div>
            ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    })

    //this is used to retrieve the value of the input element in getColumnSearchProps (because we are only rendering one Input element)
    getFilterState = (dataIndex) => {
        switch (dataIndex) {
            case "opcoId":
                return this.state.opcoIdFilter;
            case "pocName":
                return this.state.pocNameFilter
            default:
                return "empty";
        }
    }

    handleFilter = () => {
        let data = this.state.dataSource;
        let opcoIdFilter = this.state.opcoIdFilter;
        let pocNameFilter = this.state.pocNameFilter.toUpperCase();

        if (opcoIdFilter !== null && opcoIdFilter.length > 0) {
            data = _.filter(data, data => {
                if (data.opcoId != null) {
                    return data.opcoId.toString().includes(opcoIdFilter);
                } else {
                    return false;
                }
            });
        }

        if (pocNameFilter !== null && pocNameFilter.length > 0) {
            data = _.filter(data, data => {
                if (data.pocName != null) {
                    return data.pocName.toString().toUpperCase().includes(pocNameFilter);
                } else {
                    return false;
                }
            });
        }

        this.setState({ filterData: data });
    }

    resetAllFilters = () => {
        this.setState({
            opcoIdFilter: "",
            numberFilter: "",
            filterData: this.state.dataSource
        });
    }

    onResetFilter = (dataIndex) => {
        switch (dataIndex) {
            case "opcoId":
                this.setState({ opcoIdFilter: "" }, this.handleFilter);
                break;
            case "pocName":
                this.setState({ pocNameFilter: "" }, this.handleFilter);
                break;
            default:
                break;
        }
    }
    handleUserNameChange = (e) => {
        this.setState({ userName: e })
    }

    handleuserNameChangeForAdd = (e) => {
        this.setState({
            formInformation: {
                ...this.state.formInformation,
                ["userName"]: e
            }
        });
        this.setState({ userNameAdd: e })
    }

    handleBrokerNameChangeForAdd = (e) => {
        let numberAdded = e.replace(/^'|'$/g, "").split(/\s*\_\s*/g)
        this.setState({
            formInformation: {
                ...this.state.formInformation,
                ["brokerNumber"]: numberAdded[0],
                ["brokerName"]: numberAdded[1],
                ["userName"]: "Select"
            }
        });
        this.setState({ brokerNumberAdd: numberAdded[0] })
        // var dataCopy = this.state.data;
        // dataCopy.brokerNumber = e;
        // this.setState({data: dataCopy});
        let param1 = {
            "brokerCode": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.code : this.props.history.location.state.brokerCode,
            "brokerNumber": numberAdded[0]
        }
        this.getUsersForBrokerNumber(param1, false);
    }

    getUsersForBrokerNumber(param, didFail) {
        var comp = this;
        var adminUserListValAdd = [];
        $.ajax({
            crossDomain: true,
            headers: {
                
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
            },
            beforeSend: function () {
                $("#loader").show();
            },
            'contentType': 'application/json',
            'dataType': 'json',
            'type': 'POST',
            'url': URL.url + corpUserList,
            'data': JSON.stringify(param),
            complete: function (data) {
                $("#loader").hide();
            }
        })
            .done(data1 => {
                adminUserListValAdd.splice(0,);
                for (var e in data1.rows) {
                    adminUserListValAdd.push(<Option key={data1.rows[e].userName}>{data1.rows[e].userName + '-' + data1.rows[e].contactPersonName}</Option>)
                }
                this.setState({ adminUserListValAdd: adminUserListValAdd })
            })
            .fail(function (jqXhr) {
                if (!didFail && jqXhr.status == 401) {
                    GetToken();
                    comp.getUsersForBrokerNumber(param, true);
                }
            });
    }

    handleBrokerNameChange = (e) => {
        this.setState({ adminUserList: "", userName: "All" })
        this.setState({ brokerNumber: e })

        let param1 = {
            "brokerCode": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.code : this.props.history.location.state.brokerCode,
            "brokerNumber": e
        }
        this.getUsersForBrokerNumber1(param1, false);
    }

    getUsersForBrokerNumber1(param, didFail) {
        var comp = this;
        var adminUserListVal = [];
        $.ajax({
            beforeSend: function () {
                $("#loader").show();
            },
            headers: {
                'authorization': localStorage.getItem(ID_TOKEN),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
            },
            crossDomain: true,
            complete: function (data) {
                $("#loader").hide();
            },
            'type': 'POST',
            'url': URL.url + corpUserList,
            'contentType': 'application/json',
            'dataType': 'json',
            'data': JSON.stringify(param),

        })
            .done(data1 => {
                adminUserListVal.splice(0,);
                adminUserListVal.push(<Option key={"All"}>All</Option>);
                for (var e in data1.rows) {
                    adminUserListVal.push(<Option key={data1.rows[e].userName}>{data1.rows[e].userName + '-' + data1.rows[e].contactPersonName}</Option>)
                }
                this.setState({ adminUserList: adminUserListVal })
            })
            .fail(function (jqXhr) {
                if (!didFail && jqXhr.status == 401) {
                    GetToken();
                    comp.getUsersForBrokerNumber1(param, true);
                }
            });
    }

    validateFormInput1 = () => {
        let onlyLetters = new RegExp('^[a-zA-Z ]+$');
        let stateRegex = new RegExp('^[A-Z]{2}$');
        let phoneRegex = new RegExp('^[0-9]{10}$');
        let faxRegex = new RegExp('^[0-9]{0,10}$');

        let zipRegex = new RegExp('^[0-9]{5}$');
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let isFormValid = true;
        let formInformation = this.state.formInformation;
        formInformation.phone = formInformation.phone.replace(/-/g, "");
        let formValidation = {
            pocName: '',
            title: '',
            phone: '',
            fax: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            email: '',
            brokerNumber: "",
            brokerName: "",
            userName: ""
        }

        if ((formInformation.pocName.trim().length === 0) || !onlyLetters.test(formInformation.pocName)) {
            formValidation.pocName = "Mandatory Field: Only Letters";
            isFormValid = false;
        }
        console.log(!onlyLetters.test(formInformation.title))
        if ((formInformation.title.trim().length === 0) || (!onlyLetters.test(formInformation.title))) {
            formValidation.title = "Mandatory Field: Only Letters";
            isFormValid = false;
        }
        if ((formInformation.phone.trim().length === 0) || !phoneRegex.test(formInformation.phone)) {
            formValidation.phone = "Mandatory Field: Numbers only, 10 digits required";
            isFormValid = false;
        }
        if ((formInformation.fax.trim().length !== 0) && !faxRegex.test(formInformation.fax)) {
            formValidation.fax = "Numbers only, 10 digits maximum";
            isFormValid = false;
        }
        if ((formInformation.zip.trim().length !== 0) && !zipRegex.test(formInformation.zip)) {
            formValidation.zip = "Numbers only, 5 digits required ";
            isFormValid = false;
        }
        if ((formInformation.state.trim().length !== 0) && !stateRegex.test(formInformation.state)) {
            formValidation.state = "Must abrreviate state (2 letter abbreviation)";
            isFormValid = false;

        }
        if ((formInformation.country.trim().length !== 0) && !onlyLetters.test(formInformation.country)) {
            formValidation.country = "Letters only, maximum of 20 letters";
            isFormValid = false;
        }
        if ((formInformation.email.trim().length === 0) || !emailRegex.test(formInformation.email)) {
            formValidation.email = "Mandatory Field: Must be in an email address format, ex: example@gmail.com";
            isFormValid = false;
        }
        if (this.props.history.location.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin") {
            if (formInformation.brokerName.trim().length === 0 || formInformation.brokerNumber.trim().length === 0) {
                formValidation.brokerData = "Select a broker number!";
                isFormValid = false;
            }
            if (formInformation.userName.trim().length === 0) {
                formValidation.userName = "Select a username!";
                isFormValid = false;
            }
        }
        this.setState({
            formValidation: formValidation
        })

        return isFormValid;
    }

    render() {
        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: this.onSelectChange

        };
        const columnsDetail = [
            {
                title: 'Broker Number',
                dataIndex: 'brokerNumber',
                key: 'brokerNumber',
                align: 'center',
            },
            {
                title: 'Broker Name',
                dataIndex: 'brokerName',
                width: 170,
                key: 'brokerName',
                align: 'center',
            },
            {
                title: 'Username',
                dataIndex: 'userName',
                key: 'userName',
                align: 'center',
            },
            {
                title: 'Opco #',
                align: 'center',
                dataIndex: 'opcoId',
                sorter: (a, b) => a.opcoId.localeCompare(b.opcoId),
                key: 'opcoId',


            },
            {
                title: 'Opco Name',
                dataIndex: 'opcoName',
                sorter: (a, b) => a.opcoName.localeCompare(b.opcoName),
                align: 'center',
                width: '150px',
                key: 'opcoName',


            },

            {
                title: 'Name',
                dataIndex: 'pocName',
                sorter: (a, b) => a.pocName.localeCompare(b.pocName),
                width: '200px',
                key: 'pocName',
                align: 'center',

            },
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'center',
                key: 'title',

            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                width: '200px',
                align: 'center',

            },
            {
                title: 'Phone',
                key: 'phone',
                dataIndex: 'phone',
                align: 'center'
            },
            {
                title: 'Address Line 1',
                key: 'addressLine1',
                dataIndex: 'addressLine1',
                align: 'center'

            },
            {
                title: 'Address Line 2',
                align: 'center',
                dataIndex: 'addressLine2',
                key: 'addressLine2',

            },
            {
                title: 'Address Line 3',
                dataIndex: 'addressLine3',
                align: 'center',
                key: 'addressLine3',

            },
            {
                title: 'Address Line 4',
                dataIndex: 'addressLine4',
                align: 'center',
                key: 'addressLine4',

            },

            {
                title: 'Fax',
                dataIndex: 'fax',
                align: 'center',
                key: 'fax',

            },
            {
                title: 'City',
                dataIndex: 'city',
                align: 'center',
                key: 'city',

            },
            {
                title: 'State',
                dataIndex: 'state',
                align: 'center',
                key: 'state',

            },
            {
                title: 'Zip',
                key: 'zip',
                dataIndex: 'zip',
                align: 'center'
            },
            {
                title: 'Country',
                dataIndex: 'country',
                align: 'center',
                key: 'country',


            }
        ]
        const columnsPOC = [
            {
                title: 'Opco #',
                dataIndex: 'opcoId',
                sorter: (a, b) => a.opcoId.localeCompare(b.opcoId),
                key: 'opcoId',
                align: 'center',
                // ...this.getColumnSearchProps('opcoid'),
            },
            {
                title: 'Opco Name',
                dataIndex: 'opcoName',
                sorter: (a, b) => a.opcoName.localeCompare(b.opcoName),
                key: 'opcoName',
                align: 'center',
                width: '150px'
                // ...this.getColumnSearchProps('opcoid'),
            },

            {
                title: 'Name',
                dataIndex: 'pocName',
                sorter: (a, b) => a.pocName.localeCompare(b.pocName),
                key: 'pocName',
                align: 'center',
                width: '200px'
                // ...this.getColumnSearchProps('pocName'),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                align: 'center'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                align: 'center',
                width: '200px'
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                align: 'center'
            },
            {
                title: 'Address Line 1',
                dataIndex: 'addressLine1',
                key: 'addressLine1',
                align: 'center'
            },
            {
                title: 'Address Line 2',
                dataIndex: 'addressLine2',
                key: 'addressLine2',
                align: 'center'
            },
            {
                title: 'Address Line 3',
                dataIndex: 'addressLine3',
                key: 'addressLine3',
                align: 'center'
            },
            {
                title: 'Address Line 4',
                dataIndex: 'addressLine4',
                key: 'addressLine4',
                align: 'center'
            },

            {
                title: 'Fax',
                dataIndex: 'fax',
                key: 'fax',
                align: 'center'
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                align: 'center'
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                align: 'center'
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                align: 'center'
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                align: 'center'
            }

        ]
        return (
            <div className="container-fluid">
                <Navbar1 name={this.state.name} group={this.state.group} brokerNumber={this.state.currentBroker} currentUser={this.state.currentUser} brokerName={this.state.brokerName} />
                <br />
                <h1 className='brokerPocTitle'>OPCO Point Of Contacts</h1>
                <h1 className='brokerPocName' style={{ marginBottom: '20px' }} >{this.state.brokerName}</h1>

                {this.props.history.location.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ?
                    <Row>
                        <Col xs={2}>
                            <label>Broker:</label>
                        </Col>
                        <Col xs={4}>
                            {/* <div className="col-md-8 col-sm-10 col-xs-10"> */}
                            <Select
                                style={{ width: "223px" }}
                                className="brokerName"
                                placeholder={this.state.brokerCode === "IB" ? "All" : "Select"}
                                onChange={this.handleBrokerNameChange}
                            >
                                {this.state.adminList}
                            </Select>
                            {/* </div> */}
                        </Col>
                        <Col xs={2} offset={2}>
                            <label>User:</label>
                        </Col>
                        <Col xs={4}>
                            <Select
                                style={{ width: "223px" }}
                                className="userName"
                                value={this.state.userName}
                                placeholder={this.state.brokerCode === "IB" ? "All" : "Select"}
                                onChange={this.handleUserNameChange}
                            >
                                {this.state.adminUserList}
                            </Select>
                        </Col>
                    </Row> : ""}

                <Row style={{ paddingTop: "10px" }}>
                    <Col xs={{ span: 2 }}>
                        <label>OpCo Id:</label>
                    </Col>
                    <Col xs={{ span: 4 }}>
                        <Input.Search
                            id="opcoId"
                            value={this.state.searchCriteria.opcoId}
                            onChange={this.handleSearchInput}
                            placeholder="input search text"
                            onSearch={value => this.openOpcoidModal()}
                            enterButton
                        />
                        <OpcoId
                            visible={this.state.opcoidModalOpen}
                            setOpcoId={this.onSetSearchOpcoId}
                            onCancel={this.closeOpecoidModal}
                        />
                    </Col>
                    <Col xs={{ offset: 2, span: 2 }}>
                        <label>Contact Name:</label>
                    </Col>
                    <Col xs={{ span: 4 }}>
                        <Input
                            id="pocName"
                            value={this.state.searchCriteria.pocName}
                            onChange={this.handleSearchInput}
                            placeholder="input search text"
                            enterButton
                        />
                    </Col>

                </Row>

                <Row>
                    <Col xs={{ span: 1 }}>
                        <button className=' btn btn-primary btn-lg searching'
                            onClick={() => this.onSearch()}>Search</button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 4 }}>
                        <div style={{ marginTop: '15px' }}>
                            <ExportCSVButton csvData={this.state.csvFormattedData} fileName={"OPCO POC Report"} showDropdownVal={this.props.history.location.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ? true : false} />
                        </div>
                    </Col>
                </Row>

                <Table
                    style={{ marginTop: '10px' }}
                    dataSource={this.state.filterData} columns={this.props.history.location.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ? columnsDetail : columnsPOC}
                    className="brokerPocTable"
                    scroll={{ x: "max-content" }}
                    rowSelection={rowSelection} pagination={{ pageSize: 8 }}
                    size='small'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => { this.onClickRow(record, rowIndex) }
                        };
                    }} />
                <Row>
                    <Col xs={{ span: 2 }}>
                        <button
                            className=' btn btn-block btn-danger btn-lg'
                            onClick={() => this.onDeletePoc()}>Delete</button>
                    </Col>
                    {/*<Col xs={{ offset: 1, span: 2 }}>
                        <button className=' btn btn-block btn-primary btn-lg'
                            onClick={() => this.resetAllFilters()}>Reset Filters</button>
                    </Col>*/}
                    <Col xs={{ offset: 1, span: 2 }}>
                        <button className=' btn btn-block btn-primary btn-lg'
                            onClick={() => this.openAddModal()}>Add</button>
                    </Col>
                </Row>
                <Modal
                    onOk={() => this.updatePoc()}
                    title="Update Poc"
                    visible={this.state.updateModalOpen}
                    width={700}
                    onCancel={() => this.onCloseModal()}>
                    <PocModalForm
                        opcos={this.state.opcos}
                        formInformation={this.state.formInformation}
                        formValidation={this.state.formValidation}
                        handleInput={this.handleInput}
                        disableOpcoid={true}
                    />
                </Modal>
                <Modal
                    onOk={() => this.onAddPoc()}
                    title="Add Poc"
                    visible={this.state.addModalOpen}
                    width={700}
                    onCancel={() => this.onCloseModal()}>
                    <PocModalForm
                        opcos={this.state.opcos}
                        formInformation={this.state.formInformation}
                        formValidation={this.state.formValidation}
                        handleInput={this.handleInput}
                        handleBrokerChange={this.handleBrokerNameChangeForAdd}
                        disableOpcoid={false}
                        adminList={this.state.adminListForAdd}
                        adminUserListValAdd={this.state.adminUserListValAdd}
                        handleUserChange={this.handleuserNameChangeForAdd}
                        brokerCode={this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.code : this.props.history.location.state.brokerCode}
                        showDropdown={this.props.history.location.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ? true : false}
                    />
                </Modal>
            </div>
        )
    }

}

export default BrokerPoc;