const trimSearchFieldValues = searchText => {
    let search = searchText.replace(/\s*,\s*/g, ',').split(",");
    search = search.filter((eachString) => {
        if (eachString.trim() !== '') {
            return eachString.trim();
        }
    });
    search = search.join("','").trim();
    search = `'${search}'`;
    return search;
}

module.exports = {
    trimSearchFieldValues
}