import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';

var comp;
var rowKeys = [];

const cmURL = 'viewCorporates';



class ViewCM extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.group = this.props.history.location.state.group;
        this.name = this.props.history.location.state.data;
        this.viewCorporates = this.viewCorporates.bind(this);
    }

    componentWillMount() {
        $("#loaderCM1").show();
        var comp2 = this;
        comp2.viewCorporates(false);
    }

    componentDidMount() {
        initGA();
        PageView();
    }

    viewCorporates(didFail) {
        var comp = this;
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(ID_TOKEN),
                
            },
            crossDomain: true,
            'type': 'POST',
            'url': URL.url + cmURL,
            'contentType': 'application/json',
            'dataType': 'json',

        })
            .done(function (data) {
                $("#loaderCM1").hide();
                for (var el in data.rows) {
                    comp.state.data.push({
                        "contactPersonName": data.rows[el].contactPersonName,
                        "userName": data.rows[el].userName,
                        "mailId": data.rows[el].mailId,
                        "phoneNumber": data.rows[el].phoneNumber,
                        "addedBy": data.rows[el].addedBy,
                        "userGroup": data.rows[el].userGroup,
                        "userId": data.rows[el].userId,
                    })
                    comp.setState({ data: comp.state.data });
                }


            })
            .fail(function (jqXhr) {
                if (!didFail && jqXhr.status == 401) {
                    GetToken();
                    comp.viewCorporates();
                }
                else {
                    $("#loaderCM1").hide();
                }
            });
    }
    render() {
        var comp = this;
        const options = {
            onRowClick: function (row) {
                if (comp.group == "admin") {
                    comp.props.history.push({
                        pathname: '/suite/vbt/editCM',
                        state: {
                            data: comp.name,
                            group: comp.group,
                            row: row
                        }
                    })
                }
            },
            noDataText: ' ',
            sizePerPage: 6
        };

        // 			const data1 = [
        //
        //
        // ]





        return (
            <div className="container-fluid">

                <NavBar1 name={this.name} group={this.group} />
                <br />
                <h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>Corporate Merchandiser Details</h1>


                <div style={{ overflowX: "auto" }}>
                    <BootstrapTable ref='table' data={this.state.data} pagination options={options} search={true} >
                        <TableHeaderColumn dataField='contactPersonName' dataSort={true} width="160" >Corporate Merchandiser Name
                                        </TableHeaderColumn>

                        <TableHeaderColumn dataField='mailId' isKey={true} width="180" dataSort={true}  >Email Id
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='phoneNumber' width="150" dataSort={true}  >Contact Number
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='userName' width="150" dataSort={true}  >User Name
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='addedBy' width="180" dataSort={true} hidden>Added By
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='userGroup' width="180" dataSort={true} hidden >Group
                                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='userId' width="180" dataSort={true} hidden >User Id
                                        </TableHeaderColumn>
                    </BootstrapTable>

                </div>
                <div id="loaderCM1" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>



            </div>

        )
    }



}


export default withAuth(ViewCM);