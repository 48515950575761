import React from 'react';
import NavBar1 from '../admin welcome/navbar.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import BVTable from './broker_vendor_table.jsx';

class VendorBroker extends React.Component {
	constructor(props) {
		super(props);
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.state = { brokerData: { brokerName: '', brokerCode: '', brokerNumber: '', mailId: '' }, vendor: { vendorNumber: '' }, opco: { opcoId: '' } };
		this.currentUser = this.props.history.location.state.currentUser;
		this.brokerType = this.props.history.location.state.brokerType;
	}

	componentWillMount() {
		if (this.group == "user") {
			console.log("entered here user")
			this.state.brokerData.brokerNumber = this.props.history.location.state.brokerNumber;
			this.state.brokerData.brokerCode = this.props.history.location.state.brokerCode;
			this.state.brokerData.brokerName = this.props.history.location.state.brokerName;
			this.state.brokerData.mailId = this.props.history.location.state.mailId;
			this.setState({ brokerData: this.state.brokerData })

		} else if (this.group == "viewer") {
			this.state.vendor.vendorNumber = this.props.history.location.state.vendorNumber;
			this.setState({ vendor: this.state.vendor })
		} else if (this.group == "opcoviewer") {
			this.state.opco.opcoId = this.props.history.location.state.opcoId;
			this.setState({ opco: this.state.opco })
		}

		console.log("bvr props", this.props);
		console.log("bvr state", this.state)
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	render() {
		return <div className="container-fluid">
			<NavBar1 name={this.name} group={this.group} brokerNumber={this.state.brokerData.brokerNumber} brokerCode={this.state.brokerData.brokerCode} brokerName={this.state.brokerData.brokerName} mailId={this.state.brokerData.mailId} vendorNumber={this.state.vendor.vendorNumber} opcoId={this.state.opco.opcoId} currentUser={this.currentUser} /> <br />
			<h1 className="headings" style={{ textAlign: "left" }}>Broker-Vendor Tie Report</h1>
			<div> <BVTable brokerType={this.brokerType} currentUser={this.currentUser} group={this.group} brokerNumber={this.state.brokerData.brokerNumber} brokerCode={this.state.brokerData.brokerCode} brokerName={this.state.brokerData.brokerName} mailId={this.state.brokerData.mailId} vendorNumber={this.state.vendor.vendorNumber} opcoId={this.state.opco.opcoId} /> </div>
		</div>

	}

}
export default withAuth(VendorBroker);
