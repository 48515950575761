import { Button, Input, message, Modal, Radio, Select, Tooltip } from 'antd';
import 'antd/dist/antd.min.css';
import 'antd/lib/date-picker/style/css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button1 from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { withRouter } from 'react-router-dom';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import Brand from './Brand.js';
import Cat from './Cat.jsx';
import ItemDescr from './itemdescr.jsx';
import img1 from './loading.gif';
import MarketFilter from './marketFilter';
import OpcoId from './opcoid.jsx';
import VendorName from './VendorName.jsx';
import { trimSearchFieldValues } from '../../util/util';

const Option = Select.Option;
const Search = Input.Search;

var itemId;
const RadioGroup = Radio.Group;
const brokerURL = 'Broker';
const supcURL = 'getSUPC';
const brandURL = 'getBrand';
const majorURL = 'getMajor';
const marketURL = 'getMarket';
const multiSearchUrl = 'brokerTieMultipleSearch';
const deleteTieUrl = 'deleteTies';
const commodityTieUrl = 'item-details/commodity-flags';
const corpBrokerList = "getBrokerNumbersOfCompany"
const corpUserList = "getUsersForBrokerNumber"
// const corpBrokerList="usersUnderBrokerCorporate"
//var itemId;
var vendorSearch = "ibt.VENDOR_NUMBER";
var marSearch = "MARKET";
var opcoSearch = "ibt.OPCO_ID";
var catSearch = "CATEGORY";
var majSearch = "MAJOR";
var userNameA = "ibt.USER_NAME";
var brandSearch = "BRAND";
var itemSearch = "ibt.ITEM_ID";
var brokerCode = "";
var brokerType = "Normal user"
var flag;

function disable() {
	document.getElementById("inputMarket").disabled = true;

	$('.opcoId').children().val("ALL")
	$('.market').children().val("ALL")
}
function enable() {
	$('.opcoId').children().val("")
	document.getElementById("inputMarket").disabled = false
}

function handleBrandChange(value) {
	var string = `${value}`;
	$(".brand").children().val(string)
}

function handleCatChange(value) {
	var string = `${value}`;
	$(".category").children().val(string)
}

function handleMakChange(value) {
	var string = `${value}`;
	$(".market").children().val(string);
}

function handleMajChange1(value) {
	var string = `${value}`;
	$(".major").children().val(string)
}

function handleuserNameChange(value) {
	comp.setState({ userNameSelected: value })
	var string = `${value}`;
	$(".userName").children().val(string)
}

class ItemTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: itemId,
			open: true,
			data: [],

		};

		this.getSUPC = this.getSUPC.bind(this);
		this.getUsersForBrokerNumber = this.getUsersForBrokerNumber.bind(this);
		this.handleBrokerNameChange = this.handleBrokerNameChange.bind(this);
	}

	close1 = () => {
		this.setState({ open: false });
		this.props.onUpdate(this.props.defaultValue);
	}

	componentWillMount() {
		var comp = this;

		var param1 = {
			"itemId": this.state.name
		}
		comp.getSUPC(param1, false);
	}

	handleBrokerNameChange(value) {
		comp.setState({ userNameSelected: "All" })
		comp.state.brokerNumber = value;
		comp.state.brokerNumberSelected = value;
		var param1 = {
			"brokerCode": comp.state.brokerCode,
			"brokerNumber": value
		}
		this.getUsersForBrokerNumber(param1, false);
	
	}
	
	getUsersForBrokerNumber(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			'type': 'POST',
			'url': URL.url + corpUserList,
			beforeSend: function () {
				$("#loader").show();
			},
			crossDomain: true,
			'data': JSON.stringify(param),
			'contentType': 'application/json',
			'dataType': 'json',
	
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {
				adminUserList.splice(0,);
				adminUserList.push(<Option key={"All"}>All</Option>);
				for (var e in data1.rows) {
					adminUserList.push(<Option key={data1.rows[e].userName}>{data1.rows[e].userName + ' - ' + data1.rows[e].contactPersonName}</Option>)
				}			
				comp.setState({ adminUserList: adminUserList })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					getUsersForBrokerNumber(param, true);
				}
			});
	}

	getSUPC(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + supcURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {

				for (var el in data1.rows) {
					comp.state.data.push({
						itemId: data1.rows[el].itemId,
						itemPack: data1.rows[el].itemPack,
						itemSize: data1.rows[el].itemSize,
						itemDescr: data1.rows[el].itemDescr,
						brand: data1.rows[el].brand,
						category: data1.rows[el].category,
						major: data1.rows[el].major,
						intermediate: data1.rows[el].intermediate,
						minor: data1.rows[el].minor
					})
				}
				comp.setState({ data: comp.state.data })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getSUPC(param, true);
				}
			});
	}

	render() {
		const row = [];
		for (var j in this.state.data) {
			row.push(<tr key={j}>
				<td>{this.state.data[j].itemId}</td>
				<td>{this.state.data[j].itemPack}</td>
				<td>{this.state.data[j].itemSize}</td>
				<td>{this.state.data[j].itemDescr}</td>
				<td>{this.state.data[j].category}</td>
				<td>{this.state.data[j].minor}</td>
				<td>{this.state.data[j].intermediate}</td>
				<td>{this.state.data[j].major}</td>

			</tr>)
		}

		//const fadeIn = this.state.open ? 'in' : '';
		const display = this.state.open ? 'block' : 'none';
		return (<div><span>{this.state.name}</span>
			<div className="modal" id='myModal' role='dialog' style={{ display }} >
				<div className='modal-dialog modal-lg' style={{ margin: "32vh auto auto auto" }}>

					<div className='modal-content'>
						<div className="modal-header">
							<h5 className="modal-title">Item Details</h5>
							<button type="button" className="close" data-dismiss="modal" onClick={this.close1} aria-label="Close" style={{ border: "1px solid red", backgroundColor: "red", color: "white" }}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className='modal-body' style={{ overflowX: "auto" }} >
							<div className="inside" style={{ width: "65vw" }}>
								<BootstrapTable ref='itemtable' maxHeight={'200px'} data={this.state.data}  >
									<TableHeaderColumn ref='itemId' width='50' dataField='itemId' isKey={true}  >
										Item Id</TableHeaderColumn>
									<TableHeaderColumn dataField='itemPack' ref='itemPack' width='50'>Item pack
        </TableHeaderColumn>


									<TableHeaderColumn width='30' ref='itemSize' dataField='itemSize'   >Item Size</TableHeaderColumn>
									<TableHeaderColumn ref='itemDescr' dataField='itemDescr' width='100' >Item Description</TableHeaderColumn>

									<TableHeaderColumn ref='category' dataField='category' width='50' >Category</TableHeaderColumn>
									<TableHeaderColumn ref='minor' dataField='minor' width='50' >Minor</TableHeaderColumn>
									<TableHeaderColumn ref='intermediate' dataField='intermediate' width='50' >Intermediate</TableHeaderColumn>
									<TableHeaderColumn ref='major' dataField='major' width='50' >Major</TableHeaderColumn>
								</BootstrapTable>

							</div></div>

					</div>
				</div></div>
		</div>
		);
	}
}

class NewItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = { value: props.defaultValue, vendorNo: props.row.vendorNumber, market: props.row.market, opcoId: props.row.opcoId, category: props.row.category, major: props.row.major, brand: props.row.brand }
	}
	componentWillMount() {
		comp.props.history.push({
			pathname: '/suite/vbt/newitems',
			state: {
				route: 'new', data: comp.name, group: comp.group, mailId: comp.state.mailId, brokerNumber: comp.state.brokerNumber, brokerCode: comp.state.brokerCode, brokerName: comp.state.brokerName,
				vendorNo: this.state.vendorNo, market: this.state.market, opcoId: this.state.opcoId, category: this.state.category, major: this.state.major, brand: this.state.brand
			}
		})
	}
	render() {
		return <div>{this.state.value}</div>
	}
}


const createItemTable = (onUpdate, props) => (<ItemTable onUpdate={onUpdate} {...props} />);
const searchNewItem = (onUpdate, props) => (<NewItem onUpdate={onUpdate} {...props} />);


var comp;
var mname = [];
var maj = [];
var bname = [];
var mname1 = [];
var maj1 = [];
var bname1 = [];
var adminList = [];
var adminUserList = [];
var singleBroker = [];
var dataSetNum = 1;
var selectedList = [];
var visitedPages = [];
var selectAllFlag = false;
var maxPages = 0;
var majorList = ""
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------


class AllFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: [],
			brokerNumber: this.props.brokerNumber,
			brokerNumberSelected: "All",
			brokerName: this.props.brokerName,
			brokerCode: this.props.brokerCode,
			brokerType: this.props.brokerType,
			branch: this.props.branch,
			mailId: this.props.mailId,
			item: this.props.item,
			edit: this.props.edit,
			products: [],
			market: "",
			noDataText: " ",
			modalDeleteTies: false,
			modalCommodityTies: false,
			deleteTiesProcess: '',
			data: [],
			userNameSelected: "All",
			adminList: [],
			maj1: [],
			majorList: "",
			opcoId: "",
			category: "",
			brand: '',
			itemId: '',
			vno: ''
		};
		comp = this;
		this.name = this.props.data;
		this.group = this.props.group;
		this.row = this.props.row;
		this.item = this.item.bind(this);
		this.edit = this.edit.bind(this);
		this.currentUser = this.props.currentUser;

		this.getBrokerNumbersOfCompany = this.getBrokerNumbersOfCompany.bind(this);
		this.getMajor = this.getMajor.bind(this);
		this.getBroker = this.getBroker.bind(this);
		this.deleteTies = this.deleteTies.bind(this);
		this.commodityTies = this.commodityTies.bind(this);
		this.brokerTieMultipleSearch = this.brokerTieMultipleSearch.bind(this);
		this.getNextTieSet = this.getNextTieSet.bind(this);
		this.currentPage = 1;
		this.handleBrokerNameChange = this.handleBrokerNameChange.bind(this);
		this.getUsersForBrokerNumber = this.getUsersForBrokerNumber.bind(this);
		this.setProperty = this.setProperty.bind(this);
	}
	componentWillMount() {
		adminList = []
		adminUserList = []

		if (this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate") {
			let param1 = {
				"brokerCode": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.code : this.props.history.location.state.brokerCode,
				"branch": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.branch : "corporate",
				"brokerNumber": this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber : this.props.history.location.state.brokerNumber
			}
			comp.getBrokerNumbersOfCompany(param1, false);
		}

		comp.getMajor(false);
	}

	handleBrokerNameChange(value) {
		comp.setState({ userNameSelected: "All" })
		comp.state.brokerNumber = value;
		comp.state.brokerNumberSelected = value;
		var param1 = {
			"brokerCode": comp.state.brokerCode,
			"brokerNumber": value
		}
		this.getUsersForBrokerNumber(param1, false);
	
	}
	
	getUsersForBrokerNumber(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			'type': 'POST',
			'url': URL.url + corpUserList,
			beforeSend: function () {
				$("#loader").show();
			},
			crossDomain: true,
			'data': JSON.stringify(param),
			'contentType': 'application/json',
			'dataType': 'json',
	
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {
				adminUserList.splice(0,);
				adminUserList.push(<Option key={"All"}>All</Option>);
				for (var e in data1.rows) {
					adminUserList.push(<Option key={data1.rows[e].userName}>{data1.rows[e].userName + ' - ' + data1.rows[e].contactPersonName}</Option>)
				}			
				comp.setState({ adminUserList: adminUserList })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					getUsersForBrokerNumber(param, true);
				}
			});
	}

	getMajor(didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + majorURL,
			'contentType': 'application/json',
			'dataType': 'json',
		})
			.done(function (data1) {
				for (var e in data1.rows) {
					maj1.push(<Option key={data1.rows[e].major}>{data1.rows[e].major}</Option>)
				}
				comp.setState({ maj1 });
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getMajor(true);
				}
			});
	}

	getBrokerNumbersOfCompany(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			beforeSend: function () {
				$("#loader").show();
			},
			'type': 'POST',
			'url': URL.url + corpBrokerList,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
			complete: function (data) {
				$("#loader").hide();
			}
		})
			.done(function (data1) {
				if (data1.rows == null) {
					adminList.push(<Option key={comp.props.history.location.state.group == "admin" ? comp.props.history.location.state.row.brokerNumber : comp.props.history.location.state.brokerNumber}>{comp.props.history.location.state.group == "admin" ? comp.props.history.location.state.row.brokerNumber + " - " + comp.props.history.location.state.row.name : comp.props.history.location.state.brokerNumber + " - " + comp.props.history.location.state.brokerName}</Option>)
				}
				else {
					adminList.push(<Option key={"All"}>All</Option>);
				}
				for (var e in data1.rows) {
					adminList.push(<Option key={data1.rows[e].brokerNumber}>{data1.rows[e].brokerNumber + " - " + data1.rows[e].brokerName}</Option>)
				}
				comp.setState({ adminList: adminList })
				comp.handleBrokerNameChange("All")
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getBrokerNumbersOfCompany(param, true);
				}
			});
	}



	componentDidMount() {
		if (comp.group === "cm") {
			$("#deleteTie").css("display", "none");
			$("#addTie").css("display", "none");
			flag = true
		} else {
			flag = false;
		}
		$("#loaderBV1").hide();
	}

	fetchTies() {

		console.log("filter props", this.props)
		console.log("filter state", this.state)

		$("#loaderBV1").show();
		comp.state.products = [];
		comp.setState({ products: comp.state.products });
		// this.setState({noDataText: ' '});

		var param = {
			"brokerNumber": this.state.brokerNumber
		}
		comp.getBroker(param, false);
	}

	getBroker(param, didFail) {
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + brokerURL,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data1) {
				$("#loaderBV1").hide();
				for (var el in data1.rows) {
					comp.state.products.push({
						"key": el,
						"brokerNumber": comp.state.brokerNumber,
						"vendorNumber": data1.rows[el].vendorNumber,
						"vendorName": data1.rows[el].vendorName,
						"market": data1.rows[el].market,
						"itemId": data1.rows[el].itemId,
						"itemDescr": data1.rows[el].itemDescr,
						"opcoId": data1.rows[el].opcoId,
						"opcoName": data1.rows[el].opcoName,
						"brand": data1.rows[el].brand,
						"category": data1.rows[el].category,
						"major": data1.rows[el].major,
						"intermediate": data1.rows[el].intermediate,
						"minor": data1.rows[el].minor,
						"startDate": data1.rows[el].startDate.slice(0, -2),
						"endDate": data1.rows[el].endDate.slice(0, -2),
						"sd": data1.rows[el].startDate.slice(0, 10),
						"ed": data1.rows[el].endDate.slice(0, 10),
						"doneBy": comp.name,
						"conflictResolveDate": data1.rows[el].conflictResolveDate
					})
				}
				comp.setState({ products: comp.state.products })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getBroker(param, true);
				}
				else
					$("#loaderBV1").hide();
			}).always(data => {
				// this.setState({noDataText: "No Data"});
			});
	}

	item(e) {

		comp.props.history.push({
			pathname: '/suite/vbt/allitems',
			state: { currentUser: this.currentUser, brokerType: this.state.brokerType, data: this.name, group: this.group, mailId: this.state.mailId, brokerNumber: (comp.props.history.location.state.group == "admin" ? comp.props.history.location.state.row.brokerNumber : comp.props.history.location.state.brokerNumber), branch: comp.state.branch, brokerCode: this.state.brokerCode, brokerName: this.state.brokerName }
		});
	}
	add(e) {
		comp.props.history.push({ pathname: '/suite/vbt/addBroker', state: { currentUser: comp.currentUser, data: comp.name, group: comp.group, groupName: 'admin', links: comp.links, brokerName: comp.state.brokerName, brokerCode: comp.state.brokerCode, brokerType: comp.state.brokerType, brokerNumber: (comp.props.history.location.state.group == "admin" ? comp.props.history.location.state.row.brokerNumber : comp.props.history.location.state.brokerNumber), branch: comp.state.branch } });
	}
	handleDeleteTiesCancel = (e) => {
		this.setState({ modalDeleteTies: false, deleteTiesProcess: "" });
	}
	handleCommodityTiesCancel = (e) => {
		this.setState({ modalCommodityTies: false });
	}
	deleteTiesOkFunction = (e) => {
		brandSearch = "";
		catSearch = "";
		itemSearch = "";
		majSearch = "";
		marSearch = "";
		opcoSearch = "";
		vendorSearch = "";

		if (comp.state.brand != "") {
			brandSearch = comp.state.brand;
		}
		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}
		if (comp.state.majorList != "") {
			majSearch = comp.state.majorList;
		}
		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}
		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}
		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}
		var user = this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate" ? userNameA : this.currentUser;
		if(user == "ibt.USER_NAME"){
			user = "";
		}

		console.log("this.state.deleteTiesProcess is", this.state.deleteTiesProcess)
		this.setState({ modalDeleteTies: false })
		var flag = window.confirm("Are you sure you want to delete?")
		if (flag) {
			var param = {
				"deleteItemsList": selectedList,
				"brand": brandSearch,
				"brokerCode": this.state.brokerCode,
				"brokerNumber": this.state.brokerNumber,
				"brokerType": brokerType,
				"category": catSearch,
				"itemId": itemSearch,
				"major": majSearch,
				"market": this.state.market,
				"opcoId": opcoSearch,
				"selectAllFlag": selectAllFlag,
				"userName": user,
				"vendorNumber": vendorSearch,
				"doneBy": this.name,
				"deleteProcess": this.state.deleteTiesProcess
			}
			comp.deleteTies(param, false)
		}
	}

	commodityTiesOkFunction = (e) => {
		brandSearch = "";
		catSearch = "";
		itemSearch = "";
		majSearch = "";
		marSearch = "";
		opcoSearch = "";
		vendorSearch = "";

		if (comp.state.brand != "") {
			brandSearch = comp.state.brand;
		}
		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}
		if (comp.state.majorList != "") {
			majSearch = comp.state.majorList;
		}
		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}
		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}
		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}
		var user = this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate" ? userNameA : this.currentUser;
		if(user == "ibt.USER_NAME"){
			user = "";
		}

		this.setState({ modalCommodityTies: false })
			var param = {
				"commodityItemsList": selectedList,
				"brokerCode": this.state.brokerCode,
				"brokerNumber": this.state.brokerNumber,
				"brokerType": brokerType,
				"category": catSearch,
				"selectAllFlag": selectAllFlag,
				"doneBy": this.name
			}
			comp.commodityTies(param, false)
	}

	deleteTies(param, didFail) {
		const hide = message.loading('Deleting Ties. Please do not reload or exit the page', 0);
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + deleteTieUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),

		})
			.done(function (data) {
				setTimeout(hide, 10);
				message.success(<span className="errorMsg">"Selected ties are deleted. Active Ties will be deleted based on your selection."</span>);
				window.location.reload(true);
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.deleteTies(param, true);
				}
			});
	}

	commodityTies(param, didFail) {
		const hide = message.loading('Adding Commodity Ties. Please do not reload or exit the page', 20);
		const extractedData = [];

		param.commodityItemsList.forEach(item => {
		  const { itemId, opcoId, vendorNumber } = item;
		  extractedData.push({
			COMMODITY_TIE_FLAG: 1,
			ITEM_ID: itemId,
			OPCO_ID: opcoId,
			VENDOR_NO: vendorNumber
		  });
		});

		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + commodityTieUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(extractedData),

		})
			.done(function (data) {
				setTimeout(hide, 20);
				message.success(<span className="errorMsg">"Commodity flags are deleted successfully."</span>);
				window.location.reload(true);
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.commodityTies(param, true);
				}
			});
	}

	routeToBrokerPoc = () => {
		this.props.history.push({
			pathname: '/suite/vbt/BrokerPoc',
			state: {
				brokerName: this.state.brokerName,
				name: this.name,
				row: this.row,
				group: this.group,
				brokerNumber: this.props.history.location.state.group == "admin" ? this.props.history.location.state.row.brokerNumber : this.props.history.location.state.brokerNumber,
				brokerType: this.state.brokerType,
				brokerCode: this.state.brokerCode,
				currentUser: this.currentUser
			}
		})
	}

	edit(e) {
		this.props.history.push({
			pathname: '/suite/vbt/edit',
			state: {
				data: this.name,
				row: this.row,
				group: this.group
			}
		})
	}
	createCustomDeleteButton = (onClick) => {
		return (

			<span>
				<Button type="button" id="deleteTie" style={{ color: '#fff', backgroundColor: '#008000', borderColor: '#0082cb', fontSize: '14px', marginBottom: '0.5em' }} onClick={() => this.handleDeleteButtonClick(onClick)}>
					<span><Glyphicon glyph="trash" /><span style={{ fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }}> Delete Ties
						</span></span>
				</Button>
				{ this.props.history.location.state.group == "admin" ? (
				<Button type="button" id="commodityTie" class="buttoncommodityTie" style={{ marginLeft: "10px", color: '#fff', fontSize: '14px', marginBottom: '0.5em' }} onClick={() => this.handleCommodityButtonClick(onClick)}>
					<span><span style={{ fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif' }}> Add Commodity Tie
						</span></span>
				</Button> 
				 ):""} 
			</span>
		);
	}

	openDeleteTiesModal = () => {
		this.setState({ modalDeleteTies: true });
	}

	handleDeleteButtonClick(onClick) {
		this.openDeleteTiesModal();
	};

	openCommodityTiesModal = () => {
		if(selectedList.length > 0){
			this.setState({ modalCommodityTies: true });}
	}

	handleCommodityButtonClick(onClick) {
		this.openCommodityTiesModal();
	};

	onSelect(row, isSelected) {
		var selection = selectedList;
		if (isSelected) {
			comp.state.selected.push(row);
		} else {
			comp.state.selected.splice(comp.state.selected.indexOf(row), 1)
		}

		if(!selection.includes(row)){
			selection.push(row);
		} else {
			const index = selection.indexOf(row);
			selection.splice(index, 1);
		}
		selectedList = selection;
		selectedList = selection;
		comp.setState({ selected: comp.state.selected })

	}
	onSelectAll = (isSelected, rows) => {
		if (isSelected) {
			selectAllFlag = true;
			selectedList = [];
			this.state.selected = this.state.products.map(row => row);
			this.setState({ selected: this.state.selected })
			return this.state.products.map(row => row.key);
		} else {
			selectAllFlag = false;
			selectedList = [];
			this.state.selected = [];
			this.setState({ selected: this.state.selected })

			return [];
		}

	}

	//universal input handler (text)
	onChangeInputHandler = (event) => {
		this.setState({ [event.target.id]: event.target.value });
	}


	setMarket = (data) => {
		this.setState({ market: data })
	}

	handleDropdownInput = (event, id) => {
		this.setState({ [id]: event });
	}

	showBrandModal1 = () => {
		this.setState({
			visibleBrand1: true
		});
	}
	handleBrandOk1 = (e1) => {
		this.setState({
			visibleBrand1: false,
		});
	}
	handleBrandCancel1 = (e1) => {

		this.setState({
			visibleBrand1: false,
		});
	}

	showVnoModal1 = (e1) => {
		this.setState({
			visibleVno1: true,
		});
	}
	handleVnoOk1 = (e1) => {
		this.setState({
			visibleVno1: false,
		});
	}
	handleVnoCancel1 = (e1) => {
		this.setState({
			visibleVno1: false,
		});
	}



	showMakModal = (e1) => {
		this.setState({
			visibleMak: true,
		});
	}
	handleMakOk = (value) => {
		this.setState({
			visibleMak: false,
			market: value
		});
	}
	handleMakCancel = (e1) => {
		this.setState({
			visibleMak: false,
			market: ''
		});
	}

	showMajModal1 = () => {
		this.setState({
			visibleMaj1: true,
		});
	}
	handleMajOk1 = (e1) => {
		this.setState({
			visibleMaj1: false,
		});
	}
	handleMajCancel1 = (e1) => {
		this.setState({
			visibleMaj1: false,
		});
	}

	showCatModal = () => {
		this.setState({
			visibleCat: true,
		});
	}
	handleCatOk = (e1) => {
		this.setState({
			visibleCat: false,
		});
	}
	handleCatCancel = (e1) => {
		this.setState({
			visibleCat: false,
		});
	}
	onChangeRadioItem = (e1) => {
		console.log("e1.target.value is", e1.target.value)
		this.setState({ deleteTiesProcess: e1.target.value })
	}


	showIidModal = () => {
		this.setState({
			visibleId: true,
		});
	}
	handleIdOk = (e) => {
		this.setState({
			visibleId: false,
		});
	}
	handleIdCancel = (e) => {
		this.setState({
			visibleId: false,
		});
	}


	showoIdModal = () => {
		this.setState({
			visibleoId: true,
		});
	}
	handleoIdOk = (e) => {
		this.setState({
			visibleoId: false,
		});
	}
	handleoIdCancel = (e) => {
		this.setState({
			visibleoId: false,
		});
	}
	search = (e1) => {
		dataSetNum = 1;
		visitedPages = [];
		e1.preventDefault();
		$("#loaderBV1").show();
		comp.state.products = [];
		//var p=[];
		vendorSearch = "ibt.VENDOR_NUMBER";
		marSearch = "MARKET";
		opcoSearch = "ibt.OPCO_ID";
		catSearch = "CATEGORY";
		majSearch = "MAJOR";
		userNameA = "ibt.USER_NAME";
		brandSearch = "BRAND";
		itemSearch = "ibt.ITEM_ID";
		comp.setState({ products: comp.state.products });
		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}

		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}
		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}
		if (comp.state.majorList != "") {
			majSearch = comp.state.majorList;
		}
		if ($('.userName').children().val() && $('.userName').children().val() != "All") {
			userNameA = $('.userName').children().val();
		}
		if (comp.state.brand != "") {
			brandSearch = trimSearchFieldValues(comp.state.brand);
		}
		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}
		if (this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate") {
			brokerType = "Broker Corporate";
		}
		else {
			brokerType = "Normal user"
		}
		if (comp.state.brokerNumber == "All")
			comp.state.brokerNumber = null;
		var parameter = {
			"brokerNumber": comp.state.brokerNumber,
			"brand": brandSearch,
			"category": catSearch,
			"itemId": itemSearch,
			"major": majSearch,
			"market": this.state.market.trim(),
			"opcoId": opcoSearch,
			"vendorNumber": vendorSearch,
			"userName": this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate" ? userNameA : this.currentUser,
			"groupId": comp.state.brokerCode,
			"brokerType": brokerType,
			"dataSetNum": dataSetNum
		}
		comp.brokerTieMultipleSearch(parameter, false);
	}

	brokerTieMultipleSearch(param, didFail) {
		var p1 =[];
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + multiSearchUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		})
			.done(function (data) {
				$("#loaderBV1").hide();
				comp.setState({ products: [] });
				var i = 0;
				for (var e2 in data.rows) {

					p1.push({
						"key": i,
						"userName": data.rows[e2].userName,
						"brokerNumber": data.rows[e2].brokerNumber,
						"vendorNumber": data.rows[e2].vendorNumber,
						"vendorName": data.rows[e2].vendorName,
						"market": data.rows[e2].market,
						"itemId": data.rows[e2].itemId,
						"itemDescr": data.rows[e2].itemDescr,
						"opcoId": data.rows[e2].opcoId,
						"opcoName": data.rows[e2].opcoName,
						"brand": data.rows[e2].brand,
						"category": data.rows[e2].category,
						"major": data.rows[e2].major,
						"intermediate": data.rows[e2].intermediate,
						"minor": data.rows[e2].minor,
						"commodityTieFlag": data.rows[e2].commodityTieFlag === 1 ? 'Yes' : 'No',
						"startDate": data.rows[e2].startDate.slice(0, -2),
						"endDate": data.rows[e2].endDate.slice(0, -2),
						"sd": data.rows[e2].startDate.slice(0, 10),
						"ed": data.rows[e2].endDate.slice(0, 10),
						"doneBy": comp.name,
						"conflictResolveDate": data.rows[e2].conflictResolveDate,
						"brokerName": data.rows[e2].brokerName
					})
					i = i + 1;
				}
				maxPages = Math.ceil(p1.length / 6);
				comp.setState({ products: p1 });
				comp.setState({currentPage: 1});
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.brokerTieMultipleSearch(param, true);
				}
				$("#loaderBV1").hide();
			});
	}

	getNextDataSet() {
		dataSetNum = dataSetNum + 1;
		vendorSearch = "ibt.VENDOR_NUMBER";
		opcoSearch = "ibt.OPCO_ID";
		catSearch = "CATEGORY";
		userNameA = "ibt.USER_NAME";
		itemSearch = "ibt.ITEM_ID";
		majSearch = "MAJOR";
		brandSearch = "BRAND";
		marSearch = "MARKET";

		comp.setState({ products: comp.state.products });
		if (comp.state.vno != "") {
			vendorSearch = comp.state.vno;
		}

		if (comp.state.opcoId != "") {
			opcoSearch = comp.state.opcoId;
		}

		if (comp.state.category != "") {
			catSearch = comp.state.category;
		}

		if (comp.state.majorList != "") {
			majSearch = comp.state.majorList;
		}

		if ($('.userName').children().val() && $('.userName').children().val() != "All") {
			userNameA = $('.userName').children().val();
		}

		if (comp.state.brand != "") {
			brandSearch = trimSearchFieldValues(comp.state.brand);
		}

		if (comp.state.itemId != "") {
			itemSearch = comp.state.itemId;
		}

		if (this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate") {
			brokerType = "Broker Corporate";
		}
		else {
			brokerType = "Normal user"
		}
		if (comp.state.brokerNumber == "All")
			comp.state.brokerNumber = null;

		var parameter = {
			"brokerNumber": comp.state.brokerNumber,
			"brand": brandSearch,
			"category": catSearch,
			"itemId": itemSearch,
			"major": majSearch,
			"market": this.state.market.trim(),
			"opcoId": opcoSearch,
			"vendorNumber": vendorSearch,
			"userName": this.props.history.location.state.group == "admin" || this.state.brokerType == "Broker Corporate" ? userNameA : this.currentUser,
			"groupId": comp.state.brokerCode,
			"brokerType": brokerType,
			"dataSetNum": dataSetNum
		}
		this.getNextTieSet(parameter, false);
	}

	getNextTieSet(param, didFail){
		var p1 = comp.state.products;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,
			'type': 'POST',
			'url': URL.url + multiSearchUrl,
			'contentType': 'application/json',
			'dataType': 'json',
			'data': JSON.stringify(param),
		}).done(function (data) {
				var i = comp.state.products.length;
				for (var e2 in data.rows) {
					p1.push({
						"key": i,
						"userName": data.rows[e2].userName,
						"brokerNumber": data.rows[e2].brokerNumber,
						"vendorNumber": data.rows[e2].vendorNumber,
						"vendorName": data.rows[e2].vendorName,
						"market": data.rows[e2].market,
						"itemId": data.rows[e2].itemId,
						"itemDescr": data.rows[e2].itemDescr,
						"opcoId": data.rows[e2].opcoId,
						"opcoName": data.rows[e2].opcoName,
						"brand": data.rows[e2].brand,
						"category": data.rows[e2].category,
						"major": data.rows[e2].major,
						"intermediate": data.rows[e2].intermediate,
						"minor": data.rows[e2].minor,
						"startDate": data.rows[e2].startDate.slice(0, -2),
						"endDate": data.rows[e2].endDate.slice(0, -2),
						"sd": data.rows[e2].startDate.slice(0, 10),
						"ed": data.rows[e2].endDate.slice(0, 10),
						"doneBy": comp.name,
						"conflictResolveDate": data.rows[e2].conflictResolveDate,
						"brokerName": data.rows[e2].brokerName
					})
					i = i + 1;
				}
				maxPages = Math.ceil(p1.length / 6);
				comp.setState({ products: p1 })
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.getNextTieSet(param, true);
				}
				$("#loaderBV1").hide();
			});
	}

	handleChange = (page) => {
		if(page == maxPages && !visitedPages.includes(page)){
			visitedPages.push(page);
			comp.getNextDataSet();
		}
		this.setState({currentPage: page});
	}

	setProperty(key, value) {
		comp.setState({
			[key]: value
		})
	}

	render() {

		const cellEditProp = {
			mode: 'click',
			blurToSave: true,

		};
		const selectRowProp = {
			mode: 'checkbox',
			onSelect: this.onSelect,
			onSelectAll: this.onSelectAll,
			hideSelectColumn: flag,


		};
		//const { getFieldDecorator } = this.props.form;

		const options = {
			onRowClick: function (row) {
				itemId = row.itemId
			},
			deleteBtn: this.createCustomDeleteButton,
			commodityBtn: this.createCustomCommodityButton,
			noDataText: this.state.noDataText,
			sizePerPage: 6,
			onPageChange: this.handleChange,
			page: this.state.currentPage,
		}


		return (
			<div>
				<h1 className="headings" style={{ textAlign: "center", margin: "auto" }} >Broker Home Screen</h1>

				<h1 className="headings row" style={{ textAlign: "left" }} ><span className="col-md-5">{this.state.brokerCode} {this.state.brokerName}  </span></h1>
				<div style={{position: "relative"}}>
					<Tooltip placement="bottom" title='Contacts At Opco'>
						<Button1 className="btn btn-primary btn-lg"
							onClick={() => this.routeToBrokerPoc()}
							style={{
								backgroundColor: '#0082cb', borderColor: '#0082cb', float: "right", fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif',
								position: "absolute", right: "316px", marginTop: "-75px"
							}}>Contacts</Button1>
					</Tooltip>
					{this.state.brokerType !== "Normal user" ?
						<Button1 className="btn btn-primary btn-lg" style={{ backgroundColor: '#0082cb', borderColor: '#0082cb', float: "right", fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif', position: "absolute", right: "205px", marginTop: "-75px" }} onClick={this.add}>Add User</Button1>
						: ""}{/* <Button1 className="btn btn-primary btn-lg" onClick={this.edit} style={{backgroundColor: '#0082cb',borderColor: '#0082cb',float:"right",fontSize:"14px",fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif',position:"absolute",right:"10vw",top:"87px",display:this.state.edit}}>Edit broker details</Button1> */}
					<Button1 type="submit" id="addTie" className="button button2 btn btn-success" style={{ float: "right", border: "none", position: "absolute", right: "67px", marginTop: "-75px", height: "40px", backgroundColor: '#0082cb', borderColor: '#0082cb', fontSize: "14px", fontFamily: 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif', display: this.state.item }} onClick={this.item}>Add Ties</Button1>
				</div>
				<br />
				<form onSubmit={this.search} className="form-horizontal row">
					<div className="col-md-5">
						{this.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ? (
							<FormGroup >
								<div className="col-md-3 col-sm-5"><label>
									Broker: </label></div>
								<div className="col-md-8 col-sm-10 col-xs-10">
									<Select
										value={this.state.brokerNumberSelected}
										dropdownMatchSelectWidth={false}
										className="brokerName"
										style={{ width: "inherit" }}
										placeholder={"All"}
										onChange={(value) => this.handleBrokerNameChange(value)}
									>
										{this.state.adminList}
									</Select></div>
							</FormGroup >) : ""}
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Vendor No:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10">
								<Search
									placeholder="input search text" className="vno"
									onSearch={value => this.showVnoModal1()}
									onChange={e => this.setProperty('vno', e.target.value)}
									value={this.state.vno}
									enterButton
								/>

								<Modal
									title="Search Vendors"
									visible={this.state.visibleVno1}
									onOk={this.handleVnoOk1}
									onCancel={this.handleVnoCancel1}
								>
									<VendorName setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Market:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10">
								<Search
									id="market"
									onChange={(event) => this.onChangeInputHandler(event)}
									placeholder="input search text" className="market"
									onSearch={value => this.showMakModal()}
									enterButton
									value={this.state.market}
								/>
								<MarketFilter
									visible={this.state.visibleMak}
									setMarket={this.handleMakOk}
									onCancel={this.handleMakCancel} />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>OpCo Id:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10"><Search
								placeholder="input search text" className="opcoId"
								onSearch={value => this.showoIdModal()}
								enterButton
								onChange={e => this.setProperty('opcoId', e.target.value)}
								value={this.state.opcoId}
							/>

								<Modal
									title="Search OPCO"
									visible={this.state.visibleoId}
									onOk={this.handleoIdOk}
									onCancel={this.handleoIdCancel}
								>
									<OpcoId setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-11">
								<Button1 type="submit" className="btn btn-primary btn-lg search" style={{ width: "105px", backgroundColor: "#008cd2", opacity: "1.0" }}> Search</Button1>
							</div>
						</FormGroup>
					</div>
					<div className="col-md-1">

					</div>
					<div className="col-md-5">
						{this.state.brokerType == "Broker Corporate" || this.props.history.location.state.group == "admin" ? (
							<FormGroup >
								<div className="col-md-3 col-sm-5"><label>
									User: </label></div>
								<div className="col-md-8 col-sm-10 col-xs-10">
									<Select
										value={this.state.userNameSelected}
										dropdownMatchSelectWidth={false}
										className="userName"
										style={{ width: "inherit" }}
										placeholder="All"
										onChange={handleuserNameChange}
									>
										{this.state.adminUserList}
									</Select></div>
							</FormGroup >) : ""}




						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Category:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10">

								<Search
									placeholder="input search text" className="category"
									onSearch={value => this.showCatModal()}
									enterButton
									onChange={e => this.setProperty('category', e.target.value)}
									value={this.state.category}
								/>
								<Modal
									title="Search Category"
									visible={this.state.visibleCat}
									onOk={this.handleCatOk}
									onCancel={this.handleCatCancel}
								>
									<Cat setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Major:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10">

								<Search
									placeholder="input search text" className="major"
									onSearch={value => this.showMajModal1()}
									enterButton
									value={this.state.majorList}
									onChange={e => this.setProperty('majorList', e.target.value)}
								/>
								<Modal
									title="Search Major"
									visible={this.state.visibleMaj1}
									onOk={this.handleMajOk1}
									onCancel={this.handleMajCancel1}
								>
									<Select
										mode="multiple"
										style={{ width: '100%' }}
										placeholder="Please select"
										onChange={handleMajChange1}
									>
										{this.state.maj1}
									</Select>
								</Modal>
							</div>
						</FormGroup>

						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Brand:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10">

								<Search
									placeholder="input search text" className="brand"
									onSearch={value => this.showBrandModal1()}
									onChange={e => this.setProperty('brand', e.target.value)}
									value={this.state.brand}
									enterButton
								/>
								<Modal
									title="Search Brands"
									open={this.state.visibleBrand1}
									onOk={this.handleBrandOk1}
									onCancel={this.handleBrandCancel1}
								>
									<Brand setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 col-sm-5"><label>Item Id:</label></div>
							<div className="col-md-8 col-sm-10 col-xs-10"><Search
								placeholder="input search text" className="itemId"
								onSearch={value => this.showIidModal()}
								onChange={e => this.setProperty('itemId', e.target.value)}
								value={this.state.itemId}
								enterButton
							/>

								<Modal

									title="Search Items"
									visible={this.state.visibleId}
									onOk={this.handleIdOk}
									onCancel={this.handleIdCancel}

								>
									<ItemDescr setProperty={this.setProperty} />
								</Modal>
							</div>
						</FormGroup>

					</div>
				</form>

				<Modal title="Delete Process" visible={this.state.modalDeleteTies} onOk={this.deleteTiesOkFunction} centered onCancel={this.handleDeleteTiesCancel}>

					<span>
						<RadioGroup onChange={this.onChangeRadioItem} value={this.state.deleteTiesProcess}>
							<Radio value='delete immediately'>
								<span className="label_drop" style={{ fontSize: '14px', color: '#707070', marginTop: " 7px" }}>Delete immediately</span>
							</Radio>
							<br /><br />
							<Radio value='deleteAtEOM'>
								<span className="label_drop" style={{ fontSize: '14px', color: '#707070', marginTop: " 7px" }}>Delete at the end of the month</span>
							</Radio>

						</RadioGroup>
					</span>

				</Modal>
				
				<Modal  visible={this.state.modalCommodityTies} onOk={this.commodityTiesOkFunction} centered onCancel={this.handleCommodityTiesCancel}>

					<span>
								<span className="label_drop" style={{ fontSize: '14px', color: '#707070', marginTop: " 7px" }}>Are you sure you want to flag this item(s) as commodity?</span>
					</span>

				</Modal>	


				<div style={{ width: "95vw", overflowX: "auto", overflowY: "hidden" }}><br />
					<BootstrapTable ref='table' style={{ overflowX: "auto" }} data={this.state.products} pagination cellEdit={cellEditProp} options={options} deleteRow={true} selectRow={selectRowProp} striped hover condensed>
						<TableHeaderColumn ref='key' dataField='key' width="130" isKey={true} hidden editable={false} >key</TableHeaderColumn>
						{
							comp.group == "admin" || this.state.brokerType == "Broker Corporate" ?
								(<TableHeaderColumn ref='bno' dataField='brokerNumber' width="130" dataSort={true} editable={false} thStyle={{}}>Broker Number</TableHeaderColumn>) : <TableHeaderColumn></TableHeaderColumn>
						}
						{
							comp.group == "admin" || this.state.brokerType == "Broker Corporate" ?
								(<TableHeaderColumn ref='brokerName' dataField='brokerName' width="110" dataSort={true} editable={false} thStyle={{}}>Broker Name</TableHeaderColumn>) : <TableHeaderColumn></TableHeaderColumn>
						}
						{
							comp.group == "admin" || this.state.brokerType == "Broker Corporate" ?
								(<TableHeaderColumn ref='userName' dataField='userName' width="100" dataSort={true} editable={false} thStyle={{}}>User Name</TableHeaderColumn>) : <TableHeaderColumn></TableHeaderColumn>
						}
						<TableHeaderColumn ref='vendorNumber' dataField='vendorNumber' width="100" editable={!flag} customEditor={{ getElement: searchNewItem }} dataSort={true} >Vendor No</TableHeaderColumn>
						<TableHeaderColumn dataField='vendorName' width="250" ref='vendorName' editable={!flag} customEditor={{ getElement: searchNewItem }} dataSort={true} >Vendor Name</TableHeaderColumn>
						<TableHeaderColumn ref='market' dataField='market' width="100" dataSort={true} editable={false} >Market</TableHeaderColumn>
						<TableHeaderColumn ref='opcoName' dataField='opcoName' dataSort={true} width="150" editable={false}>OpCo Name</TableHeaderColumn>
						<TableHeaderColumn ref='opcoId' dataField='opcoId' dataSort={true} width="80" editable={false}>OpCo id</TableHeaderColumn>
						<TableHeaderColumn ref='category' dataField='category' dataSort={true} width="50" editable={false}>Cat</TableHeaderColumn>
						<TableHeaderColumn ref='major' dataField='major' dataSort={true} width="50" editable={false}>Maj</TableHeaderColumn>
						<TableHeaderColumn ref='intermediate' dataField='intermediate' dataSort={true} width="50" editable={false}>Int</TableHeaderColumn>
						<TableHeaderColumn ref='minor' dataField='minor' dataSort={true} width="50" editable={false}>Min</TableHeaderColumn>
						<TableHeaderColumn ref='brand' dataField='brand' dataSort={true} width="100" editable={false}>Brand</TableHeaderColumn>
						<TableHeaderColumn ref='itemId' dataField='itemId' width="100" customEditor={{ getElement: createItemTable }} dataSort={true} >Item Id</TableHeaderColumn>
						<TableHeaderColumn ref='itemDescr' dataField='itemDescr' width="250" dataSort={true} editable={false} >
							Item Description</TableHeaderColumn>
						<TableHeaderColumn ref='startdate' dataField='startDate' dataSort={true} width="100" editable={false} hidden>Start date</TableHeaderColumn>
						<TableHeaderColumn ref='enddate' dataField='endDate' dataSort={true} width="100" editable={false} hidden>End date</TableHeaderColumn>
						<TableHeaderColumn ref='sd' dataField='sd' dataSort={true} width="100" editable={false}>Start date</TableHeaderColumn>
						<TableHeaderColumn ref='ed' dataField='ed' dataSort={true} width="100" editable={false} >End date</TableHeaderColumn>
						<TableHeaderColumn ref='commodityTieFlag' dataField='commodityTieFlag' dataSort={true} width="200" editable={false} >Commodity Tie Flag</TableHeaderColumn>
						<TableHeaderColumn ref='doneBy' dataField='doneBy' dataSort={true} width="100" editable={false} hidden>Done By</TableHeaderColumn>
						<TableHeaderColumn ref='conflictResolveDate' dataField='conflictResolveDate' dataSort={true} width="100" editable={false} hidden>ConflictResolveDate</TableHeaderColumn>
					</BootstrapTable>
					<div id="loaderBV1" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>

				</div>
			</div>
		);
	}
}

export default withRouter(AllFilters);