import { message } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator';
import { initGA, PageView } from '../GATracking/GATracking';
import withAuth from '../login/withAuth';
import '../resetp_style.css';
import '../searchstyle.css';
import '../style.css';
import URL from '../url.js';
import sysco2 from './handshake.png';
import NavBar1 from './navbar.jsx';

const updateURL = 'updateUser';

class EditScreenBroker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				contactPersonName: this.props.history.location.state.row.contactPersonName,
				addedBy: this.props.history.location.state.row.addedBy,
				userName: this.props.history.location.state.row.userName,
				phoneNumber: this.props.history.location.state.row.phoneNumber,
				mailId: this.props.history.location.state.row.mailId,
				userGroup: this.props.history.location.state.row.userGroup,
				brokerCode: this.props.history.location.state.row.brokerCode,
				userId: this.props.history.location.state.row.userId,
				brokerNumber: this.props.history.location.state.row.brokerNumber,
				brokerType: this.props.history.location.state.row.brokerType,
			}
		};
		this.setEmpState1 = this.setEmpState1.bind(this);
		this.editRows1 = this.editRows1.bind(this);
		this.group = this.props.history.location.state.group;
		this.name = this.props.history.location.state.data;
		this.updateUser = this.updateUser.bind(this);
	}

	componentDidMount() {
		initGA();
		PageView();
	}

	setEmpState1(e) {
		var value1 = e.target.value;
		var field1 = e.target.name;
		this.state.data[field1] = value1;
		this.setState({ data: this.state.data });
	}

	editRows1(e2) {
		e2.preventDefault();
		$("#edit1").prop('disabled', true);
		var comp = this;
		var paramm = {
			contactPersonName: comp.state.data.contactPersonName,
			brokerType: comp.state.data.brokerType,
			brokerNumber: comp.state.data.brokerNumber,
			brokerCode: comp.state.data.brokerCode,
			userName: comp.state.data.userName,
			mailId: comp.state.data.mailId,
			phoneNumber: comp.state.data.phoneNumber,
			userId: comp.state.data.userId,
			doneBy: comp.name,
			addedBy: comp.state.data.addedBy,
			userGroup: comp.state.data.userGroup,
		}
		comp.updateUser(paramm, false);
	}

	updateUser(param, didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				'Content-Type': 'application/json',
				
			},
			'type': 'POST',
			'url': URL.url + updateURL,
			crossDomain: true,
			'dataType': 'json',
			'data': JSON.stringify(param),
			'contentType': 'application/json',

		})
			.done(function (dataa) {
				$("#edit1").prop('disabled', false);
				if (dataa.result) {
					message.success(<span className="errorMsg">'Edited successfully'</span>)
					comp.props.history.push({
						pathname: '/suite/vbt/viewbrokerScreen',
						state: {
							code: comp.state.data.brokerCode,
							data: comp.name,
							group: comp.group,
							editBasicData: false
						}
					})
				} else {
					message.error(<span className="errorMsg">"Duplicate entry"</span>)
				}
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.updateUser(param, true);
				}
				else
					$("#edit1").prop('disabled', false);
			});
	}

	render() {
		return (<div>
			<NavBar1 name={this.name} group={this.group} />
			<br />
			<div className="class1">
				<div className="row"><h1 className="headings tab">Update Broker details</h1></div>
				<br />
				<div className="row" style={{ fontSize: "15px" }}>
					<form className="form-horizontal col-md-7 col-sm-11 editForm" onSubmit={this.editRows1}>
						<div className="col-md-offset-3 col-md-7"><h5 style={{ color: "red" }} >Note: All fields are mandatory</h5></div>
						<FormGroup>
							<div className="col-md-3 aname"><label>Contact Person Name: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="contactPersonName" value={this.state.data.contactPersonName} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Code: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="brokerCode" disabled={true} value={this.state.data.brokerCode} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Broker Number: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="brokerNumber" disabled={true} value={this.state.data.brokerNumber} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup className="selectCustom">
							<div className="col-md-3 aname"><label>Broker Type: </label></div>
							<div>
								<select
									style={{ width: "435px" }}
									className="form-control col-md-9"
									value={this.state.data.brokerType}
									name="brokerType"
									id="brokerType"
									disabled={true}
									onChange={this.setEmpState1}>
									<option value={this.state.data.brokerType == "Normal user" ? "Normal user" : "Broker Corporate"}>{this.state.data.brokerType == "Normal user" ? "Individual User" : "Corporate Broker"}</option>
									{/* <option value="Broker Corporate">Corporate Broker</option> */}
								</select></div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Email: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="email" value={this.state.data.mailId} name="mailId" onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>User Name: </label></div>
							<div className="col-md-7">
								<input disabled className="form-control" type="text" name="userName" value={this.state.data.userName} onChange={this.setEmpState1} required />
							</div>
						</FormGroup>
						<FormGroup>
							<div className="col-md-3 aname"><label>Contact No: </label></div>
							<div className="col-md-7">
								<input className="form-control" type="text" name="phoneNumber" value={this.state.data.phoneNumber} pattern="[0-9]{10}" maxLength="10" onChange={this.setEmpState1} title="Please enter a 10 digit phone number " required />
							</div>
						</FormGroup>
						<div className="col-md-offset-3 col-md-4">
							<button type="submit" className=" btn btn-primary btn-lg" id="edit1"> Update </button>
						</div>
					</form>
					<div className="col-md-3">
						<img src="/static/media/handshake.c4db6082.png" style={{ paddingTop: "10%" }} className="handshake" alt="hi" />
					</div>
				</div>
			</div>
		</div>)
	}
}
export default withAuth(EditScreenBroker);
