import React, { Component } from 'react';
import NavBar1 from '../../admin welcome/navbar';
import PocSubmission from '../PocSubmission/PocSubmission';

class AddPocDetail extends Component {

    onSubmit = (input) => {
        console.log("SUBMIT")
        console.log(input);
    }

    render() {
        // const name = this.props.history.location.state.data
        // const group = this.props.history.location.state.group
        return (
            <div>
                <NavBar1 name={this.name} group={this.group} />
                <br />
                <PocSubmission title={"Add Poc Details"} onSubmit={this.onSubmit} />
            </div>
        )
    }
}

export default AddPocDetail;
