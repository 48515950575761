import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminWelcome from './components/admin welcome/adminWelcome';
import Login from './components/login/login';
import EditScreen from './components/admin welcome/editScreen';
import viewBrokerScreen from './components/admin welcome/viewBrokerScreen';
import viewBrokerScreenCorp from './components/admin welcome/viewBrokerScreenCorp';
import AddScreen from './components/admin welcome/addScreen';
import AddScreenBroker from './components/admin welcome/addScreenBroker';
import EditBroker from './components/admin welcome/editBroker';
import EditCM from './components/admin welcome/editCM';
import EditOPCO from './components/admin welcome/editOPCO';
import EditAdmin from './components/admin welcome/editAdmin';
import EditVendor from './components/admin welcome/editVendor';
import MainScreen2 from './components/broker vendor/brokermscreen';
import App from './components/broker vendor/filters';
import Vendor from './components/vendor/vendor';
import VendorOpco from './components/vendorOpco/vendoropco';
import DuplicateReport from './components/reports/duplicate_report';
import BrokerVendorReport from './components/reports/broker_vendor_report'
import AllTies from './components/reports/all_ties_audit';
import UserAudit from './components/reports/user_audit';
import BrokerTies from './components/reports/audit_broker';
import Unassigned from './components/reports/unassigned_dropdown';
import AllItems1 from './components/broker vendor/allitems1';
import ViewVendor from './components/admin welcome/Viewvendor';
import ViewOpco from './components/admin welcome/Viewopco';
import ViewAdmin from './components/admin welcome/Viewadmin';
import ViewCM from './components/admin welcome/viewcm';
import Notif from './components/reports/notification';
import ViewStatus from './components/reports/unassigneddwnldstatus';
import ViewBVRStatus from './components/reports/bvr_dwnldstatus';
import AddPocDetail from './components/BrokerPoc/AddPocDetail/AddPocDetail';
import BrokerPoc from './components/BrokerPoc/BrokerPoc';
import BrokerPocAudits from './components/reports/brokerpocaudits';
import EditBData from './components/admin welcome/editBasicData';
import ViewDuplicateStatus from './components/reports/duplicate_dwnld_status';
import Test from 'components/test';

//Remove or comment the below line to enable console log
//console.log = function () { };

function Sample(props) {
    return <Router>
        <div style={{width: '100%'}}>
            <Switch>
                <Route exact path="/suite/vbt" component={Login} />
                <Route path="/suite/vbt/admin" component={AdminWelcome} />
                <Route path="/suite/vbt/corporate" component={AdminWelcome} />

                <Route path="/suite/vbt/edit" component={EditScreen} />
                <Route path="/suite/vbt/editCM" component={EditCM} />
                <Route path="/suite/vbt/editOPCO" component={EditOPCO} />
                <Route path="/suite/vbt/editAdmin" component={EditAdmin} />
                <Route path="/suite/vbt/editBroker" component={EditBroker} />
                <Route path="/suite/vbt/editVendor" component={EditVendor} />
                <Route path="/suite/vbt/addBroker" component={AddScreenBroker} />
                <Route path="/suite/vbt/add" component={AddScreen} />

                <Route path="/suite/vbt/broker" component={MainScreen2} />
                <Route path="/suite/vbt/vendor" component={Vendor} />
                <Route path="/suite/vbt/opcoviewer" component={VendorOpco} />
                <Route path="/suite/vbt/logout" component={Login} />
                <Route path="/suite/vbt/duplicate_report" component={DuplicateReport} />
                <Route path="/suite/vbt/broker_vendor_report" component={BrokerVendorReport} />
                <Route path="/suite/vbt/user_audit" component={UserAudit} />
                <Route path="/suite/vbt/all_ties" component={AllTies} />
                <Route path="/suite/vbt/broker_ties" component={BrokerTies} />
                <Route path="/suite/vbt/allitems" component={AllItems1} />
                <Route path="/suite/vbt/newitems" component={AllItems1} />
                <Route path="/suite/vbt/vvendor" component={ViewVendor} />
                <Route path="/suite/vbt/vopco" component={ViewOpco} />
                <Route path="/suite/vbt/vadmin" component={ViewAdmin} />
                <Route path="/suite/vbt/vcm" component={ViewCM} />
                <Route path="/suite/vbt/viewbrokerScreenCorporates" component={viewBrokerScreenCorp} />

                <Route path="/suite/vbt/viewbrokerScreen" component={viewBrokerScreen} />
                <Route path="/suite/vbt/editBaseData" component={EditBData} />

                <Route path="/suite/vbt/viewbroker" component={MainScreen2} />
                <Route path="/suite/vbt/unassigned_report" component={Unassigned} />
                <Route path="/suite/vbt/notification" component={Notif} />
                <Route path="/suite/vbt/view_download_status" component={ViewStatus} />
                <Route path="/suite/vbt/view_bvr_download_status" component={ViewBVRStatus} />
                <Route path="/suite/vbt/view_duplicate_download_status" component={ViewDuplicateStatus} />

                <Route path="/suite/vbt/AddPocDetails" component={AddPocDetail} />
                <Route path="/suite/vbt/BrokerPoc" component={BrokerPoc} />
                <Route path="/suite/vbt/broker_poc_audit" component={BrokerPocAudits} />
                <Route exact path="/suite/vbt/test" component={Test} />
            </Switch>
        </div>
    </Router>
}

export default Sample;
