import { Col, Row } from 'antd';
import React from 'react';
import handShakeImage from '../../../admin welcome/handshake.png';
import './PocDetailForm.css';


const pocDetailForm = (props) => {

    // generateOpcos = () =>{
    //     input.allOpcos.map(opco =>{
    //         console.log()
    //     })
    // }

    return (
        <div>
            <img src="/static/media/handshake.c4db6082.png" className='pocHandshake' alt="image" />
            <Row>
                <Col offset={1}>
                    <h1 className="pocHeader" >{props.title}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} style={{ marginRight: '40px' }} />
                <Col span={10}>
                    <div style={{ color: 'red', fontWeight: 'bold' }} >Note: all fields are mandatory</div>
                </Col>
            </Row>
            <div style={{ textAlign: 'right', width: '100%' }}>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Broker Number: </label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input className="form-control" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Opco Number: </label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <select className="form-control" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Name: </label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="name"
                            value={props.input.name} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Title:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="title"
                            value={props.input.title} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Address Lane 1:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLane1"
                            value={props.input.addressLane1} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Address Lane 2:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }} >
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLane2"
                            value={props.input.addressLane2} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Address Lane 3:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLane3"
                            value={props.input.addressLane3} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Address Lane 4:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLane4"
                            value={props.input.addressLane4} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Phone:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="phone"
                            value={props.input.phone} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Fax:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="fax"
                            value={props.input.fax} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>City:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="city"
                            value={props.input.city} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>State:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="state"
                            value={props.input.state} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>ZIP:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="zip"
                            value={props.input.zip} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Country:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="country"
                            value={props.input.country} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} className='pocLabel'>
                        <label>Email:</label>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 7 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="email"
                            value={props.input.email} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default pocDetailForm;
