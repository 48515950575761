import { Col, Row, Select } from 'antd';
import React from 'react';
import './PocModalForm.css';

const Option = Select.Option;
const corpBrokerList = "getBrokerNumbersOfCompany";
const corpUserList = "getUsersForBrokerNumber";

const PocModalForm = (props) => {
    const displayOpcos = () => {
        let selectedOpco = props.formInformation.opcoId.slice(0, 3);

        return props.opcos.map(opco => {
            let opcoNumber = opco.opcoNumber.slice(0, 3);
            if (opcoNumber === selectedOpco) {
                return <option key={opco.key} selected="selected" value={opcoNumber}>{opco.opcoNumber}</option>
            }
            else {
                return <option key={opco.key} value={opcoNumber}>{opco.opcoNumber}</option>
            }
        });
    }


    const errorBuffer = () => {
        return (
            <Col xs={{ span: 4 }} className='pocLabel'>
            </Col>
        );
    }

    //this is used to display an error message if an input is invalid
    //use: provide it with the name of the state, it will return the state in a specified format
    const errorMessage = (errorInputLabel) => {
        if (props.formValidation[errorInputLabel] !== "") {
            return (
                <Row>
                    {errorBuffer()}
                    <Col
                        style={{ color: 'red', textAlign: 'left', marginTop: '-2%' }}>
                        <small style={{ marginLeft: "63px" }}>{props.formValidation[errorInputLabel]}</small>
                    </Col>
                </Row>
            )
        }
        else {
            return null;
        }
    }

    return (
        <div>
            <div style={{ textAlign: 'right', width: '100%' }}>
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label><span style={{ color: 'red' }}>*</span> Opco Number: </label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <select
                            style={{ width: "432px", marginLeft: "0px" }}
                            className="form-control custom-ant-select"
                            id="opcoId" placeholder="select opco"
                            disabled={props.disableOpcoid}
                            onChange={event => props.handleInput(event)}>
                            <option >Select opco</option>
                            {displayOpcos()}
                        </select>
                    </Col>
                </Row>
                {props.showDropdown ?
                    <div>
                        <Row>
                            <Col xs={{ span: 4 }} className='pocLabel'>
                                <label><span style={{ color: 'red' }}>*</span> Broker :</label>
                            </Col>
                            <Col xs={{ span: 16 }}>
                                <Select
                                    style={{ width: "432px", marginLeft: "0px", textAlign: 'start !important' }}
                                    className="form-control custom-ant-select"
                                    id="adminList" placeholder="Select Broker"
                                    disabled={props.disableOpcoid}
                                    value={props.formInformation.brokerNumber == "" || props.formInformation.brokerNumber == undefined ? "Select" : props.formInformation.brokerNumber + "-" + props.formInformation.brokerName}
                                    onChange={event => props.handleBrokerChange(event)}>
                                    {props.adminList}
                                </Select>
                            </Col>
                        </Row>
                        {errorMessage("brokerData")}
                        <Row>
                            <Col xs={{ span: 4 }} className='pocLabel'>
                                <label><span style={{ color: 'red' }}>*</span> User:</label>
                            </Col>
                            <Col xs={{ span: 16 }}>
                                <Select
                                    style={{ width: "432px", marginLeft: "0px", textAlign: 'start !important' }}
                                    className="form-control custom-ant-select"
                                    id="adminList" placeholder="Select User"
                                    value={props.formInformation.userName}
                                    disabled={props.disableOpcoid}
                                    onChange={event => props.handleUserChange(event)}>
                                    {props.adminUserListValAdd}
                                </Select>
                            </Col>
                        </Row>
                        {errorMessage("userName")}
                    </div>
                    : null}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label><span style={{ color: 'red' }}>*</span> Name: </label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.pocName === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="pocName"
                            value={props.formInformation.pocName}
                            pattern="[a-zA-Z]+"
                            maxLength="30"
                            title="Please enter Name"
                        />
                    </Col>
                </Row>
                {errorMessage("pocName")}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label><span style={{ color: 'red' }}>*</span>Title:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.title === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="title" maxLength="10"
                            value={props.formInformation.title}
                        />
                    </Col>
                </Row>
                {errorMessage("title")}
                {/* {null} */}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label><span style={{ color: 'red' }}>*</span> Phone:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.phone === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="phone" maxLength="10"
                            value={props.formInformation.phone}
                        />
                    </Col>
                </Row>
                {errorMessage("phone")}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label><span style={{ color: 'red' }}>*</span> Email:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.email === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="email" maxLength="50"
                            value={props.formInformation.email}
                        />
                    </Col>
                </Row>
                {errorMessage("email")}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>Address Line 1:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLine1" maxLength="250"
                            value={props.formInformation.addressLine1}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>Address Line 2:</label>
                    </Col>
                    <Col xs={{ span: 16 }} >
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLine2" maxLength="250"
                            value={props.formInformation.addressLine2}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>Address Line 3:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLine3" maxLength="250"
                            value={props.formInformation.addressLine3}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>Address Line 4:</label>
                    </Col>
                    <Col xs={{ span: 16 }} >
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="addressLine4" maxLength="250"
                            value={props.formInformation.addressLine4}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>Fax:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.fax === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="fax" maxLength="10"
                            value={props.formInformation.fax}
                        />
                    </Col>
                </Row>
                {errorMessage("fax")}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>City:</label>
                    </Col>
                    <Col xs={{ span: 16 }} >
                        <input
                            className="form-control"
                            onChange={event => props.handleInput(event)}
                            id="city" maxLength="16"
                            value={props.formInformation.city}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>State:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.state === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="state" maxLength="2"
                            value={props.formInformation.state}
                        />
                    </Col>
                </Row>
                {errorMessage("state")}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>ZIP:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.zip === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="zip" maxLength="5"
                            value={props.formInformation.zip}
                        />
                    </Col>
                </Row>
                {errorMessage("zip")}
                <Row>
                    <Col xs={{ span: 4 }} className='pocLabel'>
                        <label>Country:</label>
                    </Col>
                    <Col xs={{ span: 16 }}>
                        <input
                            className={"form-control " + (props.formValidation.country === "" ? "" : "invalidInput")}
                            onChange={event => props.handleInput(event)}
                            id="country" maxLength="20"
                            value={props.formInformation.country}
                        />
                    </Col>
                </Row>
                {errorMessage("country")}
            </div>
        </div>
    );
}

export default PocModalForm;
