import $ from "jquery";
import decode from 'jwt-decode';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import URL from '../url.js';
import _ from 'lodash';

var comp
const TDS = 'TDSAuthentication';
export default class AuthService {
  constructor() {
    comp = this;
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login() {

    if (!localStorage.getItem('id_token')) {
      // location.href to /
    }

    return comp.getUsername();
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken()
    return !!token  // handwaiving here
  }

  getToken() {
    // Retrieves the user token from localStorage
    const localStorageItems = { ...localStorage };
    let idTokens = [];
    let lastAuthUser = false;
    for (const [key, value] of Object.entries(localStorageItems)) {
      if (key.includes('idToken'))
        idTokens.push({key, value});
      if (key.includes('LastAuthUser'))
        lastAuthUser = value;
    }

    if (idTokens.length > 1) {
      if (lastAuthUser) {
        let lastToken = _.find(idTokens, x => x.key.includes(lastAuthUser));
        if (lastToken) {
          console.log('using last token---', lastToken)
          return lastToken.value;
        }
      }
      return idTokens[0].value;
    } else if (idTokens.length == 1) {
      return idTokens[0].value;
    } else {
      return false;
    }

  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.clear()
  }

  getProfile() {
    return decode(this.getToken());
  }

  isEmail() {
    let userName = comp.getUsername();
    return userName && userName.match(/\@/g);
  }

  getUsername () {
    let info = comp.getProfile();
    if (
      info &&
      info.identities &&
      info.identities[0] &&
      info.identities[0].userId
    ) {
      let username = info.identities[0].userId;
      if (info.identities[0].providerName === 'adprovider') {
        username = username.replace(/\@(.*)$/i, '');
      }
      return username;
    } else {
      return false;
    }
  }





}