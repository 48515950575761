import $ from 'jquery';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ID_TOKEN, GetToken } from '../APIC Generator/APICTokenGenerator.jsx';
import { initGA, PageView } from '../GATracking/GATracking.jsx';
import withAuth from '../login/withAuth';
import URL from '../url.js';
import img1 from './loading.gif';
import NavBar1 from './navbar.jsx';



var comp;
var rowKeys = [];

const opcoURL = 'viewOpco';

class ViewOpco extends React.Component {
	constructor(props) {
		super(props);
		this.state = { data: [] };
		this.name = this.props.history.location.state.data;
		this.group = this.props.history.location.state.group;
		this.viewOpco = this.viewOpco.bind(this);
	}



	componentWillMount() {
		$("#loaderOpco1").show();
		var comp1 = this;
		comp1.viewOpco(false);
	}

	viewOpco(didFail) {
		var comp = this;
		$.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': localStorage.getItem(ID_TOKEN),
				
			},
			crossDomain: true,

			'type': 'POST',
			'url': URL.url + opcoURL,
			'contentType': 'application/json',
			'dataType': 'json',

		})
			.done(function (data) {
				$("#loaderOpco1").hide();
				for (var el in data.rows) {
					comp.state.data.push({
						"opcoId": data.rows[el].opcoId,
						"contactPersonName": data.rows[el].contactPersonName,
						"phoneNumber": data.rows[el].phoneNumber,
						"mailId": data.rows[el].mailId,
						"userName": data.rows[el].userName,
						"userGroup": data.rows[el].userGroup,
						"addedby": data.rows[el].addedby,
						"userId": data.rows[el].userId,
					})
					comp.setState({ data: comp.state.data });
				}
			})
			.fail(function (jqXhr) {
				if (!didFail && jqXhr.status == 401) {
					GetToken();
					comp.viewOpco();
				}
				else
					$("#loaderOpco1").hide();
			});
	}

	componentDidMount() {
		initGA();
		PageView();

	}
	render() {
		var comp = this;
		const options = {

			onRowClick: function (row) {
				comp.props.history.push({
					pathname: '/suite/vbt/opcoviewer',
					state: {
						contactPersonName: comp.name,
						group: comp.group,
						row: row
					}
				})

			},
			noDataText: ' ',
			sizePerPage: 6
		};

		// 			const data1 = [
		//
		//
		// ]





		return (
			<div className="container-fluid">

				<NavBar1 name={this.name} group={this.group} />
				<br />
				<h1 className="headings" style={{ display: "inline", fontFamily: "inherit", textAlign: "left" }}>OPCO Details</h1>
				<br />
				<div style={{ overflowX: "auto" }}>
					<BootstrapTable ref='table' data={this.state.data} pagination options={options} search={true} >
						<TableHeaderColumn dataField='opcoId' width="100" dataSort={true}  >OPCO Id
									</TableHeaderColumn>
						<TableHeaderColumn dataField='contactPersonName' width="150" isKey={true} dataSort={true}  >Name
									</TableHeaderColumn>
						<TableHeaderColumn dataField='mailId' width="180" dataSort={true} >Email Id</TableHeaderColumn>
						<TableHeaderColumn dataField='phoneNumber' width="150" dataSort={true} >Contact Number</TableHeaderColumn>
						<TableHeaderColumn dataField='userName' width="150" dataSort={true} >User Name</TableHeaderColumn>
						<TableHeaderColumn dataField='userGroup' width="100" dataSort={true} hidden>Group</TableHeaderColumn>
						<TableHeaderColumn dataField='addedBy' width="100" dataSort={true} hidden>Added by</TableHeaderColumn>
						<TableHeaderColumn dataField='userId' width="100" dataSort={true} hidden>User Id</TableHeaderColumn>



					</BootstrapTable>

				</div>
				<div id="loaderOpco1" style={{ textAlign: "center" }}><img src={img1} style={{ height: "2em", width: "2em" }} /></div>


			</div>

		)
	}



}


export default withAuth(ViewOpco);